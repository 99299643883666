import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UploadFileItem } from '@components/Uploader/hooks/useFilesUploader';
import {
  LolaBffApiContractsModelsLoanTaskInfo,
  useLazyGetApiV1LoansByLoanIdDocumentTasksQuery,
  usePostApiV1LoansByLoanIdUploadPortfolioRequestMutation,
} from '@api/output/api';
import { UPLOAD_STATUS, UPLOAD_TYPE } from '@constants/uploader.constants';
import { checkFileExtension } from '@utils/file.utils';
import { CustomFileUploader } from '@pages/personalLoans/pages/buildMyLoan/components/CustomFileUploader/CustomFileUploader.component';
import { useOpenToast } from '@hooks/useOpenToast';
import { ApiError, convertCatchError } from '@utils/errors.utils';
import { DEFAULT_API_ERROR_TITLE } from '@constants/text.constants';
import {
  PORTFOLIO_EXCEL_UPLOADER_CONFIG,
  PORTFOLIO_EXCEL_UPLOADER_ALLOWED_EXTENSIONS,
  PORTFOLIO_EXCEL_UPLOADER_DRAWER_CONFIG,
  UPLOAD_NOTIFICATION_CONFIG,
} from './PortfolioExcelUpload.constants';

const uploaderType = UPLOAD_TYPE.CONDITIONAL;

export interface PortfolioExcelUploadProps {
  task: LolaBffApiContractsModelsLoanTaskInfo | undefined;
}

export const PortfolioExcelUpload = ({ task }: PortfolioExcelUploadProps) => {
  const { loanId = '' } = useParams();
  const openToast = useOpenToast();
  const [reloadTasks] = useLazyGetApiV1LoansByLoanIdDocumentTasksQuery();
  const [uploadPortfolio] =
    usePostApiV1LoansByLoanIdUploadPortfolioRequestMutation();

  const uploadedFiles = useMemo(() => {
    const match = task?.documents?.find((file) =>
      checkFileExtension(
        file.fileName,
        PORTFOLIO_EXCEL_UPLOADER_ALLOWED_EXTENSIONS
      )
    );

    return match ? [match] : [];
  }, [task?.documents]);

  const status = useMemo(
    () =>
      uploadedFiles.length
        ? (task?.taskStatus as UPLOAD_STATUS) || UPLOAD_STATUS.UPLOADED
        : UPLOAD_STATUS.NOT_UPLOADED,
    [uploadedFiles, task]
  );

  const notification = useMemo(
    () =>
      uploadedFiles.length
        ? {
            severity:
              UPLOAD_NOTIFICATION_CONFIG.severity[UPLOAD_STATUS.NOT_UPLOADED],
            title: UPLOAD_NOTIFICATION_CONFIG.title[UPLOAD_STATUS.NOT_UPLOADED],
            text: UPLOAD_NOTIFICATION_CONFIG.text[UPLOAD_STATUS.NOT_UPLOADED],
          }
        : {},
    [uploadedFiles]
  );

  const uploadOperationAsyncHandler = useCallback(
    async ({ body }: UploadFileItem) => {
      try {
        await uploadPortfolio({
          loanId,
          body: body as unknown as { file: Blob },
        });
      } catch (err) {
        const description = convertCatchError(err as ApiError);
        openToast({
          isError: true,
          title: DEFAULT_API_ERROR_TITLE,
          description,
        });
      }
    },
    [uploadPortfolio]
  );

  const onDeleteHandler = useCallback(async () => {
    try {
      await reloadTasks({ loanId });
    } catch (err) {
      const description = convertCatchError(err as ApiError);
      openToast({
        isError: true,
        title: DEFAULT_API_ERROR_TITLE,
        description,
      });
    }
  }, [reloadTasks]);

  const completeOperationAsyncHandler = useCallback(async () => {
    try {
      await reloadTasks({ loanId });
    } catch (err) {
      const description = convertCatchError(err as ApiError);
      openToast({
        isError: true,
        title: DEFAULT_API_ERROR_TITLE,
        description,
      });
    }
  }, [reloadTasks]);

  return (
    <CustomFileUploader
      title="Application"
      placeholder="Excel, less than 20mb"
      type={uploaderType}
      task={task}
      uploadedFiles={uploadedFiles}
      uploaderConfig={PORTFOLIO_EXCEL_UPLOADER_CONFIG}
      drawerConfig={PORTFOLIO_EXCEL_UPLOADER_DRAWER_CONFIG}
      status={status}
      allowedExtensions={PORTFOLIO_EXCEL_UPLOADER_ALLOWED_EXTENSIONS}
      notification={notification}
      onDelete={onDeleteHandler}
      completeOperationAsync={completeOperationAsyncHandler}
      uploadOperationAsync={uploadOperationAsyncHandler}
    />
  );
};
