import React, { useEffect, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Button, Checkbox, FormControlLabel, Link } from '@mui/material';

import { useSelector } from 'react-redux';
import { PasswordInput, Input } from '@lola/ui-react-components';
import { usePostApiV1AuthTokenMutation } from '@api/output/api';
import { auth } from '@config/auth';
import { selectAccessToken } from '@store/selectors/auth.selectors';
import { ContentFormSection } from '../../components/ContentFormSection';

import { loginFormSchema } from './loginForm.schema';
import styles from './login.module.scss';

export interface RegisterFormInputs {
  username: string;
  password: string;
  rememberMe: boolean;
  domain: string;
}

export const LoginPage = () => {
  const navigate = useNavigate();
  const token = useSelector(selectAccessToken);
  const [login, { isLoading, isError, isSuccess }] =
    usePostApiV1AuthTokenMutation();

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<RegisterFormInputs>({
    defaultValues: {
      username: '',
      password: '',
      rememberMe: false,
      domain: auth.domain,
    },
    resolver: loginFormSchema,
  });

  useEffect(() => {
    if (isSuccess && token) {
      navigate('/');
    }
  }, [isSuccess, token]);

  /* Temporary flow*/
  const onSubmit = useMemo(() => {
    return handleSubmit(async (values) => {
      return login({
        lolaBffApiContractsModelsAuthAuthUserLoginRequest: values,
      });
    });
  }, [handleSubmit]);

  return (
    <ContentFormSection
      title="Log in to your account"
      subtitle="Enter your credentials to access your account"
      isLoading={isLoading}
      primaryButtonText="Log in"
      primaryButtonDisabled={!isDirty}
      onSubmit={onSubmit}
    >
      <Controller
        name="username"
        control={control}
        render={({ field }) => {
          const error = isError
            ? 'Incorrect username'
            : errors.username?.message;

          return (
            <Input
              {...field}
              autoComplete="off"
              error={!!error}
              helperText={error}
              label="Username"
              placeholder="Enter data"
              variant="filled"
            />
          );
        }}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => {
          const error = isError
            ? 'Incorrect password'
            : errors.password?.message;

          return (
            <PasswordInput
              {...field}
              autoComplete="off"
              className={styles.inputLast}
              error={!!error}
              helperText={error}
              label="Password"
              placeholder="Enter data"
              variant="filled"
            />
          );
        }}
      />
      <footer className={styles.formFooter}>
        <Controller
          name="rememberMe"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label="Remember me"
            />
          )}
        />
        <Link component={NavLink} to="/auth/forgot-password" underline="none">
          <Button variant="text" size="small">
            Forgot your password?
          </Button>
        </Link>
      </footer>
    </ContentFormSection>
  );
};
