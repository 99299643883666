import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { LinearProgress, Typography } from '@mui/material';
import { useOpenState } from '@hooks/useOpenState';
import { RadioGroup } from '@components/RadioGroup';
import {
  LolaBffApiContractsModelsLoanPartyInfo,
  useLazyGetApiV1BorrowersByPartyIdQuery,
} from '@api/output/api';
import {
  getRoleByKey,
  usePartyAttach,
} from '@pages/personalLoans/pages/buildMyLoan/pages/applicationAndEntityInformation/hooks/usePartyAttach';
import { useUpdateBuildMyLoanCache } from '@pages/personalLoans/pages/buildMyLoan/hooks/useUpdateBuildMyLoanCache';
import { DEFAULT_GUARANTOR_VALUE } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan';
import { ROLE_TYPE } from '@typings/common';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { ModalWarning } from '../ModalWarning/ModalWarning.component';
import { EntityTypeListDrawer, SelectedEntityType } from './components';
import {
  MODAL_TEXT,
  OPTIONS,
  OPTIONS_VALUE,
} from './NewExistingSelector.constants';
import { EntityType } from './NewExistingSelector.types';
import styles from './newExistingSelector.module.scss';

export interface NewExistingSelectorProps {
  entityType: EntityType;
  entityKey: string;
  autoOpen?: boolean;
  label?: string;
  major?: boolean;
  newComponent?: ReactNode;
}

export const NewExistingSelector = ({
  entityType,
  newComponent,
  entityKey,
  autoOpen,
  label,
  major,
}: NewExistingSelectorProps) => {
  const { disabled } = useContext(LoanDisableContext);
  const { watch } = useFormContext();
  const { refetchParties } = useLoanDetails();
  const [isExistingEntityBeingAdded, setIsExistingEntityBeingAdded] =
    useState(false);
  const [isListOpen, openList, closeList] = useOpenState();
  const [isModalOpen, openModal, closeModal] = useOpenState();
  const [selectedValue, setSelectedValue] = useState(OPTIONS_VALUE.NEW);
  const [selectedGuarantorName, setSelectedGuarantorName] = useState('');
  const { attachParty, detachParty, isDetaching } = usePartyAttach();
  const { updatePartyCacheById, updatePartyCacheByIndex } =
    useUpdateBuildMyLoanCache();
  const [getParty] = useLazyGetApiV1BorrowersByPartyIdQuery();
  const [
    entity,
    entityId,
    entityIndex,
    primaryGuarantor = {},
    guarantors = [],
  ] = watch([
    entityKey,
    `${entityKey}.id`,
    `${entityKey}.index`,
    'party.borrower',
    'party.guarantors',
  ]);

  useEffect(() => {
    setSelectedValue(
      entityId !== undefined ? OPTIONS_VALUE.EXISTED : OPTIONS_VALUE.NEW
    );
  }, [entityId]);

  const selectBorrowerHandler = useCallback(
    async (entity: LolaBffApiContractsModelsLoanPartyInfo) => {
      if (
        primaryGuarantor.id === entity.id ||
        guarantors.find(
          (guarantor: LolaBffApiContractsModelsLoanPartyInfo) =>
            guarantor.id === entity.id
        )
      ) {
        setSelectedGuarantorName(entity.displayName as string);
        openModal();
        return;
      }

      setIsExistingEntityBeingAdded(true);

      if (entity.id) {
        const role = getRoleByKey(entityKey);
        await attachParty(entity.id, entityId, role);

        const party = await getParty({
          partyId: entity.id,
        }).unwrap();

        if (role === ROLE_TYPE.OTHER_INDIVIDUAL) {
          if (entityId) {
            updatePartyCacheById(party, role, entityId);
          } else if (entityIndex !== null) {
            updatePartyCacheByIndex(party, role, entityIndex);
          }
        } else {
          updatePartyCacheById(party, role, entityId);
        }
      }

      closeList();
      setIsExistingEntityBeingAdded(false);
    },
    [primaryGuarantor.id, guarantors.length, entityId, entityIndex, entityKey]
  );

  const changeHandler = useCallback(
    async (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
      const role = getRoleByKey(entityKey);

      if (value === OPTIONS_VALUE.NEW) {
        await handleNewValue(role);
        setSelectedValue(OPTIONS_VALUE.NEW);
      } else if (value === OPTIONS_VALUE.EXISTED && autoOpen) {
        openList();
      } else {
        const nullParty = { id: null };

        if (role === ROLE_TYPE.OTHER_INDIVIDUAL) {
          if (entityId) {
            updatePartyCacheById(nullParty, role, entityId);
          } else if (entityIndex !== null) {
            updatePartyCacheByIndex(nullParty, role, entityIndex);
          }
        } else {
          updatePartyCacheById(nullParty, role, entityId);
        }
      }
    },
    [autoOpen, entityId, entityKey, entityIndex]
  );

  const handleNewValue = async (role: ROLE_TYPE) => {
    await detachParty(entityId);

    if (role === ROLE_TYPE.PRIMARY_ENTITY && refetchParties) {
      await refetchParties();
      return;
    }

    if (role === ROLE_TYPE.OTHER_INDIVIDUAL) {
      if (entityId) {
        updatePartyCacheById(DEFAULT_GUARANTOR_VALUE, role, entityId);
      } else if (entityIndex) {
        updatePartyCacheByIndex(DEFAULT_GUARANTOR_VALUE, role, entityIndex);
      }
    } else {
      updatePartyCacheById(DEFAULT_GUARANTOR_VALUE, role, entityId);
    }
  };

  const title = useMemo(() => {
    if (entity && entityId) {
      const displayName = entity.displayName?.trim();
      const fullName = entity?.fullName?.trim();
      return displayName || fullName || entity.taxpayerIdentifierValue;
    }

    if (!autoOpen) {
      return 'Choose from existing guarantors';
    }
  }, [autoOpen, entityId, entity]);

  return (
    <>
      <div className={styles.radioWrapper}>
        <RadioGroup
          disabled={disabled}
          label={label}
          options={OPTIONS[entityType]}
          row
          major={major}
          value={selectedValue}
          onChange={changeHandler}
        />
        {isDetaching && <LinearProgress />}
      </div>
      <EntityTypeListDrawer
        entityType={entityType}
        open={isListOpen}
        onClose={closeList}
        isExistingEntityBeingAdded={isExistingEntityBeingAdded}
        onBorrowerSelect={selectBorrowerHandler}
      />
      {selectedValue === OPTIONS_VALUE.EXISTED && (
        <SelectedEntityType
          entityType={entityType}
          title={title}
          selected={!!entityId}
          onBorrowerChange={openList}
          disabled={disabled}
        >
          {newComponent}
        </SelectedEntityType>
      )}
      {selectedValue === OPTIONS_VALUE.NEW && newComponent}
      <ModalWarning
        open={isModalOpen}
        title="Select another guarantor"
        handleClose={closeModal}
      >
        <Typography>
          {MODAL_TEXT[entityType]?.replace('%name%', selectedGuarantorName)}
        </Typography>
      </ModalWarning>
    </>
  );
};
