import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { AddIcon, DeleteIcon } from '@lola/ui-react-components';
import { useNavigate } from 'react-router-dom';
import { ProgressStatusIcon } from '@components/ProgressStepper/components';
import { ProgressStepItemStatus } from '@components/ProgressStepper/types';

import { LOAN_PRODUCTS, QUOTE_TYPE } from '@typings/common';
import { ButtonWithTooltip } from '@components/ButtonWithTooltip/ButtonWithTooltip.component';
import { Modal } from '@components/Modal';
import {
  STAGE_MODAL_CANCEL,
  STAGE_MODAL_CONTENT,
  STAGE_MODAL_SUBMIT,
  STAGE_MODAL_TITLE,
  STAGE_MODAL_TYPE,
} from '@pages/scenarioBuilder/pages/quoteForm/QuoteForm.constants';
import { StageModalConfig } from '@pages/scenarioBuilder';
import styles from './newQuoteFooter.module.scss';

const STAGE_BUTTON_TOOLTIP =
  'Click here to stage your quote in preparation for a loan.';

export interface NewQuoteFooterProps {
  quoteId?: string;
  productType?: LOAN_PRODUCTS;
  quoteType?: QUOTE_TYPE;
  isSubmitButtonDisabled?: boolean;
  status?: ProgressStepItemStatus;
  isCreateQuote?: boolean;
  onFormClear?: () => void;
  onGeneratePdf?: () => void;
  isPdfLoading?: boolean;
  isCalculated?: boolean;
  isStaged?: boolean;
  onStageQuote?: (v: boolean) => void;
}

export const NewQuoteFooter = ({
  isSubmitButtonDisabled,
  quoteId,
  productType,
  quoteType,
  status = ProgressStepItemStatus.IN_PROGRESS,
  isCreateQuote,
  onFormClear,
  onGeneratePdf,
  isPdfLoading,
  isCalculated,
  isStaged,
  onStageQuote,
}: NewQuoteFooterProps) => {
  const [modalType, setModalType] = useState<STAGE_MODAL_TYPE>(
    STAGE_MODAL_TYPE.CLOSE
  );

  const handleClose = useCallback(
    () => setModalType(STAGE_MODAL_TYPE.CLOSE),
    []
  );

  const navigate = useNavigate();
  const goToNewQuote = useCallback(() => {
    navigate(
      `/new-loan?quoteId=${quoteId}&productType=${productType}&quoteType=${quoteType}`
    );
  }, [quoteId, productType, quoteType]);

  const modalSubmitAction: StageModalConfig<() => void> = useMemo(
    () => ({
      [STAGE_MODAL_TYPE.INIT]: () => {
        setModalType(STAGE_MODAL_TYPE.STAGE);
        onStageQuote?.(true);
      },
      [STAGE_MODAL_TYPE.STAGE]: goToNewQuote,
      [STAGE_MODAL_TYPE.UNSTAGE]: () => {
        onStageQuote?.(false);
        handleClose();
      },
    }),
    [goToNewQuote, onStageQuote, handleClose]
  );

  return (
    <footer className={styles.footer}>
      <Grid className={styles.status}>
        {status && <ProgressStatusIcon status={status} />}
        <Typography variant="pBold" className={styles.navTitle}>
          New Quote
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid className={styles.buttons}>
        <Button
          variant="textAlert"
          size="large"
          startIcon={<DeleteIcon />}
          className={styles.dismiss}
          disabled={!isCreateQuote}
          onClick={onFormClear}
        >
          Clear the form
        </Button>
        <Button
          variant="outlined"
          size="large"
          disabled={isSubmitButtonDisabled ?? isPdfLoading}
          onClick={onGeneratePdf}
          startIcon={isPdfLoading && <CircularProgress size={20} />}
        >
          Generate PDF
        </Button>
        {!isStaged && (
          <ButtonWithTooltip
            tooltip={STAGE_BUTTON_TOOLTIP}
            disabled={!isCalculated}
            variant="contained"
            size="large"
            onClick={() => setModalType(STAGE_MODAL_TYPE.INIT)}
          >
            Stage Quote
          </ButtonWithTooltip>
        )}
        {isStaged && (
          <>
            <Button
              variant="outlined"
              size="large"
              disabled={isSubmitButtonDisabled}
              onClick={() => setModalType(STAGE_MODAL_TYPE.UNSTAGE)}
            >
              Unstage Quote
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              size="large"
              disabled={isSubmitButtonDisabled}
              onClick={goToNewQuote}
            >
              Start a loan
            </Button>
          </>
        )}
      </Grid>
      <Modal
        title={STAGE_MODAL_TITLE[modalType]}
        isOpen={!!modalType}
        onSuccess={modalSubmitAction[modalType]}
        successButtonText={STAGE_MODAL_SUBMIT[modalType]}
        hasCancel
        cancelButtonText={STAGE_MODAL_CANCEL[modalType]}
        onClose={handleClose}
      >
        {STAGE_MODAL_CONTENT[modalType]}
      </Modal>
    </footer>
  );
};
