import { Controller, useFormContext } from 'react-hook-form';
import { LinearProgress, MenuItem } from '@mui/material';
import React, { useMemo } from 'react';
import { SelectWithPlaceholder } from '@components/SelectWithPlaceholder/SelectWithPlaceholder.component';
import { convertTeamsResponseToOptions } from '@utils/teams.utils';
import { Option } from '@typings/common';
import { useGetApiV1TeamsQuery } from '@api/output/api';

export const SelectTeam = () => {
  const { data, isLoading } = useGetApiV1TeamsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const selectOptions: Option[] = useMemo(
    () => convertTeamsResponseToOptions(data),
    [data]
  );

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Controller
      name="teamId"
      control={control}
      render={({ field: { value = '', ...props } }) => (
        <SelectWithPlaceholder
          value={value}
          error={!!errors.team}
          helperText={errors.team?.message as string}
          placeholder="Select"
          label="Select a team to which you would like to add a loan officer"
          selectOptions={selectOptions}
          {...props}
        >
          {selectOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </SelectWithPlaceholder>
      )}
    />
  );
};
