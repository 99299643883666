import React, { useEffect } from 'react';
import { LinearProgress, MenuItem, SelectProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectWithPlaceholder } from '@components/SelectWithPlaceholder/SelectWithPlaceholder.component';
import { useManagersList } from '@pages/teamManagement/components/SelectManager/hooks/useManagersList';

export interface SelectManagerProps {
  label: string;
  required?: string;
  selectVariant?: SelectProps['variant'];
}

export const SelectManager = ({
  label,
  required = '',
  selectVariant,
}: SelectManagerProps) => {
  const selectOptions = useManagersList();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    return () => setValue('managerUserId', '');
  }, []);

  if (!selectOptions.length) {
    return <LinearProgress />;
  }

  return (
    <Controller
      name="managerUserId"
      control={control}
      rules={{ required }}
      render={({ field: { value = '', ...props } }) => (
        <SelectWithPlaceholder
          error={!!errors.managerUserId}
          helperText={errors.managerUserId?.message as string}
          value={value}
          placeholder="Select"
          variant={selectVariant}
          label={label}
          selectOptions={selectOptions}
          {...props}
        >
          {selectOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </SelectWithPlaceholder>
      )}
    />
  );
};
