import React from 'react';
import { Button, IconButton } from '@mui/material';
import { ListIcon, UploadIcon, DeleteIcon } from '@lola/ui-react-components';

export interface FileUploaderButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

export const detailsButtonRendererUploader = ({
  onClick,
  disabled,
}: FileUploaderButtonProps) => {
  return (
    <Button
      variant="outlined"
      startIcon={<ListIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      Details
    </Button>
  );
};

export const detailsIconButtonRendererUploader = ({
  onClick,
  disabled,
}: FileUploaderButtonProps) => {
  return (
    <IconButton onClick={onClick} disabled={disabled}>
      <ListIcon />
    </IconButton>
  );
};

export const deleteIconButtonRendererUploader = ({
  onClick,
  disabled,
}: FileUploaderButtonProps) => {
  return (
    <IconButton onClick={onClick} disabled={disabled}>
      <DeleteIcon />
    </IconButton>
  );
};

export const uploadButtonRendererUploader = ({
  onClick,
  disabled,
}: FileUploaderButtonProps) => {
  return (
    <Button
      variant="outlined"
      startIcon={<UploadIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      Upload
    </Button>
  );
};

export const uploadIconButtonRendererUploader = ({
  onClick,
  disabled,
}: FileUploaderButtonProps) => {
  return (
    <IconButton onClick={onClick} disabled={disabled}>
      <UploadIcon />
    </IconButton>
  );
};
