import React from 'react';
import { Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { StatusAlertIcon } from '@lola/ui-react-components';
import { Notification } from '@components/Notification/Notification.component';
import styles from './calculationErrors.module.scss';

export interface CalculationErrorsProps {
  errors?: string[];
}

export const CalculationErrors = ({ errors }: CalculationErrorsProps) => {
  if (!errors?.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Notification
        severity="error"
        title="Calculation errors"
        withIcon
        icon={<StatusAlertIcon />}
        className={styles.notification}
      >
        <>
          {errors?.map((error) => (
            <p key={uuidv4()}>&bull; {error}</p>
          ))}
        </>
      </Notification>
    </Grid>
  );
};
