import React, { useMemo } from 'react';
import { LinearProgress, ListItem, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FileItem } from '../../Uploader.types';
import { useProgressValue } from '../../hooks/useProgressValue';
import { UPLOADER_STATE } from '../../Uploader.constants';

import styles from './fileListItem.module.scss';

interface FileListItemProps {
  file: FileItem;
  secondaryIcon: JSX.Element | null;
  state: UPLOADER_STATE;
  error?: string;
  isProcessing?: boolean;
}

export const FileListItem = ({
  file,
  secondaryIcon,
  state,
  error,
  isProcessing = false,
}: FileListItemProps) => {
  const progress = useProgressValue(state);
  const { fileName = '', createdAt } = file;
  const isProgressShown = useMemo(
    () => isProcessing && !error,
    [isProcessing, error]
  );

  return (
    <ListItem
      key={fileName}
      className={styles.listItem}
      secondaryAction={secondaryIcon}
    >
      <div className={styles.info}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="pBold" paragraph>
            {fileName}
          </Typography>
          {isProgressShown && (
            <Typography className={styles.percentage}>
              {progress.toFixed(0)}% completed
            </Typography>
          )}
        </Stack>
        {!isProgressShown && !error && createdAt && (
          <Typography variant="pDescription">
            Uploaded on {dayjs(createdAt).format('D MMM YY')}
          </Typography>
        )}
        {isProgressShown && (
          <LinearProgress
            variant="determinate"
            value={progress}
            className={styles.progress}
          />
        )}
        {error && (
          <Typography variant="pSmall" className={styles.error}>
            {error}
          </Typography>
        )}
      </div>
    </ListItem>
  );
};
