import React from 'react';
import get from 'lodash/get';
import { Format, SectionsConfig } from '@utils/quotes.utils';
import { Quote } from '@typings/common';
import { LeverageCalculations } from '@components/QuoteCalculation/components/LeverageCalculations/LeverageCalculations.component';
import { CalculationBlockDivider } from '@components/CaclulationBlockDivider/CalculationBlockDivider.component';

export const SHORT_TERM_CALCULATION_SECTIONS_CONFIGS: SectionsConfig = [
  {
    title: 'Loan details',
    items: [
      {
        key: 'subject-property-address',
        title: 'Subject Property Address',
        value: 'in.loanSummary.propertyAddress',
      },
      {
        title: 'Loan Purpose',
        key: 'loan-purpose',
        value: 'in.loanSummary.loanPurpose',
      },
      {
        title: 'Loan Type',
        key: 'loan-type',
        value: 'in.loanSummary.loanProduct',
      },
      {
        title: 'Property Type',
        key: 'property-type',
        value: 'in.loanSummary.propertyType',
      },
      {
        key: 'term',
        title: 'Term',
        value: 'in.loanTerm.loanTerm',
      },
      {
        title: 'Number of Properties',
        key: 'number-of-properties',
        value: 'in.dealTerms.numOfProperties',
      },
    ],
  },
  {
    title: 'Loan terms summary',
    items: [
      {
        title: 'Estimated Loan Amount',
        key: 'estimated-loan-amount',
        value: 'out.cashToClose.estimatedLoanAmt',
        format: Format.Currency,
      },
      {
        key: 'rate',
        title: 'Rate',
        value: 'out.rateFeeLLPA.finalRate',
        format: Format.Percent,
      },
      {
        title: 'Origination Fee',
        key: 'origination-fee',
        value: 'out.rateFeeLLPA.finalOrigFee',
        format: Format.Percent,
      },
      {
        title: 'Initial Payment',
        key: 'initial-payment',
        value: 'out.payments.initMonthlyPayment',
        format: Format.Currency,
      },
      {
        title: 'Payment Once Fully Drawn',
        key: 'payment-once-fully-drawn',
        value: 'out.payments.paymentFullyDrawn',
        format: Format.Currency,
      },
      {
        key: 'guaranty',
        title: 'Guaranty',
        value: 'in.loanTerm.guaranty',
      },
    ],
  },
  {
    title: 'Borrower info',
    items: [
      {
        title: 'Entity Name',
        key: 'entity-name',
        value: 'in.borrowerInfo.borrowingEntity',
      },
      {
        title: 'Primary Sponsor Name',
        key: 'primary-sponsor-name',
        value: 'in.borrowerInfo.primaryGuarantor',
      },
      {
        title: 'Qualifying FICO Score',
        key: 'qualifying-fico-score',
        value: 'in.borrowerInfo.qualifyingFICO',
      },
    ],
  },
  {
    title: 'Transaction summary',
    items: [
      {
        title: 'Purchase Price / As-Is Value',
        key: 'purchase-price-or-as-is-value',
        value: 'in.dealTerms.lesserOfLotOrPurchase',
        format: Format.Currency,
      },
      {
        title: 'Construction Budget',
        key: 'construction-budget',
        value: 'in.dealTerms.rehabConBudget',
        format: Format.Currency,
      },
      {
        key: 'estimated-arv',
        title: 'Estimated ARV',
        value: 'in.dealTerms.afterCompletedVal',
        format: Format.Currency,
      },
      {
        key: 'existing-debt',
        title: 'Existing Debt',
        value: 'in.dealTerms.payoff',
        format: Format.Currency,
      },
    ],
  },
  {
    title: 'Estimated cash to close',
    description:
      '*The above cash to close figures do not include 3rd party title fees or any required inspection or contractor due diligence fees',
    items: [
      {
        key: 'purchase',
        title: 'Purchase',
        value: 'out.cashToClose.purchase',
        format: Format.Currency,
      },
      {
        title: 'Construction Budget',
        key: 'construction-budget-ectc',
        value: 'out.cashToClose.conBudget',
        format: Format.Currency,
      },
      {
        title: 'Total Loan Fees & Costs',
        key: 'total-loan-fees-costs',
        value: 'out.cashToClose.totalLoanFeesCost',
        format: Format.Currency,
      },
      {
        title: 'Estimated Loan Amount',
        key: 'estimated-loan-amount-ectc',
        value: 'out.cashToClose.estimatedLoanAmt',
        format: Format.Currency,
      },
      {
        title: 'Existing Payoff',
        key: 'existing-payoff',
        value: 'out.cashToClose.existingPayoff',
        format: Format.Currency,
      },
      {
        key: 'estimated-cash-at-close',
        title: '',
        value: 'out.cashToClose.estimatedCashAtClose',
        format: Format.Currency,
        titleRenderer: (details) => {
          const cashAtClose = get(
            details,
            'out.cashToClose.estimatedCashAtClose'
          );
          if (cashAtClose ? cashAtClose >= 0 : true) {
            return 'Estimated Cash Due to Borrower at Close';
          } else {
            return 'Estimated Cash Due from Borrower at Close';
          }
        },
      },
    ],
  },
  {
    title: 'Loan costs',
    items: [
      {
        title: 'Loan Costs Paid at Closing',
        key: 'loan-costs-paid-at-closing',
        value: 'out.cashToClose.totalLoanFeesCost',
        format: Format.Currency,
      },
      {
        title: 'Origination Fee',
        key: 'origination-fee-lc',
        value: 'out.loanCosts.originationFee',
        format: Format.Currency,
      },
      {
        title: 'Processing Fee',
        key: 'underwriting-and-processing-fee',
        value: 'out.loanCosts.processingFee',
        format: Format.Currency,
      },
      {
        title: 'Interest Reserve',
        key: 'interest-reserve',
        value: 'out.loanCosts.interestReserve',
        format: Format.Currency,
      },
      {
        key: 'broker-fee-lc',
        title: 'Broker fee',
        value: 'out.loanCosts.brokerFee',
        format: Format.Currency,
      },
      {
        key: 'divider-key',
        itemRenderer: () => <CalculationBlockDivider my={1} />,
      },
      {
        title: 'Total Loan Fees & Costs',
        key: 'projected-total-loan-cost',
        value: 'out.loanCosts.totalLoanCostFees',
        format: Format.Currency,
        decorations: 'bold',
      },
    ],
  },
  {
    title: 'Leverage calculations',
    items: [
      {
        title: 'Blended Loan to Cost',
        key: 'blended-loan-to-cost',
        value: 'out.blendedLoanToCost',
        format: Format.Percent,
      },
      {
        title: 'Property Value Basis LTC',
        key: 'property-value-basis-ltc',
        value: 'out.propertyValueBasisLTC',
        format: Format.Percent,
      },
      {
        title: 'Rehab Budget LTC',
        key: 'rehab-budget-ltc',
        value: 'out.rehabBudgetLTC',
        format: Format.Percent,
      },
      {
        title: 'Current Projected LTV / LTARV',
        key: 'current-projected-ltv-ltarv',
        value: 'out.currentProjectedLTVLTARV',
        format: Format.Percent,
      },
      {
        key: 'leverage-calculations-item-renderer',
        itemRenderer: (details: Quote, disabled) => (
          <LeverageCalculations details={details} disabled={disabled} />
        ),
      },
    ],
  },
  {
    title: 'Partner economics',
    description:
      '*The Loan Amount is determined by the lesser of the LTC and LTV loan calculations',
    items: [
      {
        key: 'origination-points',
        title: 'Origination points',
        decorations: 'boldGray',
      },
      {
        key: 'partner-key',
        title: 'Partner',
        value: 'out.lenderFinanceOriginationPoints',
        format: Format.Currency,
      },
      {
        key: 'broker-fee',
        title: 'Broker',
        value: 'out.loanCosts.brokerFee',
        format: Format.Currency,
      },
      {
        key: 'divider-key',
        itemRenderer: () => <CalculationBlockDivider />,
      },
      {
        key: 'interest-rate-add-on',
        title: 'Interest Rate Add-On',
        value: 'out.lenderFinanceFrontEndYieldSpreadPremium',
        format: Format.Percent,
      },
      {
        key: 'application-fee',
        title: 'Application Fee',
        value: 'out.lenderFinanceApplicationFee',
        format: Format.Currency,
      },
      {
        key: 'processing-fee',
        title: 'Processing Fee',
        value: 'out.lenderFinanceProcessingFee',
        format: Format.Currency,
      },
      {
        key: 'document-fee',
        title: 'Document Fee',
        value: 'out.lenderFinanceDocumentFee',
        format: Format.Currency,
      },
      {
        key: 'appraisal-fee',
        title: 'Appraisal Fee',
        value: 'out.lenderFinanceAppraisalFee',
        format: Format.Currency,
      },
      {
        key: 'divider-key1',
        itemRenderer: () => <CalculationBlockDivider my={1} />,
      },
      {
        key: 'total-key',
        title: 'Total',
        format: Format.Currency,
        value: 'out.totalLenderFinanceFees',
        decorations: 'bold',
      },
    ],
  },
];
