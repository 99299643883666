import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Drawer, IconButton, LinearProgress } from '@mui/material';
import { TableColumnType } from 'antd';
import {
  CloseIcon,
  compareDate,
  DataTable,
  useTableDataSearchAndFilter,
  useTableDataSorting,
  useTableScroll,
  createStatusOptionRenderer,
} from '@lola/ui-react-components';
import { ContentSectionHeader } from '@components/ContentSection';
import { LOAN_STATUS_CONFIG } from '@constants/loan.constants';
import { DataControlPanel } from '@components/DataTableControlPanel/DataTableControlPanel.component';
import {
  LolaBffApiContractsModelsLoanLoanView,
  useLazyGetApiV1LoansQuery,
} from '@api/output/api';
import { createLoanInfoRenderer } from './renderers';
import { createAscendingSorter, createDescendingSorter } from './sorters';

import styles from './loanQuickView.module.scss';

const CONTROL_PANEL_HEIGHT = 150;

const columns: TableColumnType<LolaBffApiContractsModelsLoanLoanView>[] = [
  {
    dataIndex: 'addressLine',
    key: 'addressLine',
    render: createLoanInfoRenderer(LOAN_STATUS_CONFIG),
  },
];

interface LoanQuickViewProps {
  isDrawerOpen: boolean;
  onClose: () => void;
}

export const FALLBACK_DETAILS = [];

export const LoanQuickView = ({
  isDrawerOpen,
  onClose,
}: LoanQuickViewProps) => {
  const [load, { data = FALLBACK_DETAILS, isFetching }] =
    useLazyGetApiV1LoansQuery();

  const [ref, scroll] = useTableScroll(true, -CONTROL_PANEL_HEIGHT, {
    x: 440,
    y: 800,
  });

  useEffect(() => {
    if (isDrawerOpen) {
      load();
    }
  }, [isDrawerOpen]);

  const {
    filteredData,
    handleSearchChange,
    handleFilterChange,
    appliedFilters,
    filters,
    searchTerm,
  } = useTableDataSearchAndFilter<LolaBffApiContractsModelsLoanLoanView>(
    data,
    {
      status: {
        values: [],
        title: 'Status',
        optionLabelRenderer: createStatusOptionRenderer(LOAN_STATUS_CONFIG),
      },
    },
    'addressLine'
  );

  const { sortedData, sorters, handleSortChange, currentSorting } =
    useTableDataSorting<LolaBffApiContractsModelsLoanLoanView>({
      data: filteredData,
      appliedFilters,
      searchTerm,
      sorters: [
        {
          label: 'Sort by',
          options: [
            {
              title: 'Created: newest first',
              sorterFn: createDescendingSorter('createdAt', compareDate),
              labelPostfix: 'Created: new to old',
            },
            {
              title: 'Created: oldest first',
              sorterFn: createAscendingSorter('createdAt', compareDate),
              labelPostfix: 'Created: old to new',
            },
            {
              title: 'Last update: newest first',
              sorterFn: createDescendingSorter('lastUpdateAt', compareDate),
              labelPostfix: 'Updated: new to old',
            },
            {
              title: 'Last update: oldest first',
              sorterFn: createAscendingSorter('lastUpdateAt', compareDate),
              labelPostfix: 'Updated: old to new',
            },
          ],
        },
      ],
    });

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={onClose}
      anchor="right"
      classes={{ paper: styles.drawer }}
    >
      <ContentSectionHeader
        title="Loan quick view"
        actions={
          <IconButton onClick={onClose} title="Close loan quick view">
            <CloseIcon />
          </IconButton>
        }
      />

      <div className={styles.wrapper} ref={ref}>
        <DataControlPanel
          appliedFilters={appliedFilters}
          filters={filters}
          sorters={sorters}
          currentSorting={currentSorting}
          handleFilterChange={handleFilterChange}
          handleSearchChange={handleSearchChange}
          handleSortChange={handleSortChange}
          className={styles.controlPanel}
        />
        {isFetching ? (
          <LinearProgress />
        ) : (
          <DataTable
            rowKey="id"
            columns={columns}
            dataSource={sortedData}
            scroll={scroll}
          />
        )}
      </div>
      <div className={styles.footer}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="large"
          variant="outlined"
          component={NavLink}
          to="/personal-loans"
        >
          View all loans
        </Button>
      </div>
    </Drawer>
  );
};
