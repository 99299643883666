import React, { useEffect, useMemo } from 'react';
// router
import { useParams } from 'react-router-dom';
//MUI
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  LinearProgress,
  MenuItem,
} from '@mui/material';
//Hooks
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { LockedIcon } from '@lola/ui-react-components';
import { useUpload } from '@components/Uploader/hooks/useUpload';
// API
import {
  PostApiV1ConditionByLoanIdExceptionRequestApiArg,
  usePostApiV1ConditionByLoanIdExceptionRequestMutation,
  useLazyGetApiV1LoansQuery,
} from '@api/output/api';
//icons
//components
import { MessageArea } from '@pages/personalLoans/pages/approveMyLoan/pages/reviewStatus/components/MessageArea.component';
import { SelectWithPlaceholder } from '@components/SelectWithPlaceholder/SelectWithPlaceholder.component';
import { Option } from '@typings/common';
import { convertLoansResponseToOptions } from './utils/convertLoansResponseToOptions.utils';
import { ExceptionRequestFileUpload } from './components';
//styles
import styles from './exceptionRequest.module.scss';

// types
interface ExceptionRequestProps {
  handledClose: () => void;
  openModal?: () => void;
  loanName?: string;
  loanIdentifier?: string;
  isLoansList?: boolean;
}

type InputsFormType = {
  loanId?: string;
  loanName?: string;
  title: string;
  description: string;
};

// Data
const isAcceptFile: string[] = ['pdf', 'jpg', 'png'];

export const ExceptionRequest = ({
  handledClose,
  openModal,
  loanName = '',
  loanIdentifier = '',
  isLoansList,
}: ExceptionRequestProps) => {
  // router
  const { loanId = '' } = useParams();

  // API
  const [sendException, { isLoading: isSendExceptionLoading, isSuccess }] =
    usePostApiV1ConditionByLoanIdExceptionRequestMutation();
  const [getLoans, { data: loansData, isLoading: isLoansLoading }] =
    useLazyGetApiV1LoansQuery();

  // Form
  const methods = useForm<InputsFormType>({
    defaultValues: {
      loanName: loanName,
      title: '',
      description: '',
    },
  });
  const { handleSubmit, formState } = methods;

  // Hooks
  const { errors, files, uploadFiles } = useUpload({
    rules: {
      allowedExtensions: {
        rule: isAcceptFile,
        message: 'The file is not accepted format.  ',
      },
      maxSize: {
        rule: 5000000,
        message: `The file size must be below to ${5000000 / 1000000}MB.`,
      },
    },
  });

  const onSubmit = (data: InputsFormType) => {
    const body = new FormData();
    const appendFilesToForm = () => {
      files.forEach((file) =>
        body.append('Files', file.file as Blob, file.fileName ?? '')
      );
    };

    Object.entries(data).forEach(([key, value]) => {
      const capitalKey = key.charAt(0).toUpperCase() + key.slice(1);
      body.append(capitalKey, value);
    });

    if (isLoansList && data.loanId) {
      appendFilesToForm();
      sendException({
        loanId: data.loanId,
        body: body as unknown as PostApiV1ConditionByLoanIdExceptionRequestApiArg['body'],
      });
    } else {
      appendFilesToForm();
      body.append('LoanId', loanId);
      body.append('loanIdentifier', loanIdentifier);

      sendException({
        loanId,
        body: body as unknown as PostApiV1ConditionByLoanIdExceptionRequestApiArg['body'],
      });
    }
  };

  useEffect(() => {
    if (isLoansList) {
      getLoans();
    }
  }, [isLoansList, getLoans]);

  useEffect(() => {
    if (!isSuccess) return;

    handledClose();
    openModal?.();
  }, [isSuccess]);

  const selectOptions: Option[] = useMemo(
    () => convertLoansResponseToOptions(loansData),
    [loansData]
  );

  if (isLoansLoading) {
    return <LinearProgress />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={styles.content}>
          <MessageArea
            messageStatus="warning"
            message="You will be redirected to your email client. Further discussion will be held outside the platform via emails."
            title="Note"
          />
          <Grid container className={styles.fields}>
            <Grid item xs={12}>
              {isLoansList ? (
                <Controller
                  name="loanId"
                  control={methods.control}
                  render={({ field: { value = '', ...props } }) => (
                    <SelectWithPlaceholder
                      value={value}
                      placeholder="Select"
                      label="Select Loan"
                      selectOptions={selectOptions}
                      {...props}
                    >
                      {selectOptions.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </SelectWithPlaceholder>
                  )}
                />
              ) : (
                <Controller
                  name="loanName"
                  control={methods.control}
                  render={({ field: { value = '', ...props } }) => (
                    <TextField
                      error={!!methods.formState.errors.loanName}
                      placeholder="Enter loan name"
                      disabled
                      label="Loan name"
                      value={value}
                      {...props}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <LockedIcon className={styles.icon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={methods.control}
                render={({ field: { value = '', ...props } }) => (
                  <TextField
                    error={!!methods.formState.errors.title}
                    placeholder="Enter data"
                    label="Title"
                    value={value}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={methods.control}
                render={({ field: { value = '', ...props } }) => (
                  <TextField
                    error={!!methods.formState.errors.description}
                    placeholder="E.g:Here you can  ask questions about your status or request exception to review the application again."
                    label="Description"
                    value={value}
                    multiline
                    rows={6}
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ExceptionRequestFileUpload
                errors={errors}
                maxSize={5000000}
                isAcceptFile={isAcceptFile}
                uploadFiles={uploadFiles}
                files={files}
              />
            </Grid>
          </Grid>
        </Grid>
        {isSendExceptionLoading && <LinearProgress />}
        <footer className={styles.footer}>
          <Button
            variant="text"
            type="submit"
            size="large"
            onClick={() => handledClose()}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            size="large"
            disabled={
              !(
                formState.dirtyFields.description &&
                formState.dirtyFields.title &&
                files.length !== 0 &&
                !isSendExceptionLoading
              )
            }
          >
            Submit exception request
          </Button>
        </footer>
      </form>
    </FormProvider>
  );
};
