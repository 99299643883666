import React, { PropsWithChildren, ReactNode } from 'react';
import { LinearProgress, Paper } from '@mui/material';
import { ContentSectionHeader } from './components/ContentSectionHeader/ContentSectionHeader.component';

export interface ContentSectionProps {
  title?: string;
  description?: string;
  actions?: ReactNode;
  isLoading?: boolean;
}

export const ContentSection = ({
  actions,
  title,
  description,
  isLoading,
  children,
}: PropsWithChildren<ContentSectionProps>) => {
  return (
    <Paper elevation={0}>
      <ContentSectionHeader
        title={title}
        description={description}
        actions={actions}
      />
      {isLoading && <LinearProgress variant="indeterminate" value={30} />}
      {!isLoading && <section>{children}</section>}
    </Paper>
  );
};
