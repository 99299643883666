import React, { useMemo, useState, useEffect } from 'react';
import {
  Button,
  Link,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  AddIcon,
  useTableDataSearchAndFilter,
} from '@lola/ui-react-components';
import { useRole } from '@hooks/useRole';
import { SubpageHeaderComponent } from '@components/SubpageHeader/SubpageHeader.component';
import { DataControlPanel } from '@components/DataTableControlPanel/DataTableControlPanel.component';
import { ModeSwitcher } from '@components/ModeSwitcher';
import { PeriodConfig, PeriodSelector } from '@components/PeriodSelector';
import { getLast7yearsObject } from '@utils/date.utils';
import { CAN_START_LOAN } from '@pages/dashboard/dashboard.constants';
import { MyLoansActiveLoans, MyLoansInactiveLoans } from './components';
import { MODE_SWITCHER_VALUES, MY_LOANS_CONFIGS } from './MyLoans.constants';
import { sortBy } from './types';
import styles from './myLoans.module.scss';

export const MyLoansPage = (): JSX.Element => {
  const role = useRole();
  const [period, setPeriod] = useState<PeriodConfig>({
    ...getLast7yearsObject(),
    value: 'all',
  });
  const [mode, setMode] = useState<MODE_SWITCHER_VALUES>(
    MODE_SWITCHER_VALUES.ACTIVE
  );
  const [isDateSorter, setIsDateSorter] = useState<sortBy>(sortBy.newToOld);
  const isActiveLoans: boolean = mode === MODE_SWITCHER_VALUES.ACTIVE;
  const [statusFilters, setStatusFilters] = useState<(string | undefined)[]>(
    []
  );

  const handledDateSorter = (value: SelectChangeEvent) =>
    setIsDateSorter(value?.target?.value as sortBy);
  const resetDataSorter = () => setIsDateSorter(sortBy.newToOld);

  const myLoansItem = useMemo(() => MY_LOANS_CONFIGS[role], [role]);

  useEffect(() => {
    handleResetSearchChange();
    handleResetFilterChange();
    resetDataSorter();
  }, [mode]);

  useEffect(() => {
    if (myLoansItem) {
      setMode(myLoansItem.defaultValue);
    }
  }, [myLoansItem]);

  const loanSwitcher = useMemo(
    () =>
      myLoansItem &&
      mode && (
        <ModeSwitcher
          value={mode}
          options={myLoansItem.options}
          onSelect={(value) => setMode(value as MODE_SWITCHER_VALUES)}
        />
      ),
    [mode, myLoansItem]
  );

  const {
    handleSearchChange,
    searchTerm,
    handleFilterChange,
    handleResetFilterChange,
    handleResetSearchChange,
    appliedFilters,
    filters,
  } = useTableDataSearchAndFilter(
    [
      {
        status: statusFilters,
      },
    ],
    {
      status: {
        values: [],
        title: 'Status',
      },
    }
  );

  return (
    <div className={styles.section}>
      <SubpageHeaderComponent title="Loans" titleChip={loanSwitcher}>
        {CAN_START_LOAN.includes(role) && (
          <Link component={NavLink} to="/new-loan" underline="none">
            <Button variant="contained" startIcon={<AddIcon />} size="large">
              Start a loan
            </Button>
          </Link>
        )}
        {!isActiveLoans && (
          <PeriodSelector
            onPeriodChange={(newPeriod) => setPeriod(newPeriod)}
            period={period}
          />
        )}
      </SubpageHeaderComponent>

      <DataControlPanel
        appliedFilters={appliedFilters}
        filters={filters}
        defaultSearchValue={searchTerm}
        handleFilterChange={handleFilterChange}
        handleSearchChange={handleSearchChange}
        searchPlaceholder="Search by loan name, loan ID, address, or principal guarantor"
      >
        {isActiveLoans && (
          <div className={styles.selectBy}>
            <Typography variant="p">Sort by</Typography>
            <Select
              value={isDateSorter}
              label={sortBy.newToOld}
              onChange={handledDateSorter}
              className={styles.selectByComponent}
            >
              <MenuItem value={sortBy.newToOld}>Created: old to new</MenuItem>
              <MenuItem value={sortBy.oldToNew}>Created: new to old</MenuItem>
            </Select>
          </div>
        )}
      </DataControlPanel>

      {isActiveLoans ? (
        <MyLoansActiveLoans
          searchTerm={searchTerm}
          isSortBy={isDateSorter}
          statusFilter={appliedFilters.status.values}
          setStatusFilters={setStatusFilters}
        />
      ) : (
        <MyLoansInactiveLoans
          searchTerm={searchTerm}
          period={period}
          statusFilter={appliedFilters.status.values}
          setStatusFilters={setStatusFilters}
        />
      )}
    </div>
  );
};
