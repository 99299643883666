import React from 'react';
import { Grid, Button } from '@mui/material';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { Notification } from '@components/Notification/Notification.component';
import { useOpenState } from '@hooks/useOpenState';
import { FormData } from '@form-configs/types';
import { FIELD_NAMES } from '@pages/personalLoans/pages/buildMyLoan/pages/product/components/LicensingForm/licensingForm.constants';
import { LicensingFields } from './LicensingFields.component';
import styles from './licensingFormValid.module.scss';

export const isDefaultExpanded = (values: FormData) =>
  FIELD_NAMES.map((field: string) => get(values, field)).filter(Boolean)
    .length > 0;

export const LicensingFormValid = () => {
  const { getValues } = useFormContext();
  const [isShown, show] = useOpenState(isDefaultExpanded(getValues()));

  return (
    <>
      <Grid item xs={12}>
        <Notification
          severity="info"
          title="3d party license"
          withIcon
          text="You can add 3d party license. It is optional."
          className={styles.notification}
          actionButton={
            <Button variant="outlined" onClick={show}>
              Add 3d party license
            </Button>
          }
        />
      </Grid>
      {isShown && (
        <Grid item>
          <LicensingFields />
        </Grid>
      )}
    </>
  );
};
