import React, { useMemo } from 'react';
import { Button, IconButton, List } from '@mui/material';
import { DeleteIcon } from '@lola/ui-react-components';
import {
  Notification,
  NotificationProps,
} from '@components/Notification/Notification.component';
import { UPLOAD_STATUS } from '@constants/uploader.constants';
import { FileDeleteHandler, FileItem } from '../../Uploader.types';
import { FileListItem } from '../FileListItem/FileListItem.component';
import { CommentItem } from '../CommentItem/CommentItem.component';
import { UPLOADER_STATE } from '../../Uploader.constants';

import styles from './fileList.module.scss';

const STATUS_WITHOUT_DELETE = [
  UPLOAD_STATUS.IN_REVIEW,
  UPLOAD_STATUS.REOPENED,
  UPLOAD_STATUS.DENIED,
  UPLOAD_STATUS.APPROVED,
];

interface FileListProps {
  state?: UPLOADER_STATE;
  onDelete?: FileDeleteHandler;
  files?: FileItem[];
  processingFiles?: FileItem[];
  fileDelete?: boolean;
  notification?: NotificationProps | null;
  status?: UPLOAD_STATUS;
  comments?: string | null;
  setUploaderSate?: (arg: UPLOADER_STATE) => void;
}

export const FileList = ({
  files = [],
  processingFiles = [],
  state,
  fileDelete = true,
  onDelete,
  notification,
  status,
  comments,
}: FileListProps) => {
  const isDeleteDisabled = useMemo(
    () => STATUS_WITHOUT_DELETE.includes(status as UPLOAD_STATUS) || !onDelete,
    [status, onDelete]
  );

  if (!files.length) {
    return null;
  }

  return (
    <List>
      {files.map((fileItem) => {
        return (
          <FileListItem
            key={fileItem.fileName}
            file={fileItem}
            state={state as UPLOADER_STATE}
            error={fileItem.metadata?.error}
            isProcessing={
              !!processingFiles.find(
                ({ fileName }) => fileName === fileItem.fileName
              )
            }
            secondaryIcon={
              fileDelete ? (
                <IconButton
                  edge="end"
                  aria-label="delete file"
                  disabled={
                    isDeleteDisabled || state === UPLOADER_STATE.LOADING
                  }
                  onClick={() => onDelete?.(fileItem)}
                >
                  {<DeleteIcon />}
                </IconButton>
              ) : null
            }
          />
        );
      })}
      {comments && <CommentItem comments={comments} />}
      {notification?.severity && (
        <Notification
          severity={notification.severity}
          title={notification.title}
          text={notification.text}
          className={styles.listNotification}
          actionButton={
            status === UPLOAD_STATUS.DENIED ? (
              <Button
                color="inherit"
                variant="outlined"
                classes={{ root: styles.action }}
                onClick={() => onDelete?.(files[0])}
              >
                Upload new file
              </Button>
            ) : null
          }
        />
      )}
    </List>
  );
};
