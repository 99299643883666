import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UploadFileItem } from '@components/Uploader/hooks/useFilesUploader';
import { LOAN_PDF_APPLICATION_TYPE } from '@typings/common';
import { usePDFUploader } from '@hooks/usePDFUploader';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan';
import {
  LolaBffApiContractsModelsLoanTaskInfo,
  useLazyGetApiV1LoansByLoanIdDocumentTasksQuery,
} from '@api/output/api';
import { checkFileExtension } from '@utils/file.utils';
import { CustomFileUploader } from '@pages/personalLoans/pages/buildMyLoan/components/CustomFileUploader/CustomFileUploader.component';
import { useOpenToast } from '@hooks/useOpenToast';
import { ApiError, convertCatchError } from '@utils/errors.utils';
import { DEFAULT_API_ERROR_TITLE } from '@constants/text.constants';
import {
  PORTFOLIO_PDF_UPLOADER_CONFIG,
  PORTFOLIO_PDF_UPLOADER_ALLOWED_EXTENSIONS,
  PORTFOLIO_PDF_UPLOADER_DRAWER_CONFIG,
} from './PortfolioPdfUpload.constants';

export interface PortfolioPdfUploadProps {
  task: LolaBffApiContractsModelsLoanTaskInfo | undefined;
  disabled?: boolean;
}

export const PortfolioPdfUpload = ({
  task,
  disabled,
}: PortfolioPdfUploadProps) => {
  const { loanId = '' } = useParams();
  const openToast = useOpenToast();
  const { refetchLoanDetails } = useLoanDetails();
  const [reloadTasks] = useLazyGetApiV1LoansByLoanIdDocumentTasksQuery();
  const { uploadPdf } = usePDFUploader(LOAN_PDF_APPLICATION_TYPE.PORTFOLIO_APP);

  const uploadedFiles = useMemo(() => {
    const match = task?.documents?.find((file) =>
      checkFileExtension(
        file.fileName,
        PORTFOLIO_PDF_UPLOADER_ALLOWED_EXTENSIONS
      )
    );

    return match ? [match] : [];
  }, [task?.documents]);

  const uploadOperationAsyncHandler = useCallback(
    async (file: UploadFileItem) => {
      try {
        await uploadPdf(file);
      } catch (err) {
        const description = convertCatchError(err as ApiError);
        openToast({
          isError: true,
          title: DEFAULT_API_ERROR_TITLE,
          description,
        });
      }
    },
    [uploadPdf]
  );

  const completeOperationAsyncHandler = useCallback(async () => {
    try {
      await refetchLoanDetails();
    } catch (err) {
      const description = convertCatchError(err as ApiError);
      openToast({
        isError: true,
        title: DEFAULT_API_ERROR_TITLE,
        description,
      });
    }
  }, [reloadTasks]);

  const onDeleteHandler = useCallback(async () => {
    try {
      await reloadTasks({ loanId });
    } catch (err) {
      const description = convertCatchError(err as ApiError);
      openToast({
        isError: true,
        title: DEFAULT_API_ERROR_TITLE,
        description,
      });
    }
  }, [reloadTasks]);

  return (
    <CustomFileUploader
      task={task}
      title="Application"
      placeholder="Pdf, less than 20mbs"
      uploaderConfig={PORTFOLIO_PDF_UPLOADER_CONFIG}
      drawerConfig={PORTFOLIO_PDF_UPLOADER_DRAWER_CONFIG}
      uploadedFiles={uploadedFiles}
      disabled={disabled}
      onDelete={onDeleteHandler}
      completeOperationAsync={completeOperationAsyncHandler}
      uploadOperationAsync={uploadOperationAsyncHandler}
    />
  );
};
