import { UploaderDrawerConfig } from '@components/UploadDrawer/uploaderDrawer.types';
import { DEFAULT_UPLOADER_DRAWER_CONFIG } from '@components/UploadDrawer/uploaderDrawer.constants';
import {
  DEFAULT_UPLOADER_CONFIG,
  UPLOADER_STATE,
} from '@components/Uploader/Uploader.constants';

export const PORTFOLIO_PDF_UPLOADER_ALLOWED_EXTENSIONS = ['pdf'];

export const PORTFOLIO_PDF_UPLOADER_CONFIG = {
  ...DEFAULT_UPLOADER_CONFIG,
  description: 'Accepted file types: XLSX',
  submitButtonText: 'Submit to review',
  textConfig: {
    ...DEFAULT_UPLOADER_CONFIG.textConfig,
    [UPLOADER_STATE.INIT]: 'Drop your documents here or',
    [UPLOADER_STATE.SUCCESS]: 'Documents were successfully uploaded',
  },
  rules: {
    ...DEFAULT_UPLOADER_CONFIG.rules,
    allowedExtensions: {
      rule: PORTFOLIO_PDF_UPLOADER_ALLOWED_EXTENSIONS,
      message: 'Wrong file extension',
    },
    maxFiles: 1,
  },
};

export const PORTFOLIO_PDF_UPLOADER_DRAWER_CONFIG: UploaderDrawerConfig = {
  ...DEFAULT_UPLOADER_DRAWER_CONFIG,
  title: 'Portfolio property',
};
