import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Alert, Grid, LinearProgress, Paper } from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SAVED_QUOTE_ID_TO_LOAN } from '@pages/personalLoans/pages/buildMyLoan/buildMyLoan.constants';
import { FormData } from '@form-configs/types';
import { QuoteCalculation } from '@components/QuoteCalculation/QuoteCalculation.component';
import { LoanDisableContext } from '@pages/personalLoans/pages/buildMyLoan/BuildMyLoan.context';
import { useQuoteTypeAndId } from '@pages/personalLoans/pages/buildMyLoan/hooks/useQuoteTypeAndId';
import { ComponentsContext } from '@components/DynamicForm/contexts/Components.context';
import { usePrefill } from '@pages/personalLoans/pages/buildMyLoan/pages/product/components/PrefillModal/hooks/usePrefill';
import { useProductAccordion } from '@pages/personalLoans/pages/buildMyLoan/pages/product/hooks/useProductAccordion';
import { DebugMode } from '@pages/personalLoans/pages/buildMyLoan/pages/product/components/DebugMode/DebugMode.component';
import { PrefillModal } from './components/PrefillModal/PrefillModal.component';
import {
  LicensingForm,
  LicensingFormContext,
  useLicenseForm,
} from './components/LicensingForm';
import { useLoanToQuoteMapper, useQuotesProductApi } from './hooks';
import { ProductDetails, LimitedFieldPropsContext } from './components';
import { useAttachQuote } from './hooks/useAttachQuoute';

export const ProductPage = () => {
  const isUserInteraction = useRef(false);
  const { disabled } = useContext(LoanDisableContext);
  const { loanId = '' } = useParams();

  const { quoteType, quoteId } = useQuoteTypeAndId();
  const loanMethods = useFormContext();
  const quoteMethods = useForm<FormData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { licenseMethods, saveLicense } = useLicenseForm();

  const onAttach = useCallback((quoteId: string) => {
    loanMethods.setValue(SAVED_QUOTE_ID_TO_LOAN, quoteId);
    isUserInteraction.current = true;
  }, []);

  const [isQuoteAttaching, selectQuote] = useAttachQuote(loanId, onAttach);

  const { save, isQuoteLoading, data, summary, isCalculating } =
    useQuotesProductApi({
      type: quoteType,
      quoteId,
    });

  const { quoteInData } = useLoanToQuoteMapper(
    loanMethods,
    quoteMethods,
    quoteType,
    data?.in
  );

  const [accordionValue, setAccordionValue, accordionHandler] =
    useProductAccordion(data);
  const { modal, fields, prepareToPrefill } = usePrefill(loanMethods);

  useEffect(() => {
    if (quoteInData) {
      quoteMethods.reset(quoteInData);
    }
  }, [quoteInData]);

  const submitHandler = useMemo(() => {
    return quoteMethods.handleSubmit(async (values) => {
      isUserInteraction.current = true;
      const id = await save(values);
      quoteMethods.reset(values);
      await saveLicense();
      if (id && quoteId !== id) {
        loanMethods.setValue(SAVED_QUOTE_ID_TO_LOAN, id);
      }
    });
  }, [quoteId, quoteMethods, save, saveLicense]);

  useEffect(() => {
    const dataQuoteId = data?.quoteInfo?.id ? +data.quoteInfo.id : -1;
    const loanQuoteId = +quoteId;

    if (data && dataQuoteId === loanQuoteId && isUserInteraction.current) {
      isUserInteraction.current = false;
      prepareToPrefill(data);
    }
  }, [quoteId, data]);

  if (isQuoteLoading || isQuoteAttaching) {
    return <LinearProgress />;
  }

  return (
    <>
      <PrefillModal {...modal} fields={fields} />
      {isQuoteLoading || isQuoteAttaching ? (
        <LinearProgress />
      ) : (
        <LicensingFormContext.Provider value={licenseMethods}>
          <FormProvider {...quoteMethods}>
            <ComponentsContext.Provider
              value={{
                licensing: LicensingForm,
              }}
            >
              <LimitedFieldPropsContext quoteType={quoteType}>
                <form onSubmit={submitHandler} noValidate>
                  <Grid container spacing={2}>
                    <DebugMode methods={quoteMethods} />
                    <Grid item xs={12}>
                      <ProductDetails
                        quoteType={quoteType}
                        accordionValue={accordionValue}
                        onChange={accordionHandler}
                        onQuoteSelect={selectQuote}
                        data={data}
                        quoteId={quoteId}
                        calculationErrors={summary.errors}
                        isCalculating={isCalculating}
                        isLicensingFormDirty={licenseMethods.formState.isDirty}
                      />
                      {isCalculating ? <LinearProgress /> : null}
                    </Grid>
                    <Grid item xs={12}>
                      <QuoteCalculation
                        quoteType={quoteType}
                        details={data}
                        accordionValue={accordionValue}
                        setAccordionValue={setAccordionValue}
                        onChange={accordionHandler}
                        calculationErrors={summary.errors}
                        warnings={summary.warnings}
                        isCalculating={isCalculating}
                        disabled={disabled}
                        showStageInfoBlock={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Paper elevation={0}>
                        <Alert severity="info">
                          Please be advised that certain data will be reused
                          during the Application step for your convenience and
                          efficiency.
                        </Alert>
                      </Paper>
                    </Grid>
                  </Grid>
                </form>
              </LimitedFieldPropsContext>
            </ComponentsContext.Provider>
          </FormProvider>
        </LicensingFormContext.Provider>
      )}
    </>
  );
};
