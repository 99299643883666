import React from 'react';
//MUI
import { Button, Grid, Typography } from '@mui/material';
//Router
import { useParams } from 'react-router-dom';
//Components
import { Modal } from '@components/Modal';
//Styles
import styles from './archiveLoanModal.module.scss';

export interface ArchiveLoanModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  handleArchive: (loanId: string) => void;
}

// *Nota: all the props come from useArchiveLoan custom hook
export const ArchiveLoanModal = ({
  isModalOpen,
  onClose,
  handleArchive,
}: ArchiveLoanModalProps) => {
  const { loanId = '' } = useParams();
  return (
    <Modal
      title="Archive a loan"
      isOpen={isModalOpen}
      onClose={onClose}
      width={636}
    >
      <Grid>
        <Typography variant="p">
          {
            'Once you archive this loan, you cannot make any changes. To unarchive the loan, you will need to request approval. Are you sure you want to archive this loan?'
          }
        </Typography>
        <div className={styles.modalFooter}>
          <Button variant="text" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" onClick={() => handleArchive(loanId)}>
            Archive loan
          </Button>
        </div>
      </Grid>
    </Modal>
  );
};
