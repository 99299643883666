import React, { useMemo } from 'react';
import { TableColumnType } from 'antd';
import { LinearProgress } from '@mui/material';
import classnames from 'classnames';
import {
  TeamIcon,
  createDefaultSorter,
  DataTable,
  textRenderer,
  useTableScroll,
  dateRenderer,
  useTableDataSearch,
  RowRecord,
} from '@lola/ui-react-components';
import { DataSearch } from '@components/DataTableControlPanel/components/DataSearch/DataSearch.component';
import { LolaBffApiContractsModelsLoanPartyInfo } from '@api/output/api';
import { NoContent } from '@components/NoContent';
import { useEntitySearch } from '@containers/ListOfEntities/hooks/useEntitySearch';
import styles from './listOfEntities.module.scss';

export interface ListOfEntitiesProps {
  className?: string;
  fullHeight?: boolean;
  selectedId?: string;
  onChange: (record: RowRecord) => void;
}

const columns: TableColumnType<LolaBffApiContractsModelsLoanPartyInfo>[] = [
  {
    dataIndex: 'displayName',
    title: 'Borrowing entity name',
    width: '30%',
    render: textRenderer(true),
    sorter:
      createDefaultSorter<LolaBffApiContractsModelsLoanPartyInfo>(
        'displayName'
      ),
  },
  {
    dataIndex: 'taxpayerIdentifierValue',
    title: 'Business EIN',
    width: '25%',
    render: textRenderer(true),
    sorter: createDefaultSorter<LolaBffApiContractsModelsLoanPartyInfo>(
      'taxpayerIdentifierValue'
    ),
  },
  {
    dataIndex: 'lastLoanDate',
    title: 'Last loan date',
    width: '20%',
    render: dateRenderer,
    sorter:
      createDefaultSorter<LolaBffApiContractsModelsLoanPartyInfo>(
        'lastLoanDate'
      ),
  },
  {
    dataIndex: 'amountOfLoans',
    title: 'Amount of loans',
    width: '25%',
    sorter:
      createDefaultSorter<LolaBffApiContractsModelsLoanPartyInfo>(
        'amountOfLoans'
      ),
  },
];

export const SEARCH_HEADER_HEIGHT = 65;

export const ListOfEntities = ({
  className,
  selectedId,
  onChange,
  fullHeight = false,
}: ListOfEntitiesProps) => {
  const { data, isFetching } = useEntitySearch();

  const { newData, handleSearchChange } =
    useTableDataSearch<LolaBffApiContractsModelsLoanPartyInfo>(
      data ?? [],
      'displayName'
    );

  const tableData = useMemo(
    () => newData.filter(({ displayName }) => !!displayName),
    [newData]
  );

  const [ref, scroll] = useTableScroll(fullHeight, -SEARCH_HEADER_HEIGHT, {
    x: 600,
    y: 400,
  });

  if (isFetching) {
    return <LinearProgress />;
  }

  return (
    <div className={classnames(className)} ref={ref}>
      {!data?.length && (
        <NoContent
          variant="grey"
          icon={<TeamIcon />}
          title="No data to show here yet"
          text="Create first borrowing entity before"
        />
      )}
      {!!data?.length && (
        <>
          <div className={styles.headerSection}>
            <DataSearch handleSearchChange={handleSearchChange} />
          </div>
          <DataTable
            rowKey="id"
            highlightRows={selectedId ? [selectedId] : undefined}
            columns={columns}
            dataSource={tableData}
            onRowClick={onChange}
            scroll={scroll}
          />
        </>
      )}
    </div>
  );
};
