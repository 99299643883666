import React, { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Stack,
} from '@mui/material';
import { Notification } from '@components/Notification/Notification.component';
import { ROLES } from '@typings/common';
import { useRole } from '@hooks/useRole';
import { SubpageHeaderComponent } from '@components/SubpageHeader/SubpageHeader.component';
import { ProgressStepper } from '@components/ProgressStepper/ProgressStepper.component';
import { ProgressStepItemStatus } from '@components/ProgressStepper/types';
import {
  usePostApiV1LoansCreateFromQuoteMutation,
  usePostApiV1LoansMutation,
} from '@api/output/api';
import { SelectRequiredUser } from '@containers/SelectRequiredUser/SelectRequiredUser.component';
import { submitLoanForm } from '@pages/createLoan/utils/submitLoanForm.utils';
import { useCreateLoanForm } from '@pages/createLoan/hooks/useCreateLoanForm';
import { FormSection } from './components/FormSection/FormSection.component';
import { ProductPicker } from './components/ProductPicker/ProductPicker.component';
import { BorrowerPicker } from './components/BorrowerPicker/BorrowerPicker.component';
import {
  CRUMBS,
  FIELDS,
  INITIAL_STEPPER_CONFIG,
  TITLE_MAP,
  LABEL_MAP,
  REQUIRED_ROLE_MAP,
} from './CreateLoan.constants';
import { updateStepperConfig } from './utils/updateStepperConfig';

import styles from './createLoan.module.scss';

export const BuildBorrower = () => {
  const [createLoan, loanData] = usePostApiV1LoansMutation();
  const [createLoanFromQuote, quoteData] =
    usePostApiV1LoansCreateFromQuoteMutation();
  const data = loanData.data || quoteData.data;
  const isError = loanData.isError || quoteData.isError;
  const isLoading = loanData.isLoading || quoteData.isLoading;
  const isSuccess = loanData.isSuccess || quoteData.isSuccess;
  const navigate = useNavigate();
  const role = useRole();

  const { methods, borrower, loanType, requiredUserId, quoteId, quoteType } =
    useCreateLoanForm();

  const activeStep = useMemo(() => {
    const isBorrowerSelected = borrower || borrower === null;

    if (isBorrowerSelected && loanType && requiredUserId) {
      return '';
    }

    if (isBorrowerSelected && loanType) {
      return FIELDS.USER_ID;
    }

    if (isBorrowerSelected) {
      return FIELDS.LOAN_TYPE;
    }
    return FIELDS.BORROWER;
  }, [borrower, loanType, requiredUserId]);

  const steps = useMemo(() => {
    return updateStepperConfig<FIELDS | ''>(
      INITIAL_STEPPER_CONFIG[role],
      activeStep,
      {
        [FIELDS.BORROWER]: borrower,
        [FIELDS.LOAN_TYPE]: borrower || borrower === null ? loanType : '',
        [FIELDS.USER_ID]: requiredUserId,
      }
    );
  }, [activeStep, borrower, loanType, requiredUserId]);

  const handleSubmit = useMemo(
    () =>
      submitLoanForm({
        methods,
        role,
        createLoan,
        createLoanFromQuote,
        quoteType,
        quoteId,
      }),
    [methods, role, createLoan, createLoanFromQuote, quoteType, quoteId]
  );

  useEffect(() => {
    if (isSuccess && data) {
      navigate(`/personal-loans/${data.id}`);
    }
  }, [isSuccess]);

  const areAllFieldsFilled =
    role === ROLES.PROCESSOR ? requiredUserId : quoteType || loanType;
  const isSubmitDisabled = !areAllFieldsFilled || isLoading;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <section className={styles.section}>
          <SubpageHeaderComponent title="New loan" breadcrumbs={CRUMBS} />
          <Grid container spacing={4} padding={4}>
            <Grid item xs={4}>
              <Paper elevation={0} className={styles.stepper}>
                <ProgressStepper
                  headerTitle="Steps"
                  items={steps}
                  activeItem={activeStep}
                />
              </Paper>
            </Grid>

            <Grid item xs={8}>
              <Stack spacing={2}>
                <FormSection title={TITLE_MAP[role][FIELDS.BORROWER]}>
                  <BorrowerPicker />
                </FormSection>
                <FormSection
                  title={TITLE_MAP[role][FIELDS.LOAN_TYPE]}
                  isDisabled={steps[0].status !== ProgressStepItemStatus.DONE}
                >
                  <Grid container spacing={4}>
                    {!!quoteId && (
                      <Grid item xs={12}>
                        <Notification
                          severity="info"
                          withIcon
                          title={`${loanType} loan program`}
                          text={`"${loanType}" was selected when the quote was created. Therefore, changing credit programs are disabled.`}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <ProductPicker disabled={!!quoteId} />
                    </Grid>
                  </Grid>
                </FormSection>
                <FormSection
                  title={TITLE_MAP[role][FIELDS.USER_ID]}
                  isDisabled={steps[1].status !== ProgressStepItemStatus.DONE}
                >
                  <SelectRequiredUser
                    name={FIELDS.USER_ID}
                    label={LABEL_MAP[role]}
                    roles={[REQUIRED_ROLE_MAP[role]]}
                    skipFetch={!loanType}
                    variant="filled"
                  />
                </FormSection>
                {isError && (
                  <Alert severity="error">
                    <AlertTitle>
                      Something went wrong during saving the loan.
                    </AlertTitle>
                    Please check the loan details and try again.
                  </Alert>
                )}
              </Stack>
            </Grid>
          </Grid>

          <footer className={styles.footer}>
            {isLoading && <LinearProgress className={styles.loading} />}
            <Link component={NavLink} to="/" underline="none">
              <Button>Cancel</Button>
            </Link>

            <Button
              variant="contained"
              disabled={isSubmitDisabled}
              type="submit"
            >
              Create a loan
            </Button>
          </footer>
        </section>
      </form>
    </FormProvider>
  );
};
