import React, { useCallback, useEffect, useMemo, useState } from 'react';
// MUI
import {
  Button,
  Tab,
  Tabs,
  ChipProps,
  LinearProgress,
  Alert,
  Link,
} from '@mui/material';
// Router
import { NavLink, Outlet } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  CheckMyLoansIcon,
  MoreVerticalIcon,
  ArchiveIcon,
  ManageAccessIcon,
} from '@lola/ui-react-components';
import { DAY_MONTH_YEAR_FORMAT } from '@constants/date.constants';
import { SubpageHeaderComponent } from '@components/SubpageHeader/SubpageHeader.component';
// Icons
// Hooks
import { useActiveRoute } from '@hooks/useActiveRoute';
import { useArchiveLoan } from '@pages/personalLoans/hooks/useArchiveLoan';
import { useOpenState } from '@hooks/useOpenState';
import { MenuButton } from '@components/MenuButton';
import { ManageAccessDrawer } from '@containers/ManageAccessDrawer/ManageAccessDrawer.component';
import { DrawerState } from '@containers/ManageAccessDrawer/ManageAccessDrawer.constants';
import { useLoanDetails } from '@pages/personalLoans/pages/buildMyLoan';
import { TABS } from './personalLoans.constants';
import { ArchiveLoanModal, LoanQuickView } from './components';
// Styles
import styles from './personalLoans.module.scss';

const PORTFOLIO_THRESHOLD = 1;

const DEFAULT_BREADCRUMB_PERSONAL_LOANS = {
  title: 'Loans',
  link: '/personal-loans',
};

export const PersonalLoans = () => {
  const {
    data: { loan, party, properties },
    isLoanFetching,
    refetchLoan,
    refetchLoanDetails,
    isError,
  } = useLoanDetails();

  // Hooks
  const [isQuickViewOpen, openQuickView, closeQuickView] = useOpenState();

  const [drawerState, setDrawerState] = useState<DrawerState>({
    isOpen: false,
  });
  const handleDrawerClose = useCallback(() => {
    setDrawerState({ isOpen: false });
  }, []);

  const [activeRoute] = useActiveRoute(TABS);
  const { switchArchiveLoanModalOpen, handleArchive, archiveLoanModalOpen } =
    useArchiveLoan({
      loanIdentifier: loan?.loanIdentifier ?? '',
      loanName: loan?.loanName ?? '',
      callback: () => refetchLoan(),
    });

  useEffect(() => {
    if (refetchLoanDetails) refetchLoanDetails();
  }, [refetchLoanDetails]);

  const title = useMemo(() => {
    if (!properties?.length) return `New loan #${loan?.loanIdentifier}`;

    const isPortfolio = properties.length > PORTFOLIO_THRESHOLD;
    const portfolioName = `Portfolio - ${properties.length}`;
    const singleName = properties[0]?.address?.addressLineText ?? '';
    const loanName = isPortfolio ? portfolioName : singleName;
    return `${loanName} #${loan?.loanIdentifier}`;
  }, [properties, loan?.loanIdentifier]);

  const crumbs = useMemo(
    () => [
      DEFAULT_BREADCRUMB_PERSONAL_LOANS,
      {
        title,
      },
    ],
    [title]
  );

  const menuButtonOptions = useMemo(() => {
    const archiveOption = {
      label: 'Archive loan',
      startIcon: <ArchiveIcon />,
      action: switchArchiveLoanModalOpen,
    };

    const options = [
      {
        label: 'Manage access',
        startIcon: <ManageAccessIcon />,
        action: () => setDrawerState({ isOpen: true }),
      },
    ];

    return loan?.canBeArchived ? [archiveOption, ...options] : options;
  }, [loan]);

  const borrowerName = useMemo(() => {
    if (party?.borrower) {
      const { firstName, lastName } = party.borrower;
      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }
      if (firstName) {
        return firstName;
      }
      if (lastName) {
        return lastName;
      }
    }

    return '-';
  }, [party?.borrower]);

  const chips: ChipProps[] = useMemo(() => {
    const createdAt = dayjs(loan?.createdAt).format(DAY_MONTH_YEAR_FORMAT);
    const updatedAt = dayjs(loan?.modifiedAt).format(DAY_MONTH_YEAR_FORMAT);

    return [
      {
        label: loan?.loanStatus ?? 'N/A',
      },
      {
        label: `Borrower: ${borrowerName}`,
        variant: 'standard',
      },
      {
        label: `Product: ${
          loan?.aggregations?.loanProgramType?.thirdParty ?? '-'
        }`,
        variant: 'standard',
      },
      {
        label: `Created: ${createdAt}`,
        variant: 'standard',
      },
      {
        label: `Last update: ${updatedAt}`,
        variant: 'standard',
      },
    ];
  }, [loan, borrowerName]);

  if (isLoanFetching) {
    return <LinearProgress />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        The loan is not found. Go to{' '}
        <Link component={NavLink} to="/personal-loans" underline="none">
          <strong>Personal loans</strong>
        </Link>
      </Alert>
    );
  }

  return (
    <>
      <SubpageHeaderComponent title={title} breadcrumbs={crumbs} chips={chips}>
        <Button
          variant="outlined"
          size="large"
          startIcon={<CheckMyLoansIcon />}
          onClick={openQuickView}
        >
          Loan quick view
        </Button>
        <MenuButton
          label="Actions"
          startIcon={<MoreVerticalIcon />}
          options={menuButtonOptions}
        />
      </SubpageHeaderComponent>
      <Tabs className={styles.tabs} value={activeRoute}>
        {TABS.map((tabProps) => (
          <Tab component={NavLink} key={tabProps.value} {...tabProps} />
        ))}
      </Tabs>

      <Outlet />

      <ManageAccessDrawer
        drawerState={drawerState}
        onClose={handleDrawerClose}
      />

      <LoanQuickView isDrawerOpen={isQuickViewOpen} onClose={closeQuickView} />

      <ArchiveLoanModal
        isModalOpen={archiveLoanModalOpen}
        onClose={switchArchiveLoanModalOpen}
        handleArchive={handleArchive}
      />
    </>
  );
};
