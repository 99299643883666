import React, { useMemo } from 'react';
import classNames from 'classnames';
import { LinearProgress, Typography } from '@mui/material';
import { ProgressIndicatorProps } from '../../types';
import { progressIndicator } from '../constants/progressIndicator.constants';
import styles from '../../styles/progressIndicator.module.scss';

export const ProgressIndicatorLinear = ({
  totalSteps,
  completedSteps,
  locked,
}: ProgressIndicatorProps) => {
  const percentage = useMemo(() => {
    const percentage = Math.round((completedSteps / totalSteps) * 100);
    return isNaN(percentage) ? 0 : percentage;
  }, [completedSteps, totalSteps]);

  return (
    <>
      <Typography
        className={classNames(styles.text, {
          [styles.locked]: locked,
        })}
      >
        {locked
          ? progressIndicator.notAvailableText
          : `${percentage}% completed`}
      </Typography>
      <LinearProgress
        className={styles.progressBar}
        variant="determinate"
        value={percentage}
      />
    </>
  );
};
