import React from 'react';
import { TableColumnType } from 'antd';
import classnames from 'classnames';
import { LinearProgress } from '@mui/material';
import {
  TeamIcon,
  createDefaultSorter,
  createDateSorter,
  DataTable,
  dateRenderer,
  textRenderer,
  useTableScroll,
  useTableDataSearch,
  RowRecord,
} from '@lola/ui-react-components';
import { DataSearch } from '@components/DataTableControlPanel/components/DataSearch/DataSearch.component';
import {
  LolaBffApiContractsModelsLoanPartyInfo,
  useGetApiV1BorrowersSearchQuery,
} from '@api/output/api';
import { NoContent } from '@components/NoContent';
import styles from './listOfBorrowers.module.scss';

export interface ListOfBorrowersProps {
  className?: string;
  fullHeight?: boolean;
  selectedId?: string;
  onChange: (record: RowRecord) => void;
}

const columns: TableColumnType<LolaBffApiContractsModelsLoanPartyInfo>[] = [
  {
    dataIndex: 'displayName',
    title: 'Borrower name',
    width: '40%',
    render: textRenderer(true),
    sorter:
      createDefaultSorter<LolaBffApiContractsModelsLoanPartyInfo>(
        'displayName'
      ),
  },
  {
    dataIndex: 'taxpayerIdentifierValue',
    title: 'SSN',
    width: '20%',
    render: textRenderer(),
    sorter: createDefaultSorter<LolaBffApiContractsModelsLoanPartyInfo>(
      'taxpayerIdentifierValue'
    ),
  },
  {
    dataIndex: 'lastLoanDate',
    title: 'Last loan date',
    width: '20%',
    render: dateRenderer,
    sorter:
      createDateSorter<LolaBffApiContractsModelsLoanPartyInfo>('lastLoanDate'),
  },
  {
    dataIndex: 'amountOfLoans',
    title: 'Amount of loans',
    width: '20%',
    sorter:
      createDefaultSorter<LolaBffApiContractsModelsLoanPartyInfo>(
        'amountOfLoans'
      ),
  },
];

export const SEARCH_HEADER_HEIGHT = 65;

export const ListOfBorrowers = ({
  className,
  selectedId,
  onChange,
  fullHeight = false,
}: ListOfBorrowersProps) => {
  const { data, isFetching } = useGetApiV1BorrowersSearchQuery(
    {
      type: 'individual',
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { newData, handleSearchChange } =
    useTableDataSearch<LolaBffApiContractsModelsLoanPartyInfo>(
      data || [],
      'displayName'
    );

  const [ref, scroll] = useTableScroll(fullHeight, -SEARCH_HEADER_HEIGHT, {
    x: 600,
    y: 400,
  });

  if (isFetching) {
    return <LinearProgress />;
  }

  if (!data || !data.length) {
    return (
      <NoContent
        variant="grey"
        icon={<TeamIcon />}
        title="No data to show here yet"
        text="Create first borrower before"
      />
    );
  }

  return (
    <div className={classnames(className)} ref={ref}>
      <header className={styles.searchHeader}>
        <DataSearch handleSearchChange={handleSearchChange} />
      </header>
      <DataTable
        rowKey="id"
        highlightRows={selectedId ? [selectedId] : undefined}
        columns={columns}
        dataSource={newData}
        onRowClick={onChange}
        scroll={scroll}
      />
    </div>
  );
};
