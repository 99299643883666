import React from 'react';
import { Button, LinearProgress, Paper } from '@mui/material';
import {
  DataTable,
  DataTableProps,
  RowRecord,
} from '@lola/ui-react-components';
import styles from './listWithAction.module.scss';

interface ListWithActionProps<Row> extends DataTableProps<Row> {
  isLoading: boolean;
  submitHandler: () => void;
  submitLabel: string;
  isSubmitDisabled: boolean;
  isSelectionEnabled: boolean;
  disabledRows?: string[];
}

export const ListWithAction = <Row extends RowRecord>({
  columns,
  dataSource,
  isLoading,
  submitHandler,
  submitLabel,
  isSubmitDisabled,
  onSelectedChange,
  isSelectionEnabled,
  disabledRows,
}: ListWithActionProps<Row>) => {
  return (
    <div>
      <Paper elevation={0} classes={{ root: styles.tableWrapper }}>
        <DataTable
          rowSelection={
            isSelectionEnabled
              ? {
                  type: 'checkbox',
                }
              : undefined
          }
          disabledRows={disabledRows}
          rowKey="id"
          scroll={{
            x: 700,
          }}
          columns={columns}
          dataSource={dataSource}
          showHeader={false}
          onSelectedChange={onSelectedChange}
        />
        {isLoading && <LinearProgress />}
      </Paper>
      <div className={styles.buttons}>
        <Button
          variant="outlined"
          size="large"
          onClick={submitHandler}
          disabled={isSubmitDisabled}
        >
          {submitLabel}
        </Button>
      </div>
    </div>
  );
};
