import React, { useState, useEffect, useMemo } from 'react';
//Libraries
import { LinearProgress, Paper, Skeleton } from '@mui/material';
//Router
import { useNavigate } from 'react-router-dom';
//Redux
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  InboxIcon,
  useFooterConfig,
  RowRecord,
} from '@lola/ui-react-components';
import { selectUserName } from '@store/selectors/auth.selectors';
//API
import {
  LolaBffApiContractsModelsLoanInactiveLoan,
  LolaBffApiContractsModelsLoanLoanActivityStatus,
  usePostApiV1LoansInactiveLoansMutation,
} from '@api/output/api';
//Icons
// types
import { PeriodConfig } from '@components/PeriodSelector';
//Components
import { NoContent } from '@components/NoContent';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { InactiveLoans } from './components/index';
//mocks
import MyLoansInactiveLoansText from './mock/myLoansInactiveLoansText.json';
//styles
import styles from './myLoansInactiveLoans.module.scss';

interface MyLoansInactiveLoansProps {
  searchTerm?: string;
  period: PeriodConfig;
  statusFilter?: string[];
  setStatusFilters: (
    arg: (LolaBffApiContractsModelsLoanLoanActivityStatus | undefined)[]
  ) => void;
}

const MAX_ITEMS = 50;

export const MyLoansInactiveLoans = ({
  searchTerm,
  period,
  statusFilter,
  setStatusFilters,
}: MyLoansInactiveLoansProps): JSX.Element => {
  //Redux
  const userName = useSelector(selectUserName);

  // state
  const [page, setPage] = useState<number>(1);

  // query
  const [queryLoans, { isLoading, data, isError }] =
    usePostApiV1LoansInactiveLoansMutation();

  // navigate
  const navigate = useNavigate();

  // actions
  const handleRowClick = (record: RowRecord) => {
    const row = record as LolaBffApiContractsModelsLoanInactiveLoan;
    row.id && navigate(row.id.toString());
  };

  const footerConfig = useFooterConfig(
    data?.totalInactiveLoans ?? 0,
    MAX_ITEMS,
    'bottomLeft',
    (pageN) => {
      handledSetPage(pageN as number);
    }
  );

  const handledUpdateData = useMemo(
    () =>
      debounce(
        (
          newPage: number,
          searchTerm: MyLoansInactiveLoansProps['searchTerm'],
          statusFilter: MyLoansInactiveLoansProps['statusFilter'],
          period: MyLoansInactiveLoansProps['period']
        ) =>
          queryLoans({
            lolaBffApiContractsModelsLoanRequestInactiveLoanFiltersRequest: {
              inactiveLoanFilters: {
                nameFilter: searchTerm ?? null,
                statusFilter: statusFilter?.join(', ') ?? null,
                beginDateFilter:
                  period.value === 'all' ? undefined : period.start,
                endDateFilter: period.value === 'all' ? undefined : period.end,
              },
              userName: userName,
              page: newPage,
            },
          }),
        500
      ),
    []
  );

  // action States
  const handledSetPage = (newPage: number) => {
    handledUpdateData(newPage, searchTerm, statusFilter, period);
    setPage(newPage);
  };

  useEffect(() => {
    setPage(1);
    handledUpdateData(1, searchTerm, statusFilter, period);
  }, [period, searchTerm, statusFilter]);

  useEffect(() => {
    if (data?.inactiveLoans?.length) {
      const statusFilters = data?.inactiveLoans?.map(({ status }) => status);
      setStatusFilters(statusFilters);
    }
  });

  // render
  if (isError) {
    return <ErrorNoticeFetchRequest />;
  }

  if (isLoading) {
    return (
      <>
        <LinearProgress />
        <section className={styles.inactiveLoansContainer}>
          <Paper elevation={0} classes={{ root: styles.skeletonPaper }}>
            <Skeleton variant="rectangular" className={styles.skeletonHeader} />
            <Skeleton variant="rectangular" className={styles.skeletonFooter} />
          </Paper>
        </section>
      </>
    );
  }

  if (!data?.inactiveLoans?.length) {
    const { title, text } = MyLoansInactiveLoansText.noData;
    return (
      <section className={styles.noContent}>
        <NoContent icon={<InboxIcon />} title={title} text={text} />
      </section>
    );
  }

  return (
    <InactiveLoans
      dataSource={data?.inactiveLoans}
      onRowClick={handleRowClick}
      pagination={{
        ...footerConfig,
        defaultCurrent: page,
      }}
    />
  );
};
