import React, { useCallback, useEffect, useState } from 'react';
import { Button, Drawer, IconButton, LinearProgress } from '@mui/material';
import { CloseIcon } from '@lola/ui-react-components';
import { ContentSectionHeader } from '@components/ContentSection';
import { useProgramQuotes } from '@pages/personalLoans/pages/buildMyLoan/pages/product/components/MyQuotesListDrawer/hooks/useProgramQuotes';
import { QUOTE_TYPE } from '@typings/common';
import { ListOfMyQuotes } from './components/ListOfMyQuotes/ListOfMyQuotes.component';
import styles from './myQuotesListDrawer.module.scss';

export interface MyQuotesListDrawerProps {
  open: boolean;
  onQuoteSelect: (id: string) => void;
  onClose: () => void;
  selectedBorrowerId?: string;
  quoteType: QUOTE_TYPE;
}

export const MyQuotesListDrawer = ({
  open,
  onClose,
  onQuoteSelect,
  quoteType,
}: MyQuotesListDrawerProps) => {
  const [selectedQuoteId, setSelectedQuoteId] = useState<string | undefined>();
  const {
    loadQuotes,
    quotes,
    filters,
    handleSearchChange,
    handleFilterChange,
    appliedFilters,
    isFetching,
    setSelectedQuotesCreators,
  } = useProgramQuotes(quoteType);

  const selectQuoteHandler = useCallback(async () => {
    if (selectedQuoteId) {
      onQuoteSelect?.(selectedQuoteId);
      setSelectedQuoteId(undefined);
    }
  }, [selectedQuoteId, onQuoteSelect, setSelectedQuoteId]);

  const onCloseHandler = useCallback(() => {
    onClose();
    setSelectedQuotesCreators([]);
    setSelectedQuoteId(undefined);
  }, [onClose, setSelectedQuoteId, setSelectedQuotesCreators]);

  useEffect(() => {
    if (open) {
      loadQuotes();
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={onCloseHandler}
      hideBackdrop={true}
      anchor="right"
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <ContentSectionHeader
        title="My quotes"
        actions={
          <IconButton onClick={onCloseHandler} title="Close list of my quotes">
            <CloseIcon />
          </IconButton>
        }
      />
      {isFetching ? (
        <LinearProgress />
      ) : (
        <ListOfMyQuotes
          fullHeight
          className={styles.main}
          onChange={setSelectedQuoteId}
          selectedId={selectedQuoteId}
          quotes={quotes}
          appliedFilters={appliedFilters}
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleSearchChange={handleSearchChange}
        />
      )}
      <footer className={styles.footer}>
        <Button variant="text" size="large" onClick={onCloseHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          disabled={!selectedQuoteId}
          onClick={selectQuoteHandler}
        >
          Select
        </Button>
      </footer>
    </Drawer>
  );
};
