import { emptyApi as api } from '../emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiV1ActivityLog: build.query<
      GetApiV1ActivityLogApiResponse,
      GetApiV1ActivityLogApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/activity-log`,
        params: {
          cursor: queryArg.cursor,
          pageSize: queryArg.pageSize,
          entityType: queryArg.entityType,
          entityId: queryArg.entityId,
        },
      }),
    }),
    getApiV1ApplicationStageByLoanIdBuildMyLoan: build.query<
      GetApiV1ApplicationStageByLoanIdBuildMyLoanApiResponse,
      GetApiV1ApplicationStageByLoanIdBuildMyLoanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/application-stage/${queryArg.loanId}/build-my-loan`,
      }),
    }),
    getApiV1ApprovemyloanGetOverviewProgress: build.query<
      GetApiV1ApprovemyloanGetOverviewProgressApiResponse,
      GetApiV1ApprovemyloanGetOverviewProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/approvemyloan/get-overview-progress`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    getApiV1ApprovemyloanGetReviewStatus: build.query<
      GetApiV1ApprovemyloanGetReviewStatusApiResponse,
      GetApiV1ApprovemyloanGetReviewStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/approvemyloan/get-review-status`,
        params: { dealId: queryArg.dealId },
      }),
    }),
    getApiV1ApprovemyloanGetShowConditions: build.query<
      GetApiV1ApprovemyloanGetShowConditionsApiResponse,
      GetApiV1ApprovemyloanGetShowConditionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/approvemyloan/get-show-conditions`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    getApiV1ApprovemyloanGetActions: build.query<
      GetApiV1ApprovemyloanGetActionsApiResponse,
      GetApiV1ApprovemyloanGetActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/approvemyloan/get-actions`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    getApiV1ApprovemyloanGetFinalUnderwriting: build.query<
      GetApiV1ApprovemyloanGetFinalUnderwritingApiResponse,
      GetApiV1ApprovemyloanGetFinalUnderwritingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/approvemyloan/get-final-underwriting`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    postApiV1ApprovemyloanBeginFinalUnderwriting: build.mutation<
      PostApiV1ApprovemyloanBeginFinalUnderwritingApiResponse,
      PostApiV1ApprovemyloanBeginFinalUnderwritingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/approvemyloan/begin-final-underwriting`,
        method: 'POST',
        params: { dealId: queryArg.dealId },
      }),
    }),
    getApiV1ApprovemyloanIsPricingAndTermsAccepted: build.query<
      GetApiV1ApprovemyloanIsPricingAndTermsAcceptedApiResponse,
      GetApiV1ApprovemyloanIsPricingAndTermsAcceptedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/approvemyloan/is-pricing-and-terms-accepted`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    postApiV1ApprovemyloanAcceptFinalPricingAndTermsSheet: build.mutation<
      PostApiV1ApprovemyloanAcceptFinalPricingAndTermsSheetApiResponse,
      PostApiV1ApprovemyloanAcceptFinalPricingAndTermsSheetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/approvemyloan/accept-final-pricing-and-terms-sheet`,
        method: 'POST',
        params: { loanId: queryArg.loanId },
      }),
    }),
    postApiV1ApprovemyloanGetMyClearToClose: build.mutation<
      PostApiV1ApprovemyloanGetMyClearToCloseApiResponse,
      PostApiV1ApprovemyloanGetMyClearToCloseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/approvemyloan/get-my-clear-to-close`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsApproveMyLoanRequestAttestationRequest,
      }),
    }),
    postApiV1AuthToken: build.mutation<
      PostApiV1AuthTokenApiResponse,
      PostApiV1AuthTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/token`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsAuthAuthUserLoginRequest,
      }),
    }),
    getApiV1AuthLogout: build.query<
      GetApiV1AuthLogoutApiResponse,
      GetApiV1AuthLogoutApiArg
    >({
      query: () => ({ url: `/api/v1/auth/logout` }),
    }),
    postApiV1AuthTokenRefresh: build.mutation<
      PostApiV1AuthTokenRefreshApiResponse,
      PostApiV1AuthTokenRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/token/refresh`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsAuthRefreshTokenRequest,
      }),
    }),
    postApiV1AuthChangePassword: build.mutation<
      PostApiV1AuthChangePasswordApiResponse,
      PostApiV1AuthChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/change-password`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsAuthAuthChangePasswordRequest,
      }),
    }),
    postApiV1AuthResetPasswordSend: build.mutation<
      PostApiV1AuthResetPasswordSendApiResponse,
      PostApiV1AuthResetPasswordSendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/reset-password/send`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsAuthAuthResetPasswordRequest,
      }),
    }),
    postApiV1AuthResetPasswordConfirm: build.mutation<
      PostApiV1AuthResetPasswordConfirmApiResponse,
      PostApiV1AuthResetPasswordConfirmApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/reset-password/confirm`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsAuthAuthResetPasswordConfirmRequest,
      }),
    }),
    getApiV1BorrowersSearch: build.query<
      GetApiV1BorrowersSearchApiResponse,
      GetApiV1BorrowersSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/borrowers/search`,
        params: {
          type: queryArg['type'],
          query: queryArg.query,
          childPartyId: queryArg.childPartyId,
        },
      }),
    }),
    getApiV1BorrowersByPartyId: build.query<
      GetApiV1BorrowersByPartyIdApiResponse,
      GetApiV1BorrowersByPartyIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/borrowers/${queryArg.partyId}` }),
    }),
    getApiV1BorrowersGetByLoanid: build.query<
      GetApiV1BorrowersGetByLoanidApiResponse,
      GetApiV1BorrowersGetByLoanidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/borrowers/get-by-loanid`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    getApiV1ClosemyloanGetOverviewProgress: build.query<
      GetApiV1ClosemyloanGetOverviewProgressApiResponse,
      GetApiV1ClosemyloanGetOverviewProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/closemyloan/get-overview-progress`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    getApiV1ClosemyloanGetScheduling: build.query<
      GetApiV1ClosemyloanGetSchedulingApiResponse,
      GetApiV1ClosemyloanGetSchedulingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/closemyloan/get-scheduling`,
        params: { dealId: queryArg.dealId },
      }),
    }),
    getApiV1ClosemyloanGetInClosing: build.query<
      GetApiV1ClosemyloanGetInClosingApiResponse,
      GetApiV1ClosemyloanGetInClosingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/closemyloan/get-in-closing`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    getApiV1ClosemyloanFundingInformation: build.query<
      GetApiV1ClosemyloanFundingInformationApiResponse,
      GetApiV1ClosemyloanFundingInformationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/closemyloan/funding-information`,
        params: { dealId: queryArg.dealId },
      }),
    }),
    getApiV1ConditionGetConditionDetails: build.query<
      GetApiV1ConditionGetConditionDetailsApiResponse,
      GetApiV1ConditionGetConditionDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/condition/get-condition-details`,
        params: { loanId: queryArg.loanId, conditionId: queryArg.conditionId },
      }),
    }),
    postApiV1ConditionConditionUpload: build.mutation<
      PostApiV1ConditionConditionUploadApiResponse,
      PostApiV1ConditionConditionUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/condition/condition-upload`,
        method: 'POST',
        body: queryArg.body,
        params: {
          conditionId: queryArg.conditionId,
          loanId: queryArg.loanId,
          loanIdentifier: queryArg.loanIdentifier,
          userName: queryArg.userName,
        },
      }),
    }),
    postApiV1ConditionByLoanIdExceptionRequest: build.mutation<
      PostApiV1ConditionByLoanIdExceptionRequestApiResponse,
      PostApiV1ConditionByLoanIdExceptionRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/condition/${queryArg.loanId}/exception-request`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiV1ConstructionInspectionOrder: build.mutation<
      PostApiV1ConstructionInspectionOrderApiResponse,
      PostApiV1ConstructionInspectionOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/construction-inspection/order`,
        method: 'POST',
        params: { DealId: queryArg.dealId, PropertyId: queryArg.propertyId },
      }),
    }),
    getApiV1DashboardActionsForToday: build.query<
      GetApiV1DashboardActionsForTodayApiResponse,
      GetApiV1DashboardActionsForTodayApiArg
    >({
      query: () => ({ url: `/api/v1/dashboard/actions-for-today` }),
    }),
    getApiV1DashboardTodoCount: build.query<
      GetApiV1DashboardTodoCountApiResponse,
      GetApiV1DashboardTodoCountApiArg
    >({
      query: () => ({ url: `/api/v1/dashboard/todo-count` }),
    }),
    getApiV1DashboardStatsKpiWidgets: build.query<
      GetApiV1DashboardStatsKpiWidgetsApiResponse,
      GetApiV1DashboardStatsKpiWidgetsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/dashboard/stats/kpi-widgets`,
        params: {
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          TeamStats: queryArg.teamStats,
        },
      }),
    }),
    getApiV1DashboardStatsAllTimeKpi: build.query<
      GetApiV1DashboardStatsAllTimeKpiApiResponse,
      GetApiV1DashboardStatsAllTimeKpiApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/dashboard/stats/all-time-kpi`,
        params: {
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          TeamStats: queryArg.teamStats,
        },
      }),
    }),
    getApiV1DashboardStatsKeyStatistics: build.query<
      GetApiV1DashboardStatsKeyStatisticsApiResponse,
      GetApiV1DashboardStatsKeyStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/dashboard/stats/key-statistics`,
        params: {
          DateFrom: queryArg.dateFrom,
          DateTo: queryArg.dateTo,
          TeamStats: queryArg.teamStats,
        },
      }),
    }),
    getApiV1DashboardStatsManagerPipeline: build.query<
      GetApiV1DashboardStatsManagerPipelineApiResponse,
      GetApiV1DashboardStatsManagerPipelineApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/dashboard/stats/manager-pipeline`,
        params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo },
      }),
    }),
    getApiV1DashboardStatsKeyManagerPipeline: build.query<
      GetApiV1DashboardStatsKeyManagerPipelineApiResponse,
      GetApiV1DashboardStatsKeyManagerPipelineApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/dashboard/stats/key-manager-pipeline`,
        params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo },
      }),
    }),
    getApiV1DashboardStatsBrokerPipeline: build.query<
      GetApiV1DashboardStatsBrokerPipelineApiResponse,
      GetApiV1DashboardStatsBrokerPipelineApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/dashboard/stats/broker-pipeline`,
        params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo },
      }),
    }),
    getApiV1DocumentLocker: build.query<
      GetApiV1DocumentLockerApiResponse,
      GetApiV1DocumentLockerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/document-locker`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    getApiV1DocumentLockerLoansByLoanId: build.query<
      GetApiV1DocumentLockerLoansByLoanIdApiResponse,
      GetApiV1DocumentLockerLoansByLoanIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/document-locker/loans/${queryArg.loanId}`,
      }),
    }),
    getApiV1DocumentLockerTasksByTaskId: build.query<
      GetApiV1DocumentLockerTasksByTaskIdApiResponse,
      GetApiV1DocumentLockerTasksByTaskIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/document-locker/tasks/${queryArg.taskId}`,
      }),
    }),
    getApiV1DocumentsDownloadUrl: build.query<
      GetApiV1DocumentsDownloadUrlApiResponse,
      GetApiV1DocumentsDownloadUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/documents/download-url`,
        params: { downloadId: queryArg.downloadId },
      }),
    }),
    getApiV1DocumentsDownload: build.query<
      GetApiV1DocumentsDownloadApiResponse,
      GetApiV1DocumentsDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/documents/download`,
        params: {
          downloadToken: queryArg.downloadToken,
          preview: queryArg.preview,
        },
      }),
    }),
    deleteApiV1DocumentsDelete: build.mutation<
      DeleteApiV1DocumentsDeleteApiResponse,
      DeleteApiV1DocumentsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/documents/delete`,
        method: 'DELETE',
        params: { taskId: queryArg.taskId, downloadId: queryArg.downloadId },
      }),
    }),
    postApiV1DocumentsUploadByTaskId: build.mutation<
      PostApiV1DocumentsUploadByTaskIdApiResponse,
      PostApiV1DocumentsUploadByTaskIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/documents/upload/${queryArg.taskId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiV1DocumentsGenerateFilesArchive: build.query<
      GetApiV1DocumentsGenerateFilesArchiveApiResponse,
      GetApiV1DocumentsGenerateFilesArchiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/documents/generate-files-archive`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    postApiV1FilesParsePdf: build.mutation<
      PostApiV1FilesParsePdfApiResponse,
      PostApiV1FilesParsePdfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/files/parse-pdf`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiV1HealthTaskConfiguration: build.query<
      GetApiV1HealthTaskConfigurationApiResponse,
      GetApiV1HealthTaskConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/health/task-configuration`,
        params: { Domain: queryArg.domain },
      }),
    }),
    getApiV1LearningCenter: build.query<
      GetApiV1LearningCenterApiResponse,
      GetApiV1LearningCenterApiArg
    >({
      query: () => ({ url: `/api/v1/learning-center` }),
    }),
    getApiV1LearningCenterDownload: build.query<
      GetApiV1LearningCenterDownloadApiResponse,
      GetApiV1LearningCenterDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/learning-center/download`,
        params: { fileId: queryArg.fileId, type: queryArg['type'] },
      }),
    }),
    getApiV1Licenses: build.query<
      GetApiV1LicensesApiResponse,
      GetApiV1LicensesApiArg
    >({
      query: () => ({ url: `/api/v1/licenses` }),
    }),
    postApiV1Licenses: build.mutation<
      PostApiV1LicensesApiResponse,
      PostApiV1LicensesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/licenses`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsLicensesCreateLicenseRequest,
      }),
    }),
    getApiV1LicensesByLicenseId: build.query<
      GetApiV1LicensesByLicenseIdApiResponse,
      GetApiV1LicensesByLicenseIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/licenses/${queryArg.licenseId}` }),
    }),
    putApiV1LicensesByLicenseId: build.mutation<
      PutApiV1LicensesByLicenseIdApiResponse,
      PutApiV1LicensesByLicenseIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/licenses/${queryArg.licenseId}`,
        method: 'PUT',
        body: queryArg.lolaBffApiContractsModelsLicensesUpdateLicenseRequest,
      }),
    }),
    deleteApiV1LicensesByLicenseId: build.mutation<
      DeleteApiV1LicensesByLicenseIdApiResponse,
      DeleteApiV1LicensesByLicenseIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/licenses/${queryArg.licenseId}`,
        method: 'DELETE',
      }),
    }),
    postApiV1LoansByLoanIdAddProperties: build.mutation<
      PostApiV1LoansByLoanIdAddPropertiesApiResponse,
      PostApiV1LoansByLoanIdAddPropertiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/add-properties`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiV1Loans: build.mutation<
      PostApiV1LoansApiResponse,
      PostApiV1LoansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsRequestsCreateNewLoanRequest,
      }),
    }),
    getApiV1Loans: build.query<GetApiV1LoansApiResponse, GetApiV1LoansApiArg>({
      query: () => ({ url: `/api/v1/loans` }),
    }),
    postApiV1LoansByLoanIdFromPdfApp: build.mutation<
      PostApiV1LoansByLoanIdFromPdfAppApiResponse,
      PostApiV1LoansByLoanIdFromPdfAppApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/from-pdf-app`,
        method: 'POST',
        body: queryArg.body,
        params: { appType: queryArg.appType },
      }),
    }),
    getApiV1LoansByLoanId: build.query<
      GetApiV1LoansByLoanIdApiResponse,
      GetApiV1LoansByLoanIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/loans/${queryArg.loanId}` }),
    }),
    putApiV1LoansByLoanId: build.mutation<
      PutApiV1LoansByLoanIdApiResponse,
      PutApiV1LoansByLoanIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}`,
        method: 'PUT',
        body: queryArg.lolaBffApiContractsModelsLoanRequestUpdateLoanModel,
      }),
    }),
    postApiV1LoansByLoanIdSubmit: build.mutation<
      PostApiV1LoansByLoanIdSubmitApiResponse,
      PostApiV1LoansByLoanIdSubmitApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/submit`,
        method: 'POST',
      }),
    }),
    getApiV1LoansByLoanIdParties: build.query<
      GetApiV1LoansByLoanIdPartiesApiResponse,
      GetApiV1LoansByLoanIdPartiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/parties`,
      }),
    }),
    getApiV1LoansByLoanIdProperties: build.query<
      GetApiV1LoansByLoanIdPropertiesApiResponse,
      GetApiV1LoansByLoanIdPropertiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/properties`,
      }),
    }),
    getApiV1LoansByLoanIdDocumentTasks: build.query<
      GetApiV1LoansByLoanIdDocumentTasksApiResponse,
      GetApiV1LoansByLoanIdDocumentTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/document-tasks`,
      }),
    }),
    postApiV1LoansByLoanIdChangeBroker: build.mutation<
      PostApiV1LoansByLoanIdChangeBrokerApiResponse,
      PostApiV1LoansByLoanIdChangeBrokerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/change-broker`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsLoanChangeBrokerRequest,
      }),
    }),
    postApiV1LoansByLoanIdManageUsers: build.mutation<
      PostApiV1LoansByLoanIdManageUsersApiResponse,
      PostApiV1LoansByLoanIdManageUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/manage-users`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsLoanManageLoanUsersRequest,
      }),
    }),
    postApiV1LoansByLoanIdPartiesAndPartyId: build.mutation<
      PostApiV1LoansByLoanIdPartiesAndPartyIdApiResponse,
      PostApiV1LoansByLoanIdPartiesAndPartyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/parties/${queryArg.partyId}`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsPartyPartyRoleRequest,
      }),
    }),
    deleteApiV1LoansByLoanIdPartiesAndPartyId: build.mutation<
      DeleteApiV1LoansByLoanIdPartiesAndPartyIdApiResponse,
      DeleteApiV1LoansByLoanIdPartiesAndPartyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/parties/${queryArg.partyId}`,
        method: 'DELETE',
      }),
    }),
    postApiV1LoansByLoanIdCreateGuarantor: build.mutation<
      PostApiV1LoansByLoanIdCreateGuarantorApiResponse,
      PostApiV1LoansByLoanIdCreateGuarantorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/create-guarantor`,
        method: 'POST',
        body: queryArg.body,
        params: { isPrimaryGuarantor: queryArg.isPrimaryGuarantor },
      }),
    }),
    postApiV1LoansByLoanIdUploadBorrowerAuthorization: build.mutation<
      PostApiV1LoansByLoanIdUploadBorrowerAuthorizationApiResponse,
      PostApiV1LoansByLoanIdUploadBorrowerAuthorizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/upload-borrower-authorization`,
        method: 'POST',
        body: queryArg.body,
        params: { partyId: queryArg.partyId },
      }),
    }),
    postApiV1LoansByLoanIdUploadCreditReport: build.mutation<
      PostApiV1LoansByLoanIdUploadCreditReportApiResponse,
      PostApiV1LoansByLoanIdUploadCreditReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/upload-credit-report`,
        method: 'POST',
        body: queryArg.body,
        params: { partyId: queryArg.partyId },
      }),
    }),
    postApiV1LoansByLoanIdUploadPortfolioRequest: build.mutation<
      PostApiV1LoansByLoanIdUploadPortfolioRequestApiResponse,
      PostApiV1LoansByLoanIdUploadPortfolioRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/upload-portfolio-request`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiV1LoansByLoanIdPayments: build.query<
      GetApiV1LoansByLoanIdPaymentsApiResponse,
      GetApiV1LoansByLoanIdPaymentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/payments`,
      }),
    }),
    postApiV1LoansCreateFromQuote: build.mutation<
      PostApiV1LoansCreateFromQuoteApiResponse,
      PostApiV1LoansCreateFromQuoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/create-from-quote`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsRequestsCreateLoanFromQuoteRequest,
      }),
    }),
    postApiV1LoansByLoanIdGenerateTasks: build.mutation<
      PostApiV1LoansByLoanIdGenerateTasksApiResponse,
      PostApiV1LoansByLoanIdGenerateTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/generate-tasks`,
        method: 'POST',
      }),
    }),
    getApiV1LoansExport: build.query<
      GetApiV1LoansExportApiResponse,
      GetApiV1LoansExportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/export`,
        params: { searchQuery: queryArg.searchQuery },
      }),
    }),
    getApiV1LoansActiveLoans: build.query<
      GetApiV1LoansActiveLoansApiResponse,
      GetApiV1LoansActiveLoansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/active-loans`,
        params: { userName: queryArg.userName },
      }),
    }),
    postApiV1LoansInactiveLoans: build.mutation<
      PostApiV1LoansInactiveLoansApiResponse,
      PostApiV1LoansInactiveLoansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/inactive-loans`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsLoanRequestInactiveLoanFiltersRequest,
      }),
    }),
    getApiV1LoansControlPanel: build.query<
      GetApiV1LoansControlPanelApiResponse,
      GetApiV1LoansControlPanelApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/control-panel`,
        params: { loanId: queryArg.loanId },
      }),
    }),
    postApiV1LoansAssignProcessor: build.mutation<
      PostApiV1LoansAssignProcessorApiResponse,
      PostApiV1LoansAssignProcessorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/assign-processor`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsLoanAssignLoansToProcessorRequest,
      }),
    }),
    putApiV1LoansPartyRelations: build.mutation<
      PutApiV1LoansPartyRelationsApiResponse,
      PutApiV1LoansPartyRelationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/party-relations`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    postApiV1LoansArchiveLoan: build.mutation<
      PostApiV1LoansArchiveLoanApiResponse,
      PostApiV1LoansArchiveLoanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/archive-loan`,
        method: 'POST',
        params: { dealId: queryArg.dealId },
      }),
    }),
    getApiV1LoansDisabled: build.query<
      GetApiV1LoansDisabledApiResponse,
      GetApiV1LoansDisabledApiArg
    >({
      query: () => ({ url: `/api/v1/loans/disabled` }),
    }),
    postApiV1LoansByLoanIdSendChangeRequest: build.mutation<
      PostApiV1LoansByLoanIdSendChangeRequestApiResponse,
      PostApiV1LoansByLoanIdSendChangeRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loans/${queryArg.loanId}/send-change-request`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsLoanRequestLoanChangeRequest,
      }),
    }),
    getApiV1LoanTodos: build.query<
      GetApiV1LoanTodosApiResponse,
      GetApiV1LoanTodosApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/loan-todos`,
        params: {
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
          includeTeam: queryArg.includeTeam,
        },
      }),
    }),
    getApiV1LolaTaskStatus: build.query<
      GetApiV1LolaTaskStatusApiResponse,
      GetApiV1LolaTaskStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/lola-task-status`,
        params: {
          entity1Type: queryArg.entity1Type,
          entity1Id: queryArg.entity1Id,
        },
      }),
    }),
    getApiV1MeridianCra: build.query<
      GetApiV1MeridianCraApiResponse,
      GetApiV1MeridianCraApiArg
    >({
      query: () => ({ url: `/api/v1/meridian/cra` }),
    }),
    postApiV1MeridianReissueCreditReport: build.mutation<
      PostApiV1MeridianReissueCreditReportApiResponse,
      PostApiV1MeridianReissueCreditReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/meridian/reissue-credit-report`,
        method: 'POST',
        body: queryArg.lolaBffMeridianApiClientModelsRequestReissueCreditRequest,
      }),
    }),
    putApiV1PartiesByPartyIdIndividual: build.mutation<
      PutApiV1PartiesByPartyIdIndividualApiResponse,
      PutApiV1PartiesByPartyIdIndividualApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/parties/${queryArg.partyId}/individual`,
        method: 'PUT',
        body: queryArg.lolaBffApiContractsModelsPartyUpdateIndividualPartyRequest,
        params: { sourceLoanId: queryArg.sourceLoanId },
      }),
    }),
    putApiV1PartiesByPartyIdEntity: build.mutation<
      PutApiV1PartiesByPartyIdEntityApiResponse,
      PutApiV1PartiesByPartyIdEntityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/parties/${queryArg.partyId}/entity`,
        method: 'PUT',
        body: queryArg.lolaBffApiContractsModelsPartyUpdateEntityPartyRequest,
        params: { sourceLoanId: queryArg.sourceLoanId },
      }),
    }),
    putApiV1PartiesPartyRelations: build.mutation<
      PutApiV1PartiesPartyRelationsApiResponse,
      PutApiV1PartiesPartyRelationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/parties/party-relations`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiV1PartiesByPartyId: build.mutation<
      DeleteApiV1PartiesByPartyIdApiResponse,
      DeleteApiV1PartiesByPartyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/parties/${queryArg.partyId}`,
        method: 'DELETE',
      }),
    }),
    postApiV1PaymentsStripeCreatePaymentIntent: build.mutation<
      PostApiV1PaymentsStripeCreatePaymentIntentApiResponse,
      PostApiV1PaymentsStripeCreatePaymentIntentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/payments/stripe/create-payment-intent`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsPaymentCreatePaymentIntentRequest,
      }),
    }),
    postApiV1PaymentsStripeCreatePaymentLink: build.mutation<
      PostApiV1PaymentsStripeCreatePaymentLinkApiResponse,
      PostApiV1PaymentsStripeCreatePaymentLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/payments/stripe/create-payment-link`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsPaymentCreatePaymentLinkRequest,
      }),
    }),
    postApiV1PaymentsStripeSendPaymentLinks: build.mutation<
      PostApiV1PaymentsStripeSendPaymentLinksApiResponse,
      PostApiV1PaymentsStripeSendPaymentLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/payments/stripe/send-payment-links`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsPaymentCreatePaymentLinkRequest,
      }),
    }),
    getApiV1PricingEngineQuotes: build.query<
      GetApiV1PricingEngineQuotesApiResponse,
      GetApiV1PricingEngineQuotesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quotes`,
        params: {
          portalUserIds: queryArg.portalUserIds,
          includeSubmitted: queryArg.includeSubmitted,
        },
      }),
    }),
    getApiV1PricingEngineQuoteByQuoteIdRental: build.query<
      GetApiV1PricingEngineQuoteByQuoteIdRentalApiResponse,
      GetApiV1PricingEngineQuoteByQuoteIdRentalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/rental`,
      }),
    }),
    putApiV1PricingEngineQuoteByQuoteIdRental: build.mutation<
      PutApiV1PricingEngineQuoteByQuoteIdRentalApiResponse,
      PutApiV1PricingEngineQuoteByQuoteIdRentalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/rental`,
        method: 'PUT',
        body: queryArg.lolaBffApiContractsModelsPricingEngineRequestUpdateRentalQuoteRequest,
      }),
    }),
    getApiV1PricingEngineQuoteByQuoteIdStf: build.query<
      GetApiV1PricingEngineQuoteByQuoteIdStfApiResponse,
      GetApiV1PricingEngineQuoteByQuoteIdStfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/stf`,
      }),
    }),
    putApiV1PricingEngineQuoteByQuoteIdStf: build.mutation<
      PutApiV1PricingEngineQuoteByQuoteIdStfApiResponse,
      PutApiV1PricingEngineQuoteByQuoteIdStfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/stf`,
        method: 'PUT',
        body: queryArg.lolaBffApiContractsModelsPricingEngineRequestUpdateShortTermFinanceQuoteRequest,
      }),
    }),
    postApiV1PricingEngineQuoteStfCalculations: build.mutation<
      PostApiV1PricingEngineQuoteStfCalculationsApiResponse,
      PostApiV1PricingEngineQuoteStfCalculationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/stf/calculations`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest,
      }),
    }),
    postApiV1PricingEngineQuoteRentalCalculations: build.mutation<
      PostApiV1PricingEngineQuoteRentalCalculationsApiResponse,
      PostApiV1PricingEngineQuoteRentalCalculationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/rental/calculations`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest,
      }),
    }),
    postApiV1PricingEngineQuoteRental: build.mutation<
      PostApiV1PricingEngineQuoteRentalApiResponse,
      PostApiV1PricingEngineQuoteRentalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/rental`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsPricingEngineRequestSaveRentalQuoteRequest,
      }),
    }),
    getApiV1PricingEngineQuoteByQuoteIdRentalPdf: build.query<
      GetApiV1PricingEngineQuoteByQuoteIdRentalPdfApiResponse,
      GetApiV1PricingEngineQuoteByQuoteIdRentalPdfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/rental/pdf`,
      }),
    }),
    postApiV1PricingEngineQuoteStf: build.mutation<
      PostApiV1PricingEngineQuoteStfApiResponse,
      PostApiV1PricingEngineQuoteStfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/stf`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsPricingEngineRequestSaveShortTermFinanceQuoteRequest,
      }),
    }),
    getApiV1PricingEngineQuoteByQuoteIdStfPdf: build.query<
      GetApiV1PricingEngineQuoteByQuoteIdStfPdfApiResponse,
      GetApiV1PricingEngineQuoteByQuoteIdStfPdfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/stf/pdf`,
      }),
    }),
    deleteApiV1PricingEngineQuoteByQuoteId: build.mutation<
      DeleteApiV1PricingEngineQuoteByQuoteIdApiResponse,
      DeleteApiV1PricingEngineQuoteByQuoteIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}`,
        method: 'DELETE',
      }),
    }),
    postApiV1PricingEngineQuoteByQuoteIdStfPdfSend: build.mutation<
      PostApiV1PricingEngineQuoteByQuoteIdStfPdfSendApiResponse,
      PostApiV1PricingEngineQuoteByQuoteIdStfPdfSendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/stf/pdf/send`,
        method: 'POST',
        params: { isFinal: queryArg.isFinal },
      }),
    }),
    postApiV1PricingEngineQuoteByQuoteIdRentalPdfSend: build.mutation<
      PostApiV1PricingEngineQuoteByQuoteIdRentalPdfSendApiResponse,
      PostApiV1PricingEngineQuoteByQuoteIdRentalPdfSendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/rental/pdf/send`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsPricingEngineRequestSendQuoteRequest,
        params: { isFinal: queryArg.isFinal },
      }),
    }),
    postApiV1PricingEngineQuoteByQuoteIdCopyQuote: build.mutation<
      PostApiV1PricingEngineQuoteByQuoteIdCopyQuoteApiResponse,
      PostApiV1PricingEngineQuoteByQuoteIdCopyQuoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/copy-quote`,
        method: 'POST',
      }),
    }),
    putApiV1PricingEngineQuoteByQuoteIdChangeStatus: build.mutation<
      PutApiV1PricingEngineQuoteByQuoteIdChangeStatusApiResponse,
      PutApiV1PricingEngineQuoteByQuoteIdChangeStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/pricing-engine/quote/${queryArg.quoteId}/change-status`,
        method: 'PUT',
      }),
    }),
    putApiV1PricingEngineUsersOpxUsername: build.mutation<
      PutApiV1PricingEngineUsersOpxUsernameApiResponse,
      PutApiV1PricingEngineUsersOpxUsernameApiArg
    >({
      query: () => ({
        url: `/api/v1/pricing-engine/users/opx/username`,
        method: 'PUT',
      }),
    }),
    putApiV1PricingEngineUsersOpxUsernameCalculations: build.mutation<
      PutApiV1PricingEngineUsersOpxUsernameCalculationsApiResponse,
      PutApiV1PricingEngineUsersOpxUsernameCalculationsApiArg
    >({
      query: () => ({
        url: `/api/v1/pricing-engine/users/opx/username/calculations`,
        method: 'PUT',
      }),
    }),
    postApiV1Properties: build.mutation<
      PostApiV1PropertiesApiResponse,
      PostApiV1PropertiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/properties`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiV1PropertiesByPropertyId: build.mutation<
      PutApiV1PropertiesByPropertyIdApiResponse,
      PutApiV1PropertiesByPropertyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/properties/${queryArg.propertyId}`,
        method: 'PUT',
        body: queryArg.lolaBffApiContractsModelsPropertyUpdatePropertyRequest,
        params: { loanId: queryArg.loanId },
      }),
    }),
    deleteApiV1PropertiesByPropertyId: build.mutation<
      DeleteApiV1PropertiesByPropertyIdApiResponse,
      DeleteApiV1PropertiesByPropertyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/properties/${queryArg.propertyId}`,
        method: 'DELETE',
      }),
    }),
    putApiV1PropertiesByPropertyIdUpdateFromJobject: build.mutation<
      PutApiV1PropertiesByPropertyIdUpdateFromJobjectApiResponse,
      PutApiV1PropertiesByPropertyIdUpdateFromJobjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/properties/${queryArg.propertyId}/update-from-jobject`,
        method: 'PUT',
        body: queryArg.body,
        params: { sourceLoanId: queryArg.sourceLoanId },
      }),
    }),
    getApiV1Roles: build.query<GetApiV1RolesApiResponse, GetApiV1RolesApiArg>({
      query: () => ({ url: `/api/v1/roles` }),
    }),
    getApiV1SettingsProgramsAndProducts: build.query<
      GetApiV1SettingsProgramsAndProductsApiResponse,
      GetApiV1SettingsProgramsAndProductsApiArg
    >({
      query: () => ({ url: `/api/v1/settings/programs-and-products` }),
    }),
    getApiV1SettingsTaskConfiguration: build.query<
      GetApiV1SettingsTaskConfigurationApiResponse,
      GetApiV1SettingsTaskConfigurationApiArg
    >({
      query: () => ({ url: `/api/v1/settings/task-configuration` }),
    }),
    postApiV1SigningSendCreditAuthorization: build.mutation<
      PostApiV1SigningSendCreditAuthorizationApiResponse,
      PostApiV1SigningSendCreditAuthorizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/signing/send-credit-authorization`,
        method: 'POST',
        params: { DealId: queryArg.dealId, PartyIds: queryArg.partyIds },
      }),
    }),
    getApiV1Teams: build.query<GetApiV1TeamsApiResponse, GetApiV1TeamsApiArg>({
      query: () => ({ url: `/api/v1/teams` }),
    }),
    postApiV1Teams: build.mutation<
      PostApiV1TeamsApiResponse,
      PostApiV1TeamsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/teams`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsRequestsManageTeamRequest,
      }),
    }),
    getApiV1TeamsNewTeamId: build.query<
      GetApiV1TeamsNewTeamIdApiResponse,
      GetApiV1TeamsNewTeamIdApiArg
    >({
      query: () => ({ url: `/api/v1/teams/new-team-id` }),
    }),
    getApiV1DocumentTemplates: build.query<
      GetApiV1DocumentTemplatesApiResponse,
      GetApiV1DocumentTemplatesApiArg
    >({
      query: () => ({ url: `/api/v1/document-templates` }),
    }),
    getApiV1DocumentTemplatesGetDownloadUrl: build.query<
      GetApiV1DocumentTemplatesGetDownloadUrlApiResponse,
      GetApiV1DocumentTemplatesGetDownloadUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/document-templates/get-download-url`,
        params: { templateId: queryArg.templateId },
      }),
    }),
    getApiV1TenantConfig: build.query<
      GetApiV1TenantConfigApiResponse,
      GetApiV1TenantConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tenant/config`,
        params: { domain: queryArg.domain },
      }),
    }),
    getApiV1TenantByTenantIdPublicAndImage: build.query<
      GetApiV1TenantByTenantIdPublicAndImageApiResponse,
      GetApiV1TenantByTenantIdPublicAndImageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tenant/${queryArg.tenantId}/public/${queryArg.image}`,
      }),
    }),
    getApiV1UsersMe: build.query<
      GetApiV1UsersMeApiResponse,
      GetApiV1UsersMeApiArg
    >({
      query: () => ({ url: `/api/v1/users/me` }),
    }),
    postApiV1Users: build.mutation<
      PostApiV1UsersApiResponse,
      PostApiV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: 'POST',
        body: queryArg.lolaBffApiContractsModelsUsersCreateAuth0UserRequest,
      }),
    }),
    getApiV1Users: build.query<GetApiV1UsersApiResponse, GetApiV1UsersApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        params: { role: queryArg.role },
      }),
    }),
    putApiV1Users: build.mutation<
      PutApiV1UsersApiResponse,
      PutApiV1UsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: 'PUT',
        body: queryArg.lolaBffApiContractsModelsUsersUpdateAuth0UserRequest,
      }),
    }),
    getApiV1UsersAll: build.query<
      GetApiV1UsersAllApiResponse,
      GetApiV1UsersAllApiArg
    >({
      query: () => ({ url: `/api/v1/users/all` }),
    }),
    getApiV1UsersByUserId: build.query<
      GetApiV1UsersByUserIdApiResponse,
      GetApiV1UsersByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/users/${queryArg.userId}` }),
    }),
    getApiV1UsersUsernameByUserName: build.query<
      GetApiV1UsersUsernameByUserNameApiResponse,
      GetApiV1UsersUsernameByUserNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/username/${queryArg.userName}`,
      }),
    }),
    postApiV1UsersMePhoto: build.mutation<
      PostApiV1UsersMePhotoApiResponse,
      PostApiV1UsersMePhotoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/me/photo`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteApiV1UsersMePhoto: build.mutation<
      DeleteApiV1UsersMePhotoApiResponse,
      DeleteApiV1UsersMePhotoApiArg
    >({
      query: () => ({ url: `/api/v1/users/me/photo`, method: 'DELETE' }),
    }),
    getApiV1UsersByUserIdPhoto: build.query<
      GetApiV1UsersByUserIdPhotoApiResponse,
      GetApiV1UsersByUserIdPhotoApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/users/${queryArg.userId}/photo` }),
    }),
    postApiV1UsersByUserIdReSendInvitation: build.mutation<
      PostApiV1UsersByUserIdReSendInvitationApiResponse,
      PostApiV1UsersByUserIdReSendInvitationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.userId}/re-send-invitation`,
        method: 'POST',
      }),
    }),
    postApiV1WebhooksDocusign: build.mutation<
      PostApiV1WebhooksDocusignApiResponse,
      PostApiV1WebhooksDocusignApiArg
    >({
      query: () => ({ url: `/api/v1/webhooks/docusign`, method: 'POST' }),
    }),
    postApiV1WebhooksStripe: build.mutation<
      PostApiV1WebhooksStripeApiResponse,
      PostApiV1WebhooksStripeApiArg
    >({
      query: () => ({ url: `/api/v1/webhooks/stripe`, method: 'POST' }),
    }),
    postApiV1WebhooksPruvan: build.mutation<
      PostApiV1WebhooksPruvanApiResponse,
      PostApiV1WebhooksPruvanApiArg
    >({
      query: () => ({ url: `/api/v1/webhooks/pruvan`, method: 'POST' }),
    }),
    postApiV1WebhooksElphi: build.mutation<
      PostApiV1WebhooksElphiApiResponse,
      PostApiV1WebhooksElphiApiArg
    >({
      query: () => ({ url: `/api/v1/webhooks/elphi`, method: 'POST' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetApiV1ActivityLogApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsActivitiesActivityLogContainer;
export type GetApiV1ActivityLogApiArg = {
  /** A unique base64 string used for pagination. */
  cursor?: string;
  /** The number of records to retrieve. Maximum is 100. */
  pageSize?: number;
  /** Type of the entity (loan, team, user). Optional. */
  entityType?: string;
  /** The ID of the entity. Optional. */
  entityId?: string;
};
export type GetApiV1ApplicationStageByLoanIdBuildMyLoanApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsApplicationStepBuildMyLoanCompletionState;
export type GetApiV1ApplicationStageByLoanIdBuildMyLoanApiArg = {
  /** Loan Id */
  loanId: string;
};
export type GetApiV1ApprovemyloanGetOverviewProgressApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsStepperOverviewProgressResponse[];
export type GetApiV1ApprovemyloanGetOverviewProgressApiArg = {
  loanId: string;
};
export type GetApiV1ApprovemyloanGetReviewStatusApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsApproveMyLoanResponseLoanReviewResponse;
export type GetApiV1ApprovemyloanGetReviewStatusApiArg = {
  dealId: string;
};
export type GetApiV1ApprovemyloanGetShowConditionsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsApproveMyLoanResponseShowConditionsResponse;
export type GetApiV1ApprovemyloanGetShowConditionsApiArg = {
  loanId: string;
};
export type GetApiV1ApprovemyloanGetActionsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsApproveMyLoanResponseActionResponse[];
export type GetApiV1ApprovemyloanGetActionsApiArg = {
  loanId: string;
};
export type GetApiV1ApprovemyloanGetFinalUnderwritingApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsApproveMyLoanResponseLoanReviewResponse;
export type GetApiV1ApprovemyloanGetFinalUnderwritingApiArg = {
  loanId: string;
};
export type PostApiV1ApprovemyloanBeginFinalUnderwritingApiResponse = unknown;
export type PostApiV1ApprovemyloanBeginFinalUnderwritingApiArg = {
  dealId: string;
};
export type GetApiV1ApprovemyloanIsPricingAndTermsAcceptedApiResponse = unknown;
export type GetApiV1ApprovemyloanIsPricingAndTermsAcceptedApiArg = {
  loanId: string;
};
export type PostApiV1ApprovemyloanAcceptFinalPricingAndTermsSheetApiResponse =
  unknown;
export type PostApiV1ApprovemyloanAcceptFinalPricingAndTermsSheetApiArg = {
  loanId: string;
};
export type PostApiV1ApprovemyloanGetMyClearToCloseApiResponse = unknown;
export type PostApiV1ApprovemyloanGetMyClearToCloseApiArg = {
  lolaBffApiContractsModelsApproveMyLoanRequestAttestationRequest: LolaBffApiContractsModelsApproveMyLoanRequestAttestationRequest;
};
export type PostApiV1AuthTokenApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsAuthBffTokenResponse;
export type PostApiV1AuthTokenApiArg = {
  lolaBffApiContractsModelsAuthAuthUserLoginRequest: LolaBffApiContractsModelsAuthAuthUserLoginRequest;
};
export type GetApiV1AuthLogoutApiResponse = unknown;
export type GetApiV1AuthLogoutApiArg = void;
export type PostApiV1AuthTokenRefreshApiResponse = unknown;
export type PostApiV1AuthTokenRefreshApiArg = {
  lolaBffApiContractsModelsAuthRefreshTokenRequest: LolaBffApiContractsModelsAuthRefreshTokenRequest;
};
export type PostApiV1AuthChangePasswordApiResponse = unknown;
export type PostApiV1AuthChangePasswordApiArg = {
  lolaBffApiContractsModelsAuthAuthChangePasswordRequest: LolaBffApiContractsModelsAuthAuthChangePasswordRequest;
};
export type PostApiV1AuthResetPasswordSendApiResponse = unknown;
export type PostApiV1AuthResetPasswordSendApiArg = {
  lolaBffApiContractsModelsAuthAuthResetPasswordRequest: LolaBffApiContractsModelsAuthAuthResetPasswordRequest;
};
export type PostApiV1AuthResetPasswordConfirmApiResponse = unknown;
export type PostApiV1AuthResetPasswordConfirmApiArg = {
  lolaBffApiContractsModelsAuthAuthResetPasswordConfirmRequest: LolaBffApiContractsModelsAuthAuthResetPasswordConfirmRequest;
};
export type GetApiV1BorrowersSearchApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanBorrowerSearchEntity[];
export type GetApiV1BorrowersSearchApiArg = {
  /** Party type (individual|entity) */
  type?: string;
  /** search parameter */
  query?: string;
  /** String array of party Id */
  childPartyId?: string[];
};
export type GetApiV1BorrowersByPartyIdApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanPartyInfo;
export type GetApiV1BorrowersByPartyIdApiArg = {
  /** Party Id */
  partyId: string;
};
export type GetApiV1BorrowersGetByLoanidApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanGeneralBorrower[];
export type GetApiV1BorrowersGetByLoanidApiArg = {
  /** loanId identifier */
  loanId?: string;
};
export type GetApiV1ClosemyloanGetOverviewProgressApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsStepperOverviewProgressResponse[];
export type GetApiV1ClosemyloanGetOverviewProgressApiArg = {
  loanId: string;
};
export type GetApiV1ClosemyloanGetSchedulingApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsCloseMyLoanGetSchedulingResponse;
export type GetApiV1ClosemyloanGetSchedulingApiArg = {
  dealId: string;
};
export type GetApiV1ClosemyloanGetInClosingApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsCloseMyLoanGetInClosingResponse;
export type GetApiV1ClosemyloanGetInClosingApiArg = {
  loanId: string;
};
export type GetApiV1ClosemyloanFundingInformationApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanFundingInformation;
export type GetApiV1ClosemyloanFundingInformationApiArg = {
  dealId?: string;
};
export type GetApiV1ConditionGetConditionDetailsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsConditionResponseConditionDetailsResponse;
export type GetApiV1ConditionGetConditionDetailsApiArg = {
  loanId: string;
  conditionId: string;
};
export type PostApiV1ConditionConditionUploadApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsConditionResponseConditionUpload;
export type PostApiV1ConditionConditionUploadApiArg = {
  conditionId: string;
  loanId: string;
  loanIdentifier: string;
  userName?: string;
  body: {
    files: Blob[];
  };
};
export type PostApiV1ConditionByLoanIdExceptionRequestApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsConditionResponseExceptionRequestResponse;
export type PostApiV1ConditionByLoanIdExceptionRequestApiArg = {
  loanId: string;
  body: {
    FirstName?: string;
    LastName?: string;
    LoanName?: string;
    EmailFrom?: string;
    EmailTo?: string;
    Title?: string;
    LoanId?: string;
    LoanIdentifier?: string;
    ConditionId: string;
    Description?: string;
    UserName?: string;
    UserPortalId?: string;
    Files?: Blob[];
  };
};
export type PostApiV1ConstructionInspectionOrderApiResponse = unknown;
export type PostApiV1ConstructionInspectionOrderApiArg = {
  dealId: string;
  propertyId: string;
};
export type GetApiV1DashboardActionsForTodayApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDashboardActionsLoanActionDto[];
export type GetApiV1DashboardActionsForTodayApiArg = void;
export type GetApiV1DashboardTodoCountApiResponse =
  /** status 200 Success */ number;
export type GetApiV1DashboardTodoCountApiArg = void;
export type GetApiV1DashboardStatsKpiWidgetsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDashboardKpiKpiData;
export type GetApiV1DashboardStatsKpiWidgetsApiArg = {
  dateFrom: string;
  dateTo: string;
  teamStats?: boolean;
};
export type GetApiV1DashboardStatsAllTimeKpiApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDashboardAllTimeKpiAllTimeKpiData;
export type GetApiV1DashboardStatsAllTimeKpiApiArg = {
  dateFrom: string;
  dateTo: string;
  teamStats?: boolean;
};
export type GetApiV1DashboardStatsKeyStatisticsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDashboardKeyStatisticsKeyStatisticsData;
export type GetApiV1DashboardStatsKeyStatisticsApiArg = {
  dateFrom: string;
  dateTo: string;
  teamStats?: boolean;
};
export type GetApiV1DashboardStatsManagerPipelineApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatisticData;
export type GetApiV1DashboardStatsManagerPipelineApiArg = {
  dateFrom: string;
  dateTo: string;
};
export type GetApiV1DashboardStatsKeyManagerPipelineApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDashboardLoansStatisticsLoansKeyBrokerManagerStatisticData;
export type GetApiV1DashboardStatsKeyManagerPipelineApiArg = {
  dateFrom: string;
  dateTo: string;
};
export type GetApiV1DashboardStatsBrokerPipelineApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDashboardLoansStatisticsBrokerLoanStatisticData;
export type GetApiV1DashboardStatsBrokerPipelineApiArg = {
  /** from date */
  dateFrom: string;
  /** to date */
  dateTo: string;
};
export type GetApiV1DocumentLockerApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDocumentLockerDocumentInfo[];
export type GetApiV1DocumentLockerApiArg = {
  loanId: string;
};
export type GetApiV1DocumentLockerLoansByLoanIdApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDocumentLockerDocumentInfo[];
export type GetApiV1DocumentLockerLoansByLoanIdApiArg = {
  loanId: string;
};
export type GetApiV1DocumentLockerTasksByTaskIdApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsDocumentLockerDocumentInfo[];
export type GetApiV1DocumentLockerTasksByTaskIdApiArg = {
  taskId: string;
};
export type GetApiV1DocumentsDownloadUrlApiResponse =
  /** status 200 Success */ Blob;
export type GetApiV1DocumentsDownloadUrlApiArg = {
  /** Document download Id */
  downloadId: string;
};
export type GetApiV1DocumentsDownloadApiResponse =
  /** status 200 Success */ Blob;
export type GetApiV1DocumentsDownloadApiArg = {
  /** Download token */
  downloadToken: string;
  preview?: boolean;
};
export type DeleteApiV1DocumentsDeleteApiResponse = unknown;
export type DeleteApiV1DocumentsDeleteApiArg = {
  taskId: string;
  downloadId: string;
};
export type PostApiV1DocumentsUploadByTaskIdApiResponse = unknown;
export type PostApiV1DocumentsUploadByTaskIdApiArg = {
  taskId: string;
  body: {
    file?: Blob;
  };
};
export type GetApiV1DocumentsGenerateFilesArchiveApiResponse = unknown;
export type GetApiV1DocumentsGenerateFilesArchiveApiArg = {
  loanId: string;
};
export type PostApiV1FilesParsePdfApiResponse = /** status 200 Success */ {
  [key: string]: LolaBffApiContractsModelsPdfPdfFieldInfo[];
};
export type PostApiV1FilesParsePdfApiArg = {
  body: {
    file?: Blob;
  };
};
export type GetApiV1HealthTaskConfigurationApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsHealthHealthCheckResponse601LolaBffApiContractsModelsHealthBaseHealthResponse20LolaBffApiContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1HealthTaskConfigurationApiArg = {
  domain: string;
};
export type GetApiV1LearningCenterApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLearningCenterLearningData;
export type GetApiV1LearningCenterApiArg = void;
export type GetApiV1LearningCenterDownloadApiResponse = unknown;
export type GetApiV1LearningCenterDownloadApiArg = {
  /** The ID of the file */
  fileId?: string;
  /** type (shared|tenant) */
  type?: string;
};
export type GetApiV1LicensesApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLicensesLicense[];
export type GetApiV1LicensesApiArg = void;
export type PostApiV1LicensesApiResponse =
  /** status 201 Created */ LolaBffApiContractsModelsLicensesLicense;
export type PostApiV1LicensesApiArg = {
  lolaBffApiContractsModelsLicensesCreateLicenseRequest: LolaBffApiContractsModelsLicensesCreateLicenseRequest;
};
export type GetApiV1LicensesByLicenseIdApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLicensesLicense;
export type GetApiV1LicensesByLicenseIdApiArg = {
  licenseId: string;
};
export type PutApiV1LicensesByLicenseIdApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLicensesLicense;
export type PutApiV1LicensesByLicenseIdApiArg = {
  licenseId: string;
  lolaBffApiContractsModelsLicensesUpdateLicenseRequest: LolaBffApiContractsModelsLicensesUpdateLicenseRequest;
};
export type DeleteApiV1LicensesByLicenseIdApiResponse =
  /** status 204 No Content */ undefined;
export type DeleteApiV1LicensesByLicenseIdApiArg = {
  licenseId: string;
};
export type PostApiV1LoansByLoanIdAddPropertiesApiResponse =
  /** status 200 Success */ LolaBffCoreDomainModelLoanResponseAddPropertiesResponse;
export type PostApiV1LoansByLoanIdAddPropertiesApiArg = {
  loanId: string;
  body: any;
};
export type PostApiV1LoansApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanLoanModel;
export type PostApiV1LoansApiArg = {
  lolaBffApiContractsModelsRequestsCreateNewLoanRequest: LolaBffApiContractsModelsRequestsCreateNewLoanRequest;
};
export type GetApiV1LoansApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanLoanView[];
export type GetApiV1LoansApiArg = void;
export type PostApiV1LoansByLoanIdFromPdfAppApiResponse =
  /** status 200 Success */ any;
export type PostApiV1LoansByLoanIdFromPdfAppApiArg = {
  loanId: string;
  appType: LolaBffApiContractsModelsLoanFileModelsPdfApplicationType;
  body: {
    pdfFile?: Blob;
  };
};
export type GetApiV1LoansByLoanIdApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanLoanModel;
export type GetApiV1LoansByLoanIdApiArg = {
  loanId: string;
};
export type PutApiV1LoansByLoanIdApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsIdResponse;
export type PutApiV1LoansByLoanIdApiArg = {
  loanId: string;
  lolaBffApiContractsModelsLoanRequestUpdateLoanModel: LolaBffApiContractsModelsLoanRequestUpdateLoanModel;
};
export type PostApiV1LoansByLoanIdSubmitApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsIdResponse;
export type PostApiV1LoansByLoanIdSubmitApiArg = {
  loanId: string;
};
export type GetApiV1LoansByLoanIdPartiesApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanPartyInfo[];
export type GetApiV1LoansByLoanIdPartiesApiArg = {
  /** Loan Id */
  loanId: string;
};
export type GetApiV1LoansByLoanIdPropertiesApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanLoanProperty[];
export type GetApiV1LoansByLoanIdPropertiesApiArg = {
  /** Loan Id */
  loanId: string;
};
export type GetApiV1LoansByLoanIdDocumentTasksApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanDocumentTaskSection[];
export type GetApiV1LoansByLoanIdDocumentTasksApiArg = {
  loanId: string;
};
export type PostApiV1LoansByLoanIdChangeBrokerApiResponse =
  /** status 200 Success */ any;
export type PostApiV1LoansByLoanIdChangeBrokerApiArg = {
  loanId: string;
  lolaBffApiContractsModelsLoanChangeBrokerRequest: LolaBffApiContractsModelsLoanChangeBrokerRequest;
};
export type PostApiV1LoansByLoanIdManageUsersApiResponse =
  /** status 200 Success */ any;
export type PostApiV1LoansByLoanIdManageUsersApiArg = {
  loanId: string;
  lolaBffApiContractsModelsLoanManageLoanUsersRequest: LolaBffApiContractsModelsLoanManageLoanUsersRequest;
};
export type PostApiV1LoansByLoanIdPartiesAndPartyIdApiResponse =
  /** status 200 Success */ string;
export type PostApiV1LoansByLoanIdPartiesAndPartyIdApiArg = {
  /** Deal Id */
  loanId: string;
  /** Party Id */
  partyId: string;
  /** Attached party role */
  lolaBffApiContractsModelsPartyPartyRoleRequest: LolaBffApiContractsModelsPartyPartyRoleRequest;
};
export type DeleteApiV1LoansByLoanIdPartiesAndPartyIdApiResponse =
  /** status 200 Success */ string;
export type DeleteApiV1LoansByLoanIdPartiesAndPartyIdApiArg = {
  loanId: string;
  partyId: string;
};
export type PostApiV1LoansByLoanIdCreateGuarantorApiResponse =
  /** status 200 Success */ LolaBffCoreDomainModelLoanResponseAddGuarantorResponse;
export type PostApiV1LoansByLoanIdCreateGuarantorApiArg = {
  loanId: string;
  isPrimaryGuarantor?: boolean;
  body: any;
};
export type PostApiV1LoansByLoanIdUploadBorrowerAuthorizationApiResponse =
  unknown;
export type PostApiV1LoansByLoanIdUploadBorrowerAuthorizationApiArg = {
  loanId: string;
  partyId: string;
  body: {
    file?: Blob;
  };
};
export type PostApiV1LoansByLoanIdUploadCreditReportApiResponse = unknown;
export type PostApiV1LoansByLoanIdUploadCreditReportApiArg = {
  loanId: string;
  partyId: string;
  body: {
    file?: Blob;
  };
};
export type PostApiV1LoansByLoanIdUploadPortfolioRequestApiResponse = unknown;
export type PostApiV1LoansByLoanIdUploadPortfolioRequestApiArg = {
  loanId: string;
  body: {
    file?: Blob;
  };
};
export type GetApiV1LoansByLoanIdPaymentsApiResponse = unknown;
export type GetApiV1LoansByLoanIdPaymentsApiArg = {
  /** Loan id */
  loanId: string;
};
export type PostApiV1LoansCreateFromQuoteApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanLoanModel;
export type PostApiV1LoansCreateFromQuoteApiArg = {
  lolaBffApiContractsModelsRequestsCreateLoanFromQuoteRequest: LolaBffApiContractsModelsRequestsCreateLoanFromQuoteRequest;
};
export type PostApiV1LoansByLoanIdGenerateTasksApiResponse = unknown;
export type PostApiV1LoansByLoanIdGenerateTasksApiArg = {
  loanId: string;
};
export type GetApiV1LoansExportApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanLoan[];
export type GetApiV1LoansExportApiArg = {
  searchQuery: string;
};
export type GetApiV1LoansActiveLoansApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanResponseActiveLoansResponse;
export type GetApiV1LoansActiveLoansApiArg = {
  userName?: string;
};
export type PostApiV1LoansInactiveLoansApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanResponseInactiveLoansResponse;
export type PostApiV1LoansInactiveLoansApiArg = {
  lolaBffApiContractsModelsLoanRequestInactiveLoanFiltersRequest: LolaBffApiContractsModelsLoanRequestInactiveLoanFiltersRequest;
};
export type GetApiV1LoansControlPanelApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanResponseLoanControlPanelResponse[];
export type GetApiV1LoansControlPanelApiArg = {
  loanId?: string;
};
export type PostApiV1LoansAssignProcessorApiResponse = unknown;
export type PostApiV1LoansAssignProcessorApiArg = {
  lolaBffApiContractsModelsLoanAssignLoansToProcessorRequest: LolaBffApiContractsModelsLoanAssignLoansToProcessorRequest;
};
export type PutApiV1LoansPartyRelationsApiResponse = unknown;
export type PutApiV1LoansPartyRelationsApiArg = {
  body: any;
};
export type PostApiV1LoansArchiveLoanApiResponse =
  /** status 200 Success */ MicrosoftAspNetCoreMvcIActionResult;
export type PostApiV1LoansArchiveLoanApiArg = {
  dealId: string;
};
export type GetApiV1LoansDisabledApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanResponseInactiveLoanResponse[];
export type GetApiV1LoansDisabledApiArg = void;
export type PostApiV1LoansByLoanIdSendChangeRequestApiResponse = unknown;
export type PostApiV1LoansByLoanIdSendChangeRequestApiArg = {
  /** Loan Id */
  loanId: string;
  /** Loan Change Request */
  lolaBffApiContractsModelsLoanRequestLoanChangeRequest: LolaBffApiContractsModelsLoanRequestLoanChangeRequest;
};
export type GetApiV1LoanTodosApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanLoanTodo[];
export type GetApiV1LoanTodosApiArg = {
  startDate: string;
  endDate: string;
  includeTeam?: string;
};
export type GetApiV1LolaTaskStatusApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto[];
export type GetApiV1LolaTaskStatusApiArg = {
  entity1Type: string;
  entity1Id: string;
};
export type GetApiV1MeridianCraApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsMeridianResponseCraResponse[];
export type GetApiV1MeridianCraApiArg = void;
export type PostApiV1MeridianReissueCreditReportApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsMeridianResponseMeridianReissueCreditResponse;
export type PostApiV1MeridianReissueCreditReportApiArg = {
  lolaBffMeridianApiClientModelsRequestReissueCreditRequest: LolaBffMeridianApiClientModelsRequestReissueCreditRequest;
};
export type PutApiV1PartiesByPartyIdIndividualApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsIdResponse;
export type PutApiV1PartiesByPartyIdIndividualApiArg = {
  partyId: string;
  sourceLoanId?: string;
  lolaBffApiContractsModelsPartyUpdateIndividualPartyRequest: LolaBffApiContractsModelsPartyUpdateIndividualPartyRequest;
};
export type PutApiV1PartiesByPartyIdEntityApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsIdResponse;
export type PutApiV1PartiesByPartyIdEntityApiArg = {
  partyId: string;
  sourceLoanId?: string;
  lolaBffApiContractsModelsPartyUpdateEntityPartyRequest: LolaBffApiContractsModelsPartyUpdateEntityPartyRequest;
};
export type PutApiV1PartiesPartyRelationsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsIdResponse;
export type PutApiV1PartiesPartyRelationsApiArg = {
  body: any;
};
export type DeleteApiV1PartiesByPartyIdApiResponse = unknown;
export type DeleteApiV1PartiesByPartyIdApiArg = {
  partyId: string;
};
export type PostApiV1PaymentsStripeCreatePaymentIntentApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsPaymentPaymentIntentResponse;
export type PostApiV1PaymentsStripeCreatePaymentIntentApiArg = {
  lolaBffApiContractsModelsPaymentCreatePaymentIntentRequest: LolaBffApiContractsModelsPaymentCreatePaymentIntentRequest;
};
export type PostApiV1PaymentsStripeCreatePaymentLinkApiResponse =
  /** status 200 Success */ string;
export type PostApiV1PaymentsStripeCreatePaymentLinkApiArg = {
  lolaBffApiContractsModelsPaymentCreatePaymentLinkRequest: LolaBffApiContractsModelsPaymentCreatePaymentLinkRequest;
};
export type PostApiV1PaymentsStripeSendPaymentLinksApiResponse = unknown;
export type PostApiV1PaymentsStripeSendPaymentLinksApiArg = {
  lolaBffApiContractsModelsPaymentCreatePaymentLinkRequest: LolaBffApiContractsModelsPaymentCreatePaymentLinkRequest;
};
export type GetApiV1PricingEngineQuotesApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse[];
export type GetApiV1PricingEngineQuotesApiArg = {
  /** List of user IDs for which quotes should be returned (if empty when will be returned quotes for current user) */
  portalUserIds?: string[];
  /** Boolean flag that includes in the response already submitted quotes & them loan milestones */
  includeSubmitted?: boolean;
};
export type GetApiV1PricingEngineQuoteByQuoteIdRentalApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse;
export type GetApiV1PricingEngineQuoteByQuoteIdRentalApiArg = {
  quoteId: string;
};
export type PutApiV1PricingEngineQuoteByQuoteIdRentalApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsPricingEngineResponseCalculateAndUpdateRentalQuoteResponse;
export type PutApiV1PricingEngineQuoteByQuoteIdRentalApiArg = {
  quoteId: string;
  lolaBffApiContractsModelsPricingEngineRequestUpdateRentalQuoteRequest: LolaBffApiContractsModelsPricingEngineRequestUpdateRentalQuoteRequest;
};
export type GetApiV1PricingEngineQuoteByQuoteIdStfApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteByIdResponse;
export type GetApiV1PricingEngineQuoteByQuoteIdStfApiArg = {
  quoteId: string;
};
export type PutApiV1PricingEngineQuoteByQuoteIdStfApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsPricingEngineResponseCalculateAndUpdateShortTermFinanceQuoteResponse;
export type PutApiV1PricingEngineQuoteByQuoteIdStfApiArg = {
  quoteId: string;
  lolaBffApiContractsModelsPricingEngineRequestUpdateShortTermFinanceQuoteRequest: LolaBffApiContractsModelsPricingEngineRequestUpdateShortTermFinanceQuoteRequest;
};
export type PostApiV1PricingEngineQuoteStfCalculationsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsPricingEngineResponseCalculateShortTermFinanceQuoteResponse;
export type PostApiV1PricingEngineQuoteStfCalculationsApiArg = {
  lolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest: LolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest;
};
export type PostApiV1PricingEngineQuoteRentalCalculationsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsPricingEngineResponseCalculateRentalQuoteResponse;
export type PostApiV1PricingEngineQuoteRentalCalculationsApiArg = {
  lolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest: LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest;
};
export type PostApiV1PricingEngineQuoteRentalApiResponse =
  /** status 200 Success */ string;
export type PostApiV1PricingEngineQuoteRentalApiArg = {
  lolaBffApiContractsModelsPricingEngineRequestSaveRentalQuoteRequest: LolaBffApiContractsModelsPricingEngineRequestSaveRentalQuoteRequest;
};
export type GetApiV1PricingEngineQuoteByQuoteIdRentalPdfApiResponse =
  /** status 200 Success */ Blob;
export type GetApiV1PricingEngineQuoteByQuoteIdRentalPdfApiArg = {
  quoteId: string;
};
export type PostApiV1PricingEngineQuoteStfApiResponse =
  /** status 200 Success */ string;
export type PostApiV1PricingEngineQuoteStfApiArg = {
  lolaBffApiContractsModelsPricingEngineRequestSaveShortTermFinanceQuoteRequest: LolaBffApiContractsModelsPricingEngineRequestSaveShortTermFinanceQuoteRequest;
};
export type GetApiV1PricingEngineQuoteByQuoteIdStfPdfApiResponse =
  /** status 200 Success */ Blob;
export type GetApiV1PricingEngineQuoteByQuoteIdStfPdfApiArg = {
  quoteId: string;
};
export type DeleteApiV1PricingEngineQuoteByQuoteIdApiResponse = unknown;
export type DeleteApiV1PricingEngineQuoteByQuoteIdApiArg = {
  /** quoteId */
  quoteId: string;
};
export type PostApiV1PricingEngineQuoteByQuoteIdStfPdfSendApiResponse = unknown;
export type PostApiV1PricingEngineQuoteByQuoteIdStfPdfSendApiArg = {
  /** rental quote Id */
  quoteId: string;
  /** is final quote, otherwise initial */
  isFinal?: boolean;
};
export type PostApiV1PricingEngineQuoteByQuoteIdRentalPdfSendApiResponse =
  unknown;
export type PostApiV1PricingEngineQuoteByQuoteIdRentalPdfSendApiArg = {
  /** rental quote Id */
  quoteId: string;
  /** is final quote, otherwise initial */
  isFinal?: boolean;
  /** send quote request */
  lolaBffApiContractsModelsPricingEngineRequestSendQuoteRequest: LolaBffApiContractsModelsPricingEngineRequestSendQuoteRequest;
};
export type PostApiV1PricingEngineQuoteByQuoteIdCopyQuoteApiResponse =
  /** status 200 Success */ string;
export type PostApiV1PricingEngineQuoteByQuoteIdCopyQuoteApiArg = {
  quoteId: string;
};
export type PutApiV1PricingEngineQuoteByQuoteIdChangeStatusApiResponse =
  unknown;
export type PutApiV1PricingEngineQuoteByQuoteIdChangeStatusApiArg = {
  /** quote Id */
  quoteId: string;
};
export type PutApiV1PricingEngineUsersOpxUsernameApiResponse = unknown;
export type PutApiV1PricingEngineUsersOpxUsernameApiArg = void;
export type PutApiV1PricingEngineUsersOpxUsernameCalculationsApiResponse =
  unknown;
export type PutApiV1PricingEngineUsersOpxUsernameCalculationsApiArg = void;
export type PostApiV1PropertiesApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsIdResponse;
export type PostApiV1PropertiesApiArg = {
  body: any;
};
export type PutApiV1PropertiesByPropertyIdApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsIdResponse;
export type PutApiV1PropertiesByPropertyIdApiArg = {
  propertyId: string;
  loanId: string;
  lolaBffApiContractsModelsPropertyUpdatePropertyRequest: LolaBffApiContractsModelsPropertyUpdatePropertyRequest;
};
export type DeleteApiV1PropertiesByPropertyIdApiResponse = unknown;
export type DeleteApiV1PropertiesByPropertyIdApiArg = {
  /** property id */
  propertyId: string;
};
export type PutApiV1PropertiesByPropertyIdUpdateFromJobjectApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsIdResponse;
export type PutApiV1PropertiesByPropertyIdUpdateFromJobjectApiArg = {
  propertyId: string;
  sourceLoanId?: string;
  body: any;
};
export type GetApiV1RolesApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsRoleRole[];
export type GetApiV1RolesApiArg = void;
export type GetApiV1SettingsProgramsAndProductsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsSettingsLoanProgramsResponse;
export type GetApiV1SettingsProgramsAndProductsApiArg = void;
export type GetApiV1SettingsTaskConfigurationApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsSettingsTaskConfiguration;
export type GetApiV1SettingsTaskConfigurationApiArg = void;
export type PostApiV1SigningSendCreditAuthorizationApiResponse =
  /** status 200 Success */ any;
export type PostApiV1SigningSendCreditAuthorizationApiArg = {
  dealId: string;
  partyIds: string[];
};
export type GetApiV1TeamsApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsTeam[];
export type GetApiV1TeamsApiArg = void;
export type PostApiV1TeamsApiResponse = unknown;
export type PostApiV1TeamsApiArg = {
  lolaBffApiContractsModelsRequestsManageTeamRequest: LolaBffApiContractsModelsRequestsManageTeamRequest;
};
export type GetApiV1TeamsNewTeamIdApiResponse =
  /** status 200 Success */ number;
export type GetApiV1TeamsNewTeamIdApiArg = void;
export type GetApiV1DocumentTemplatesApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsTaskDocumentTemplateTaskDocumentTemplatesConfiguration;
export type GetApiV1DocumentTemplatesApiArg = void;
export type GetApiV1DocumentTemplatesGetDownloadUrlApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsLoanTemplateTemplateFileInfo;
export type GetApiV1DocumentTemplatesGetDownloadUrlApiArg = {
  /** Selected templateId */
  templateId: string;
};
export type GetApiV1TenantConfigApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsTenantTenant;
export type GetApiV1TenantConfigApiArg = {
  domain?: string;
};
export type GetApiV1TenantByTenantIdPublicAndImageApiResponse = unknown;
export type GetApiV1TenantByTenantIdPublicAndImageApiArg = {
  tenantId: string;
  image: string;
};
export type GetApiV1UsersMeApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsUser;
export type GetApiV1UsersMeApiArg = void;
export type PostApiV1UsersApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsUser;
export type PostApiV1UsersApiArg = {
  lolaBffApiContractsModelsUsersCreateAuth0UserRequest: LolaBffApiContractsModelsUsersCreateAuth0UserRequest;
};
export type GetApiV1UsersApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsUser[];
export type GetApiV1UsersApiArg = {
  /** User role, if role is null returns all users */
  role?: LolaBffApiContractsModelsUserRole;
};
export type PutApiV1UsersApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsUser;
export type PutApiV1UsersApiArg = {
  lolaBffApiContractsModelsUsersUpdateAuth0UserRequest: LolaBffApiContractsModelsUsersUpdateAuth0UserRequest;
};
export type GetApiV1UsersAllApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsUser[];
export type GetApiV1UsersAllApiArg = void;
export type GetApiV1UsersByUserIdApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsUser;
export type GetApiV1UsersByUserIdApiArg = {
  /** Auth0 UserId */
  userId: string;
};
export type GetApiV1UsersUsernameByUserNameApiResponse =
  /** status 200 Success */ LolaBffApiContractsModelsUser;
export type GetApiV1UsersUsernameByUserNameApiArg = {
  /** User name */
  userName: string;
};
export type PostApiV1UsersMePhotoApiResponse = unknown;
export type PostApiV1UsersMePhotoApiArg = {
  body: {
    file: Blob;
  };
};
export type DeleteApiV1UsersMePhotoApiResponse = unknown;
export type DeleteApiV1UsersMePhotoApiArg = void;
export type GetApiV1UsersByUserIdPhotoApiResponse = unknown;
export type GetApiV1UsersByUserIdPhotoApiArg = {
  userId: string;
};
export type PostApiV1UsersByUserIdReSendInvitationApiResponse = unknown;
export type PostApiV1UsersByUserIdReSendInvitationApiArg = {
  userId: string;
};
export type PostApiV1WebhooksDocusignApiResponse = unknown;
export type PostApiV1WebhooksDocusignApiArg = void;
export type PostApiV1WebhooksStripeApiResponse = unknown;
export type PostApiV1WebhooksStripeApiArg = void;
export type PostApiV1WebhooksPruvanApiResponse = unknown;
export type PostApiV1WebhooksPruvanApiArg = void;
export type PostApiV1WebhooksElphiApiResponse = unknown;
export type PostApiV1WebhooksElphiApiArg = void;
export type LolaBffApiContractsModelsActivitiesActivityUserInfo = {
  userId?: string | null;
  email?: string | null;
  name?: string | null;
  role?: string | null;
};
export type LolaBffApiContractsModelsActivitiesActivityLogData = {
  activityId?: string;
  eventName?: string | null;
  eventData?: any | null;
  activity?: string | null;
  isSuccessful?: boolean;
  errorMessage?: string | null;
  resources?: any | null;
  type?: string | null;
  ipAddress?: string | null;
  requestUri?: string | null;
  userInfo?: LolaBffApiContractsModelsActivitiesActivityUserInfo;
  sessionId?: string | null;
  traceId?: string | null;
  activityDate?: string;
};
export type LolaBffApiContractsModelsActivitiesActivityMetaInfo = {
  hasMore?: boolean;
  cursor?: string | null;
};
export type LolaBffApiContractsModelsActivitiesActivityLogContainer = {
  data?: LolaBffApiContractsModelsActivitiesActivityLogData[] | null;
  meta?: LolaBffApiContractsModelsActivitiesActivityMetaInfo;
};
export type LolaBffApiContractsModelsApplicationStepStepItem = {
  id?: string | null;
  title?: string | null;
  state?: string | null;
  percentageCompleted?: number | null;
  isVisible?: boolean;
  items?: LolaBffApiContractsModelsApplicationStepStepItem[] | null;
};
export type LolaBffApiContractsModelsApplicationStepBuildMyLoanCompletionState =
  {
    steps?: LolaBffApiContractsModelsApplicationStepStepItem[] | null;
    totalPercentageCompleted?: number;
  };
export type LolaBffApiContractsModelsApproveMyLoanStatusStepperStepsStatus =
  | 'none'
  | 'inProgress'
  | 'done'
  | 'locked'
  | 'alert';
export type LolaBffApiContractsModelsStepperOverviewProgressResponse = {
  id?: string | null;
  to?: string | null;
  title?: string | null;
  status?: LolaBffApiContractsModelsApproveMyLoanStatusStepperStepsStatus;
  hasButton?: boolean;
  percentComplete?: number;
  items?: LolaBffApiContractsModelsStepperOverviewProgressResponse[] | null;
};
export type LolaBffApiContractsModelsApproveMyLoanStatusApproveMyLoanStepsStatuses =
  'Unsubmitted' | 'InProgress' | 'Done' | 'Suspended' | 'Warning' | 'Error';
export type LolaBffApiContractsModelsApproveMyLoanStatusLoanReviewStepperDescription =

    | 'In review'
    | 'Conditionally approved'
    | 'Loan denied'
    | 'Loan approved'
    | 'Loan suspended'
    | 'In Progress'
    | 'Delivered'
    | 'Denied';
export type LolaBffApiContractsModelsApproveMyLoanStatusMessagingAreaStatuses =
  | 'success'
  | 'error'
  | 'info'
  | 'warning';
export type LolaBffApiContractsModelsMessagingArea = {
  title?: string | null;
  message?: string | null;
  messageStatus?: LolaBffApiContractsModelsApproveMyLoanStatusMessagingAreaStatuses;
};
export type LolaBffApiContractsModelsApproveMyLoanResponseDetailSteps = {
  step?: string | null;
  lastChange?: string | null;
  lastChangeLabel?: string | null;
  currentState?: string | null;
};
export type LolaBffApiContractsModelsApproveMyLoanResponseCompletedTasks = {
  tasksCompletedPercentage?: string | null;
  tasksCompletedDescription?: string | null;
};
export type LolaBffApiContractsModelsApproveMyLoanResponseLoanReviewResponse = {
  loanStatus?: LolaBffApiContractsModelsApproveMyLoanStatusApproveMyLoanStepsStatuses;
  loanDescription?: LolaBffApiContractsModelsApproveMyLoanStatusLoanReviewStepperDescription;
  messagingArea?: LolaBffApiContractsModelsMessagingArea;
  progress?: LolaBffApiContractsModelsApproveMyLoanResponseDetailSteps[] | null;
  completedTasks?: LolaBffApiContractsModelsApproveMyLoanResponseCompletedTasks;
};
export type LolaBffApiContractsModelsConditionStatusConditionStatusResponse =
  | 'NotSet'
  | 'New condition'
  | 'Approved'
  | 'Uploaded'
  | 'In Review'
  | 'Submitted'
  | 'Denied';
export type LolaBffApiContractsModelsApproveMyLoanResponseCondition = {
  conditionId?: string | null;
  name?: string | null;
  status?: LolaBffApiContractsModelsConditionStatusConditionStatusResponse;
  technicalRequirements?: string | null;
  notifications?: string[] | null;
  isKeyClosingTask?: boolean;
};
export type LolaBffApiContractsModelsApproveMyLoanResponseShowCondition = {
  maindependency?: string | null;
  mainDependencyId?: string | null;
  conditions?: LolaBffApiContractsModelsApproveMyLoanResponseCondition[] | null;
};
export type LolaBffApiContractsModelsApproveMyLoanResponseShowConditionsResponse =
  {
    loanArchived?: boolean;
    enableSubmitFinalUnderwriting?: boolean;
    loanName?: string | null;
    loanType?: string | null;
    loanIdentifier?: string | null;
    showConditions?:
      | LolaBffApiContractsModelsApproveMyLoanResponseShowCondition[]
      | null;
  };
export type LolaBffApiContractsModelsLoanDocumentTaskFileInfo = {
  fileName?: string | null;
  folderId?: string | null;
  fileId?: string | null;
  size?: string | null;
  downloadId?: string | null;
  status?: string | null;
  createdAt?: string;
  modifiedAt?: string | null;
};
export type LolaBffApiContractsModelsApproveMyLoanResponseActionResponse = {
  lender?: string | null;
  comments?: string | null;
  lastChange?: string | null;
  currentState?: LolaBffApiContractsModelsConditionStatusConditionStatusResponse;
  taskId?: string | null;
  taskName?: string | null;
  taskStatus?: string | null;
  documents?: LolaBffApiContractsModelsLoanDocumentTaskFileInfo[] | null;
  createdAt?: string;
  modifiedAt?: string | null;
};
export type LolaBffApiContractsModelsApproveMyLoanRequestAttestationRequest = {
  dealId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  date?: string;
  consent?: boolean;
};
export type LolaBffApiContractsModelsAuthBffTokenResponse = {
  accessToken?: string | null;
  refreshToken?: string | null;
  expiresIn?: number;
  tokenType?: string | null;
};
export type LolaBffApiContractsModelsAuthAuthUserLoginRequest = {
  username: string;
  password: string;
  rememberMe?: boolean;
  domain: string;
};
export type LolaBffApiContractsModelsAuthRefreshTokenRequest = {
  refreshToken: string;
};
export type LolaBffApiContractsModelsAuthAuthChangePasswordRequest = {
  oldPassword: string;
  newPassword: string;
};
export type LolaBffApiContractsModelsAuthAuthResetPasswordRequest = {
  username: string;
  domain: string;
};
export type LolaBffApiContractsModelsAuthAuthResetPasswordConfirmRequest = {
  userId: string;
  token: string;
  newPassword: string;
  domain: string;
};
export type LolaBffApiContractsModelsLoanBorrowerSearchEntity = {
  id?: string | null;
  type?: string | null;
  displayName?: string | null;
  taxpayerIdentifierValue?: string | null;
  lastLoanDate?: string | null;
  amountOfLoans?: string | null;
  hasChildParty?: boolean | null;
};
export type LolaBffApiContractsModelsLoanAddress = {
  addressLineText?: string | null;
  stateName?: string | null;
  stateCode?: string | null;
  postalCode?: string | null;
  addressUnitIdentifier?: string | null;
  cityName?: string | null;
  countyName?: string | null;
  propertyBlockIdentifier?: string | null;
  propertyLotIdentifier?: string | null;
  propertyIdentifier?: string | null;
  legalDescription?: string | null;
  fullAddressName?: string | null;
  propertySubdivisionIdentifier?: string | null;
};
export type LolaBffApiContractsModelsPartyPartyMilestone =
  | ''
  | 'Pre-Submission Borrower'
  | 'New Borrower'
  | 'Initial Borrower Review'
  | 'Initial Borrower Review - Additional Info Needed'
  | 'Borrower Approved'
  | 'Borrower Denied'
  | 'Resubmitted'
  | 'Prohibited'
  | 'Expired'
  | 'Suspended';
export type LolaBffApiContractsModelsLoanPartyInfo = {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  fullName?: string | null;
  displayName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  contactPointEmailValue?: string | null;
  contactPointTelephoneValue?: string | null;
  address?: LolaBffApiContractsModelsLoanAddress;
  streetAddress?: string | null;
  cityName?: string | null;
  stateName?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  taxpayerIdentifierValue?: string | null;
  birthDate?: string | null;
  citizenshipCountry?: string | null;
  businessRegistrationStates?: string[] | null;
  realEstateOperationType?: string[] | null;
  type?: string | null;
  entityType?: string | null;
  relationRoleType?: string[] | null;
  partyRelationRoles?: string[] | null;
  ownershipPercentage?: string | null;
  liquidAssetTotalAmount?: string | null;
  transUnionScore?: string | null;
  experianScore?: string | null;
  creditReportDate?: string | null;
  equifaxScore?: string | null;
  lastLoanDate?: string | null;
  amountOfLoans?: string | null;
  createdAt?: string;
  modifiedAt?: string | null;
  crmId?: string | null;
  businessIncorporationStateName?: string | null;
  businessIncorporationStateCode?: string | null;
  fixAndFlipBridgePlusTier?: string | null;
  newConstructionEligibilityIndicator?: string | null;
  remainingLineOfCreditAmount?: string | null;
  notes?: string | null;
  partyMilestone?: LolaBffApiContractsModelsPartyPartyMilestone;
  totalOutstandingLongTermLoanCount?: string | null;
  totalOutstandingLongTermPrincipalAmount?: string | null;
  estimatedCreditScore?: string | null;
  convictionIndicator?: string | null;
  outstandingLawsuitsIndicator?: string | null;
  outstandingJudgmentsIndicator?: string | null;
  bankruptcyIndicator?: string | null;
  foreclosureIndicator?: string | null;
  mortgageDelinquentIndicator?: string | null;
  lola?: any | null;
};
export type LolaBffApiContractsModelsLoanAuthorizationStatus =
  | 'Not sent'
  | 'Link sent'
  | 'Approved'
  | 'Signed'
  | 'Rejected'
  | 'Done'
  | 'Unknown'
  | 'Generated'
  | 'Sent'
  | 'Uploaded';
export type LolaBffApiContractsModelsConditionStatusBorrowerStatus =
  | 'Lead borrower'
  | 'Primary guarantor';
export type LolaBffApiContractsModelsLoanGeneralBorrower = {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  itemActionStatus?: LolaBffApiContractsModelsLoanAuthorizationStatus;
  status?: LolaBffApiContractsModelsConditionStatusBorrowerStatus;
  fundedTotalAmount?: number;
};
export type LolaBffApiContractsModelsCloseMyLoanScheduleInformation = {
  date?: string | null;
  organizationName?: string | null;
  address?: string | null;
  phone?: string | null;
};
export type LolaBffApiContractsModelsCloseMyLoanGetSchedulingResponse = {
  responseTitle?: string | null;
  messageTitle?: string | null;
  messageDescription?: string | null;
  scheduleInformation?: LolaBffApiContractsModelsCloseMyLoanScheduleInformation;
};
export type LolaBffApiContractsModelsCloseMyLoanLoanTerm = {
  title?: string | null;
  label?: string | null;
};
export type LolaBffApiContractsModelsCloseMyLoanGetInClosingResponse = {
  terms?: LolaBffApiContractsModelsCloseMyLoanLoanTerm[] | null;
  messagingArea?: LolaBffApiContractsModelsMessagingArea;
};
export type LolaBffApiContractsModelsTasksTaskFileInfo = {
  fileName?: string | null;
  folderId?: string | null;
  fileId?: string | null;
};
export type LolaBffApiContractsModelsLoanButtonDownload = {
  titleButton?: string | null;
  fileToDownload?: LolaBffApiContractsModelsTasksTaskFileInfo;
  fileDownloadId?: string | null;
};
export type LolaBffApiContractsModelsLoanStagesStageStatus =
  | 'NotSet'
  | 'Open'
  | 'InProgress'
  | 'Done'
  | 'Locked';
export type LolaBffApiContractsModelsLoanHistoric = {
  titleHistoric?: string | null;
  label?: string | null;
  time?: string | null;
  currentStatus?: LolaBffApiContractsModelsLoanStagesStageStatus;
};
export type LolaBffApiContractsModelsLoanFundingInformation = {
  description?: string | null;
  alert?: LolaBffApiContractsModelsMessagingArea;
  buttonError?: string | null;
  buttonDownload?: LolaBffApiContractsModelsLoanButtonDownload;
  historic?: LolaBffApiContractsModelsLoanHistoric[] | null;
};
export type LolaBffApiContractsModelsConditionResponseUnderwriterComment = {
  underwriterName?: string | null;
  comment?: string | null;
  timeStamp?: string | null;
};
export type LolaBffApiContractsModelsConditionResponseBroker = {
  name?: string | null;
  timeStamp?: string | null;
};
export type LolaBffApiContractsModelsConditionResponseFile = {
  fileId?: string | null;
  folderId?: string | null;
  name?: string | null;
  conditionName?: string | null;
  timeStamp?: string | null;
  status?: LolaBffApiContractsModelsConditionStatusConditionStatusResponse;
};
export type LolaBffApiContractsModelsConditionResponseConditionHistory = {
  header?: LolaBffApiContractsModelsConditionResponseUnderwriterComment;
  broker?: LolaBffApiContractsModelsConditionResponseBroker;
  file?: LolaBffApiContractsModelsConditionResponseFile;
};
export type LolaBffApiContractsModelsConditionAcceptedFileExtensions =
  | 'docx'
  | 'pdf'
  | 'jpg'
  | 'png';
export type LolaBffApiContractsModelsConditionResponseConditionUploadFile = {
  maxFileWeight?: string | null;
  acceptedFilesType?:
    | LolaBffApiContractsModelsConditionAcceptedFileExtensions[]
    | null;
};
export type LolaBffApiContractsModelsConditionResponseConditionDetailsResponse =
  {
    conditionName?: string | null;
    conditionDescription?: string | null;
    conditionStatus?: LolaBffApiContractsModelsConditionStatusConditionStatusResponse;
    enableExceptionRequest?: boolean;
    enableUploadFiles?: boolean;
    history?:
      | LolaBffApiContractsModelsConditionResponseConditionHistory[]
      | null;
    upload?: LolaBffApiContractsModelsConditionResponseConditionUploadFile;
  };
export type LolaBffApiContractsModelsConditionResponseUploadFile = {
  uploadedFile?: boolean;
  conditionStatus?: string | null;
  fileName?: string | null;
  lastUpdateFile?: string | null;
};
export type LolaBffApiContractsModelsConditionResponseConditionUpload = {
  broker?: LolaBffApiContractsModelsConditionResponseBroker;
  UploadFiles?: LolaBffApiContractsModelsConditionResponseUploadFile[] | null;
};
export type LolaBffApiContractsModelsConditionResponseExceptionRequestResponse =
  {
    message?: string | null;
  };
export type LolaBffApiContractsModelsDashboardActionsLoanActionDto = {
  loanId?: string | null;
  addressLine?: string | null;
  borrowerName?: string | null;
  loanType?: string | null;
  loanNumber?: string | null;
  step?: string | null;
  createdAt?: string;
  displayedTaskName?: string | null;
  displayedTaskId?: string | null;
  folderId?: string | null;
  moreTasksCount?: number;
  hasMultipleDocuments?: boolean;
  brokerName?: string | null;
  brokerTeam?: string | null;
};
export type LolaBffApiContractsModelsDashboardKpiQuotesKpi = {
  totalAmount?: number;
  numberOfLoans?: number;
  averageTotalAmount?: number;
};
export type LolaBffApiContractsModelsDashboardKpiPipelineKpi = {
  totalAmount?: number;
  numberOfLoans?: number;
  averageTotalAmount?: number;
};
export type LolaBffApiContractsModelsDashboardKpiFundedKpi = {
  totalAmount?: number;
  numberOfLoans?: number;
  averageTotalAmount?: number;
};
export type LolaBffApiContractsModelsDashboardKpiKpiData = {
  quotes?: LolaBffApiContractsModelsDashboardKpiQuotesKpi;
  pipeline?: LolaBffApiContractsModelsDashboardKpiPipelineKpi;
  funded?: LolaBffApiContractsModelsDashboardKpiFundedKpi;
};
export type LolaBffApiContractsModelsDashboardAllTimeKpiQuotesToSubmission = {
  percentageClosed?: number;
  averageDaysToClose?: number | null;
  numberOfLoansSubmitted?: number;
  numberOfLoansClosed?: number;
};
export type LolaBffApiContractsModelsDashboardAllTimeKpiSubmissionToClose = {
  percentageClosed?: number;
  averageDaysToClose?: number | null;
  numberOfLoansSubmitted?: number;
  numberOfLoansClosed?: number;
};
export type LolaBffApiContractsModelsDashboardAllTimeKpiQuotesToClose = {
  percentageClosed?: number;
  averageDaysToClose?: number | null;
  numberOfLoansSubmitted?: number;
  numberOfLoansClosed?: number;
};
export type LolaBffApiContractsModelsDashboardAllTimeKpiAllTimeKpiData = {
  quotesToSubmission?: LolaBffApiContractsModelsDashboardAllTimeKpiQuotesToSubmission;
  submissionToClose?: LolaBffApiContractsModelsDashboardAllTimeKpiSubmissionToClose;
  quotesToClose?: LolaBffApiContractsModelsDashboardAllTimeKpiQuotesToClose;
};
export type LolaBffApiContractsModelsDashboardKeyStatisticsSubmissionKpi = {
  totalAmount?: number;
  numberOfLoans?: number;
  averageTotalAmount?: number;
};
export type LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolioItem = {
  amount?: number;
  numberOfLoans?: number;
  averageTotalAmount?: number;
};
export type LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolio = {
  totalAmount?: number;
  totalLoansCount?: number;
  fixNFlip?: LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolioItem;
  newConstruction?: LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolioItem;
  rental30?: LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolioItem;
  bridgePlus?: LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolioItem;
};
export type LolaBffApiContractsModelsDashboardKeyStatisticsSubmissionToCloseKeyStats =
  {
    percentages?: number;
    days?: number;
    closedLoanCount?: number;
  };
export type LolaBffApiContractsModelsDashboardKeyStatisticsKeyStatisticsData = {
  submission?: LolaBffApiContractsModelsDashboardKeyStatisticsSubmissionKpi;
  loanPortfolio?: LolaBffApiContractsModelsDashboardKeyStatisticsLoanPortfolio;
  submissionToClose?: LolaBffApiContractsModelsDashboardKeyStatisticsSubmissionToCloseKeyStats;
};
export type LolaBffApiContractsModelsDashboardLoansStatisticsBrokerStatistic = {
  id?: string | null;
  teamId?: number | null;
  managesTeams?: string[] | null;
  firstName?: string | null;
  lastName?: string | null;
  displayName?: string | null;
  dealsNumber?: number;
  applicationsNumber?: number;
  submissionsNumber?: number;
  closedNumber?: number;
  pipelineTotalAmount?: number;
  fundedTotalAmount?: number;
};
export type LolaBffApiContractsModelsDashboardLoansStatisticsTeamStatistic = {
  id?: number;
  displayName?: string | null;
  dealsNumber?: number;
  applicationsNumber?: number;
  submissionsNumber?: number;
  closedNumber?: number;
  pipelineTotalAmount?: number;
  fundedTotalAmount?: number;
  children?:
    | LolaBffApiContractsModelsDashboardLoansStatisticsBrokerStatistic[]
    | null;
};
export type LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatisticData =
  {
    children?:
      | LolaBffApiContractsModelsDashboardLoansStatisticsTeamStatistic[]
      | null;
  };
export type LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatistic =
  {
    children?:
      | LolaBffApiContractsModelsDashboardLoansStatisticsTeamStatistic[]
      | null;
    id?: string | null;
    teamId?: number | null;
    managesTeams?: string[] | null;
    firstName?: string | null;
    lastName?: string | null;
    displayName?: string | null;
    dealsNumber?: number;
    applicationsNumber?: number;
    submissionsNumber?: number;
    closedNumber?: number;
    pipelineTotalAmount?: number;
    fundedTotalAmount?: number;
  };
export type LolaBffApiContractsModelsDashboardLoansStatisticsLoansKeyBrokerManagerStatisticData =
  {
    children?:
      | LolaBffApiContractsModelsDashboardLoansStatisticsBrokerManagerLoansStatistic[]
      | null;
  };
export type LolaBffApiContractsModelsDashboardLoansStatisticsBrokerLoanStatisticData =
  {
    children?:
      | LolaBffApiContractsModelsDashboardLoansStatisticsTeamStatistic[]
      | null;
  };
export type LolaBffApiContractsModelsDocumentLockerDocumentInfo = {
  status?: string | null;
  fileName?: string | null;
  fileId?: string | null;
  folderId?: string | null;
  modifiedAt?: string | null;
  createdAt?: string | null;
  uploadedByEmail?: string | null;
  size?: string | null;
  provider?: string | null;
  downloadId?: string | null;
  taskId?: string | null;
  taskName?: string | null;
};
export type LolaBffApiContractsModelsPdfOption = {
  index?: number | null;
  value?: string | null;
  isSelected?: boolean;
};
export type LolaBffApiContractsModelsPdfPdfFieldInfo = {
  alternateName?: string | null;
  mappingName?: string | null;
  partialName?: string | null;
  group?: string | null;
  value?: string | null;
  isSelected?: boolean | null;
  items?: LolaBffApiContractsModelsPdfOption[] | null;
};
export type MicrosoftExtensionsDiagnosticsHealthChecksHealthStatus =
  | 'Unhealthy'
  | 'Degraded'
  | 'Healthy';
export type LolaBffApiContractsModelsHealthBaseHealthResponse = {
  status?: MicrosoftExtensionsDiagnosticsHealthChecksHealthStatus;
  name?: string | null;
  entityName?: string | null;
  description?: string | null;
};
export type LolaBffApiContractsModelsHealthHealthCheckResponse601LolaBffApiContractsModelsHealthBaseHealthResponse20LolaBffApiContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    details?: LolaBffApiContractsModelsHealthBaseHealthResponse[] | null;
    status?: MicrosoftExtensionsDiagnosticsHealthChecksHealthStatus;
    name?: string | null;
    entityName?: string | null;
    description?: string | null;
  };
export type LolaBffApiContractsModelsLearningCenterLearningDocument = {
  fileId?: string | null;
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  duration?: string | null;
  description?: string | null;
  contentUrl?: string | null;
  isMarketingMaterial?: boolean;
  thumbnail?: string | null;
  isViewed?: boolean;
};
export type LolaBffApiContractsModelsLearningCenterLearningCenterSection = {
  name?: string | null;
  documents?: LolaBffApiContractsModelsLearningCenterLearningDocument[] | null;
  isViewOnly?: boolean;
};
export type LolaBffApiContractsModelsLearningCenterLearningData = {
  sections?:
    | LolaBffApiContractsModelsLearningCenterLearningCenterSection[]
    | null;
};
export type MicrosoftAspNetCoreMvcRoutingAttributeRouteInfo = {
  template?: string | null;
  order?: number;
  name?: string | null;
  suppressLinkGeneration?: boolean;
  suppressPathMatching?: boolean;
};
export type MicrosoftAspNetCoreMvcActionConstraintsIActionConstraintMetadata =
  object;
export type MicrosoftAspNetCoreMvcModelBindingBindingSource = {
  displayName?: string | null;
  id?: string | null;
  isGreedy?: boolean;
  isFromRequest?: boolean;
};
export type SystemReflectionMemberTypes =
  | 'Constructor'
  | 'Event'
  | 'Field'
  | 'Method'
  | 'Property'
  | 'TypeInfo'
  | 'Custom'
  | 'NestedType'
  | 'All';
export type SystemReflectionParameterAttributes =
  | 'None'
  | 'In'
  | 'Out'
  | 'Lcid'
  | 'Retval'
  | 'Optional'
  | 'HasDefault'
  | 'HasFieldMarshal'
  | 'Reserved3'
  | 'Reserved4'
  | 'ReservedMask';
export type SystemReflectionMethodAttributes =
  | 'ReuseSlot'
  | 'PrivateScope, Private'
  | 'PrivateScope, FamANDAssem'
  | 'PrivateScope, Assembly'
  | 'PrivateScope, Family'
  | 'PrivateScope, FamORAssem'
  | 'PrivateScope, Public'
  | 'PrivateScope, MemberAccessMask'
  | 'PrivateScope, UnmanagedExport'
  | 'PrivateScope, Static'
  | 'PrivateScope, Final'
  | 'PrivateScope, Virtual'
  | 'PrivateScope, HideBySig'
  | 'PrivateScope, VtableLayoutMask'
  | 'PrivateScope, CheckAccessOnOverride'
  | 'PrivateScope, Abstract'
  | 'PrivateScope, SpecialName'
  | 'PrivateScope, RTSpecialName'
  | 'PrivateScope, PinvokeImpl'
  | 'PrivateScope, HasSecurity'
  | 'PrivateScope, RequireSecObject'
  | 'PrivateScope, ReservedMask';
export type SystemReflectionMethodImplAttributes =
  | 'Managed'
  | 'Native'
  | 'OPTIL'
  | 'CodeTypeMask'
  | 'Unmanaged'
  | 'NoInlining'
  | 'ForwardRef'
  | 'Synchronized'
  | 'NoOptimization'
  | 'PreserveSig'
  | 'AggressiveInlining'
  | 'AggressiveOptimization'
  | 'InternalCall'
  | 'MaxMethodImplVal';
export type SystemReflectionCallingConventions =
  | 'Standard'
  | 'VarArgs'
  | 'Any'
  | 'HasThis'
  | 'ExplicitThis';
export type SystemRuntimeMethodHandle = {
  value?: any;
};
export type SystemReflectionConstructorInfo = {
  memberType?: SystemReflectionMemberTypes;
  attributes?: SystemReflectionMethodAttributes;
  methodImplementationFlags?: SystemReflectionMethodImplAttributes;
  callingConvention?: SystemReflectionCallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: SystemRuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  name?: string | null;
  declaringType?: string | null;
  reflectedType?: string | null;
  module?: SystemReflectionModule;
  customAttributes?: SystemReflectionCustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
};
export type SystemReflectionCustomAttributeTypedArgument = {
  argumentType?: string | null;
  value?: any | null;
};
export type SystemReflectionCustomAttributeNamedArgument = {
  memberInfo?: SystemReflectionMemberInfo;
  typedValue?: SystemReflectionCustomAttributeTypedArgument;
  memberName?: string | null;
  isField?: boolean;
};
export type SystemReflectionCustomAttributeData = {
  attributeType?: string | null;
  constructor?: SystemReflectionConstructorInfo;
  constructorArguments?: SystemReflectionCustomAttributeTypedArgument[] | null;
  namedArguments?: SystemReflectionCustomAttributeNamedArgument[] | null;
};
export type SystemSecuritySecurityRuleSet = 'None' | 'Level1' | 'Level2';
export type SystemReflectionAssembly = {
  definedTypes?: string[] | null;
  exportedTypes?: string[] | null;
  codeBase?: string | null;
  entryPoint?: SystemReflectionMethodInfo;
  fullName?: string | null;
  imageRuntimeVersion?: string | null;
  isDynamic?: boolean;
  location?: string | null;
  reflectionOnly?: boolean;
  isCollectible?: boolean;
  isFullyTrusted?: boolean;
  customAttributes?: SystemReflectionCustomAttributeData[] | null;
  escapedCodeBase?: string | null;
  manifestModule?: SystemReflectionModule;
  modules?: SystemReflectionModule[] | null;
  globalAssemblyCache?: boolean;
  hostContext?: number;
  securityRuleSet?: SystemSecuritySecurityRuleSet;
};
export type SystemModuleHandle = {
  mdStreamVersion?: number;
};
export type SystemReflectionModule = {
  assembly?: SystemReflectionAssembly;
  fullyQualifiedName?: string | null;
  name?: string | null;
  mdStreamVersion?: number;
  moduleVersionId?: string;
  scopeName?: string | null;
  moduleHandle?: SystemModuleHandle;
  customAttributes?: SystemReflectionCustomAttributeData[] | null;
  metadataToken?: number;
};
export type SystemReflectionMemberInfo = {
  memberType?: SystemReflectionMemberTypes;
  name?: string | null;
  declaringType?: string | null;
  reflectedType?: string | null;
  module?: SystemReflectionModule;
  customAttributes?: SystemReflectionCustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
};
export type SystemReflectionParameterInfo = {
  attributes?: SystemReflectionParameterAttributes;
  member?: SystemReflectionMemberInfo;
  name?: string | null;
  parameterType?: string | null;
  position?: number;
  isIn?: boolean;
  isLcid?: boolean;
  isOptional?: boolean;
  isOut?: boolean;
  isRetval?: boolean;
  defaultValue?: any | null;
  rawDefaultValue?: any | null;
  hasDefaultValue?: boolean;
  customAttributes?: SystemReflectionCustomAttributeData[] | null;
  metadataToken?: number;
};
export type SystemReflectionICustomAttributeProvider = object;
export type SystemReflectionMethodInfo = {
  memberType?: SystemReflectionMemberTypes;
  returnParameter?: SystemReflectionParameterInfo;
  returnType?: string | null;
  returnTypeCustomAttributes?: SystemReflectionICustomAttributeProvider;
  attributes?: SystemReflectionMethodAttributes;
  methodImplementationFlags?: SystemReflectionMethodImplAttributes;
  callingConvention?: SystemReflectionCallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: SystemRuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  name?: string | null;
  declaringType?: string | null;
  reflectedType?: string | null;
  module?: SystemReflectionModule;
  customAttributes?: SystemReflectionCustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
};
export type SystemFunc602MicrosoftAspNetCoreMvcModelBindingModelMetadata20MicrosoftAspNetCoreMvcAbstractions20Version600020CultureNeutral20PublicKeyTokenAdb9793829Ddae60SystemBoolean20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E =
  {
    target?: any | null;
    method?: SystemReflectionMethodInfo;
  };
export type MicrosoftAspNetCoreMvcModelBindingIPropertyFilterProvider = {
  propertyFilter?: SystemFunc602MicrosoftAspNetCoreMvcModelBindingModelMetadata20MicrosoftAspNetCoreMvcAbstractions20Version600020CultureNeutral20PublicKeyTokenAdb9793829Ddae60SystemBoolean20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E;
};
export type SystemFunc602MicrosoftAspNetCoreMvcActionContext20MicrosoftAspNetCoreMvcAbstractions20Version600020CultureNeutral20PublicKeyTokenAdb9793829Ddae60SystemBoolean20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E =
  {
    target?: any | null;
    method?: SystemReflectionMethodInfo;
  };
export type MicrosoftAspNetCoreMvcModelBindingEmptyBodyBehavior =
  | 'Default'
  | 'Allow'
  | 'Disallow';
export type MicrosoftAspNetCoreMvcModelBindingBindingInfo = {
  bindingSource?: MicrosoftAspNetCoreMvcModelBindingBindingSource;
  binderModelName?: string | null;
  binderType?: string | null;
  propertyFilterProvider?: MicrosoftAspNetCoreMvcModelBindingIPropertyFilterProvider;
  requestPredicate?: SystemFunc602MicrosoftAspNetCoreMvcActionContext20MicrosoftAspNetCoreMvcAbstractions20Version600020CultureNeutral20PublicKeyTokenAdb9793829Ddae60SystemBoolean20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E;
  emptyBodyBehavior?: MicrosoftAspNetCoreMvcModelBindingEmptyBodyBehavior;
};
export type MicrosoftAspNetCoreMvcAbstractionsParameterDescriptor = {
  name?: string | null;
  parameterType?: string | null;
  bindingInfo?: MicrosoftAspNetCoreMvcModelBindingBindingInfo;
};
export type MicrosoftAspNetCoreMvcFiltersIFilterMetadata = object;
export type MicrosoftAspNetCoreMvcFiltersFilterDescriptor = {
  filter?: MicrosoftAspNetCoreMvcFiltersIFilterMetadata;
  order?: number;
  scope?: number;
};
export type MicrosoftAspNetCoreMvcAbstractionsActionDescriptor = {
  id?: string | null;
  routeValues?: {
    [key: string]: string;
  } | null;
  attributeRouteInfo?: MicrosoftAspNetCoreMvcRoutingAttributeRouteInfo;
  actionConstraints?:
    | MicrosoftAspNetCoreMvcActionConstraintsIActionConstraintMetadata[]
    | null;
  endpointMetadata?: any[] | null;
  parameters?: MicrosoftAspNetCoreMvcAbstractionsParameterDescriptor[] | null;
  boundProperties?:
    | MicrosoftAspNetCoreMvcAbstractionsParameterDescriptor[]
    | null;
  filterDescriptors?: MicrosoftAspNetCoreMvcFiltersFilterDescriptor[] | null;
  displayName?: string | null;
  properties?: {
    [key: string]: any;
  } | null;
};
export type SystemCollectionsGenericKeyValuePair602SystemType20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798ESystemObject20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E =
  {
    key?: string | null;
    value?: any | null;
  };
export type MicrosoftAspNetCoreHttpHostString = {
  value?: string | null;
  hasValue?: boolean;
  host?: string | null;
  port?: number | null;
};
export type MicrosoftAspNetCoreHttpQueryString = {
  value?: string | null;
  hasValue?: boolean;
};
export type SystemCollectionsGenericKeyValuePair602SystemString20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798EMicrosoftExtensionsPrimitivesStringValues20MicrosoftExtensionsPrimitives20Version600020CultureNeutral20PublicKeyTokenAdb9793829Ddae60 =
  {
    key?: string | null;
    value?: string[];
  };
export type SystemCollectionsGenericKeyValuePair602SystemString20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798ESystemString20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E =
  {
    key?: string | null;
    value?: string | null;
  };
export type SystemIoStream = {
  canRead?: boolean;
  canWrite?: boolean;
  canSeek?: boolean;
  canTimeout?: boolean;
  length?: number;
  position?: number;
  readTimeout?: number;
  writeTimeout?: number;
};
export type SystemIoPipelinesPipeReader = object;
export type MicrosoftAspNetCoreHttpHttpRequest = {
  httpContext?: MicrosoftAspNetCoreHttpHttpContext;
  method?: string | null;
  scheme?: string | null;
  isHttps?: boolean;
  host?: MicrosoftAspNetCoreHttpHostString;
  pathBase?: string;
  path?: string;
  queryString?: MicrosoftAspNetCoreHttpQueryString;
  query?:
    | SystemCollectionsGenericKeyValuePair602SystemString20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798EMicrosoftExtensionsPrimitivesStringValues20MicrosoftExtensionsPrimitives20Version600020CultureNeutral20PublicKeyTokenAdb9793829Ddae60[]
    | null;
  protocol?: string | null;
  headers?: {
    [key: string]: string[];
  } | null;
  cookies?:
    | SystemCollectionsGenericKeyValuePair602SystemString20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798ESystemString20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E[]
    | null;
  contentLength?: number | null;
  contentType?: string | null;
  body?: SystemIoStream;
  bodyReader?: SystemIoPipelinesPipeReader;
  hasFormContentType?: boolean;
  form?:
    | SystemCollectionsGenericKeyValuePair602SystemString20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798EMicrosoftExtensionsPrimitivesStringValues20MicrosoftExtensionsPrimitives20Version600020CultureNeutral20PublicKeyTokenAdb9793829Ddae60[]
    | null;
  routeValues?: {
    [key: string]: any;
  } | null;
};
export type SystemIoPipelinesPipeWriter = {
  canGetUnflushedBytes?: boolean;
  unflushedBytes?: number;
};
export type MicrosoftAspNetCoreHttpIResponseCookies = object;
export type MicrosoftAspNetCoreHttpHttpResponse = {
  httpContext?: MicrosoftAspNetCoreHttpHttpContext;
  statusCode?: number;
  headers?: {
    [key: string]: string[];
  } | null;
  body?: SystemIoStream;
  bodyWriter?: SystemIoPipelinesPipeWriter;
  contentLength?: number | null;
  contentType?: string | null;
  cookies?: MicrosoftAspNetCoreHttpIResponseCookies;
  hasStarted?: boolean;
};
export type SystemNetSocketsAddressFamily =
  | 'Unspecified'
  | 'Unix'
  | 'InterNetwork'
  | 'ImpLink'
  | 'Pup'
  | 'Chaos'
  | 'Ipx'
  | 'Iso'
  | 'Ecma'
  | 'DataKit'
  | 'Ccitt'
  | 'Sna'
  | 'DecNet'
  | 'DataLink'
  | 'Lat'
  | 'HyperChannel'
  | 'AppleTalk'
  | 'NetBios'
  | 'VoiceView'
  | 'FireFox'
  | 'Banyan'
  | 'Atm'
  | 'InterNetworkV6'
  | 'Cluster'
  | 'Ieee12844'
  | 'Irda'
  | 'NetworkDesigners'
  | 'Max'
  | 'Packet'
  | 'ControllerAreaNetwork'
  | 'Unknown';
export type SystemNetIpAddress = {
  addressFamily?: SystemNetSocketsAddressFamily;
  scopeId?: number;
  isIPv6Multicast?: boolean;
  isIPv6LinkLocal?: boolean;
  isIPv6SiteLocal?: boolean;
  isIPv6Teredo?: boolean;
  isIPv6UniqueLocal?: boolean;
  isIPv4MappedToIPv6?: boolean;
  address?: number;
};
export type SystemSecurityCryptographyOid = {
  value?: string | null;
  friendlyName?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesX509Extension = {
  critical?: boolean;
  oid?: SystemSecurityCryptographyOid;
  rawData?: string | null;
};
export type SystemSecurityCryptographyKeySizes = {
  minSize?: number;
  maxSize?: number;
  skipSize?: number;
};
export type SystemSecurityCryptographyAsymmetricAlgorithm = {
  keySize?: number;
  legalKeySizes?: SystemSecurityCryptographyKeySizes[] | null;
  signatureAlgorithm?: string | null;
  keyExchangeAlgorithm?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesX500DistinguishedName = {
  name?: string | null;
  oid?: SystemSecurityCryptographyOid;
  rawData?: string | null;
};
export type SystemSecurityCryptographyAsnEncodedData = {
  oid?: SystemSecurityCryptographyOid;
  rawData?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesPublicKey = {
  encodedKeyValue?: SystemSecurityCryptographyAsnEncodedData;
  encodedParameters?: SystemSecurityCryptographyAsnEncodedData;
  key?: SystemSecurityCryptographyAsymmetricAlgorithm;
  oid?: SystemSecurityCryptographyOid;
};
export type SystemSecurityCryptographyX509CertificatesX509Certificate2 = {
  archived?: boolean;
  extensions?: SystemSecurityCryptographyX509CertificatesX509Extension[] | null;
  friendlyName?: string | null;
  hasPrivateKey?: boolean;
  privateKey?: SystemSecurityCryptographyAsymmetricAlgorithm;
  issuerName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedName;
  notAfter?: string;
  notBefore?: string;
  publicKey?: SystemSecurityCryptographyX509CertificatesPublicKey;
  rawData?: string | null;
  serialNumber?: string | null;
  signatureAlgorithm?: SystemSecurityCryptographyOid;
  subjectName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedName;
  thumbprint?: string | null;
  version?: number;
  handle?: any;
  issuer?: string | null;
  subject?: string | null;
};
export type MicrosoftAspNetCoreHttpConnectionInfo = {
  id?: string | null;
  remoteIpAddress?: SystemNetIpAddress;
  remotePort?: number;
  localIpAddress?: SystemNetIpAddress;
  localPort?: number;
  clientCertificate?: SystemSecurityCryptographyX509CertificatesX509Certificate2;
};
export type MicrosoftAspNetCoreHttpWebSocketManager = {
  isWebSocketRequest?: boolean;
  webSocketRequestedProtocols?: string[] | null;
};
export type SystemSecurityClaimsClaimsIdentity = {
  authenticationType?: string | null;
  isAuthenticated?: boolean;
  actor?: SystemSecurityClaimsClaimsIdentity;
  bootstrapContext?: any | null;
  claims?: SystemSecurityClaimsClaim[] | null;
  label?: string | null;
  name?: string | null;
  nameClaimType?: string | null;
  roleClaimType?: string | null;
};
export type SystemSecurityClaimsClaim = {
  issuer?: string | null;
  originalIssuer?: string | null;
  properties?: {
    [key: string]: string;
  } | null;
  subject?: SystemSecurityClaimsClaimsIdentity;
  type?: string | null;
  value?: string | null;
  valueType?: string | null;
};
export type SystemSecurityPrincipalIIdentity = {
  authenticationType?: string | null;
  isAuthenticated?: boolean;
  name?: string | null;
};
export type SystemSecurityClaimsClaimsPrincipal = {
  claims?: SystemSecurityClaimsClaim[] | null;
  identities?: SystemSecurityClaimsClaimsIdentity[] | null;
  identity?: SystemSecurityPrincipalIIdentity;
};
export type SystemIServiceProvider = object;
export type MicrosoftWin32SafeHandlesSafeWaitHandle = {
  isInvalid?: boolean;
  isClosed?: boolean;
};
export type SystemThreadingWaitHandle = {
  handle?: any;
  safeWaitHandle?: MicrosoftWin32SafeHandlesSafeWaitHandle;
};
export type SystemThreadingCancellationToken = {
  isCancellationRequested?: boolean;
  canBeCanceled?: boolean;
  waitHandle?: SystemThreadingWaitHandle;
};
export type MicrosoftAspNetCoreHttpISession = {
  isAvailable?: boolean;
  id?: string | null;
  keys?: string[] | null;
};
export type MicrosoftAspNetCoreHttpHttpContext = {
  features?:
    | SystemCollectionsGenericKeyValuePair602SystemType20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798ESystemObject20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E[]
    | null;
  request?: MicrosoftAspNetCoreHttpHttpRequest;
  response?: MicrosoftAspNetCoreHttpHttpResponse;
  connection?: MicrosoftAspNetCoreHttpConnectionInfo;
  webSockets?: MicrosoftAspNetCoreHttpWebSocketManager;
  user?: SystemSecurityClaimsClaimsPrincipal;
  items?: {
    [key: string]: any;
  } | null;
  requestServices?: SystemIServiceProvider;
  requestAborted?: SystemThreadingCancellationToken;
  traceIdentifier?: string | null;
  session?: MicrosoftAspNetCoreHttpISession;
};
export type MicrosoftAspNetCoreMvcModelBindingModelError = {
  exception?: any | null;
  errorMessage?: string | null;
};
export type MicrosoftAspNetCoreMvcModelBindingModelValidationState =
  | 'Unvalidated'
  | 'Invalid'
  | 'Valid'
  | 'Skipped';
export type MicrosoftAspNetCoreMvcModelBindingModelStateEntry = {
  rawValue?: any | null;
  attemptedValue?: string | null;
  errors?: MicrosoftAspNetCoreMvcModelBindingModelError[] | null;
  validationState?: MicrosoftAspNetCoreMvcModelBindingModelValidationState;
  isContainerNode?: boolean;
  children?: MicrosoftAspNetCoreMvcModelBindingModelStateEntry[] | null;
};
export type MicrosoftAspNetCoreRoutingIRouter = object;
export type MicrosoftAspNetCoreRoutingRouteData = {
  dataTokens?: {
    [key: string]: any;
  } | null;
  routers?: MicrosoftAspNetCoreRoutingIRouter[] | null;
  values?: {
    [key: string]: any;
  } | null;
};
export type MicrosoftAspNetCoreMvcActionContext = {
  actionDescriptor?: MicrosoftAspNetCoreMvcAbstractionsActionDescriptor;
  httpContext?: MicrosoftAspNetCoreHttpHttpContext;
  modelState?: {
    [key: string]: MicrosoftAspNetCoreMvcModelBindingModelStateEntry;
  } | null;
  routeData?: MicrosoftAspNetCoreRoutingRouteData;
};
export type MicrosoftAspNetCoreMvcIUrlHelper = {
  actionContext?: MicrosoftAspNetCoreMvcActionContext;
};
export type MicrosoftAspNetCoreMvcRedirectResult = {
  permanent?: boolean;
  preserveMethod?: boolean;
  url?: string | null;
  urlHelper?: MicrosoftAspNetCoreMvcIUrlHelper;
};
export type LolaBffApiContractsModelsLicensesLicenseType = 'NMLS' | 'Other';
export type LolaBffApiContractsModelsLicensesLicenseStatus =
  | 'Active'
  | 'Expired'
  | 'ExpiredIn30Days';
export type LolaBffApiContractsModelsLicensesLicense = {
  licenseId?: string | null;
  stateCode: string;
  stateName?: string | null;
  licenseType: LolaBffApiContractsModelsLicensesLicenseType;
  licenseNumber?: string | null;
  status?: LolaBffApiContractsModelsLicensesLicenseStatus;
  expirationDate: string;
  createdBy?: string | null;
};
export type LolaBffApiContractsModelsLicensesCreateLicenseRequest = {
  stateCode: string;
  licenseNumber: string;
  licenseType: LolaBffApiContractsModelsLicensesLicenseType;
  expirationDate: string;
};
export type MicrosoftAspNetCoreMvcProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type LolaBffApiContractsModelsLicensesUpdateLicenseRequest = {
  stateCode: string;
  licenseNumber: string;
  licenseType: LolaBffApiContractsModelsLicensesLicenseType;
  expirationDate: string;
};
export type LolaBffCoreDomainModelLoanResponseAddPropertiesResponse = {
  properties?: string[] | null;
  relations?: string[] | null;
};
export type LolaBffApiContractsModelsLoanLoanType =
  | 'Not Set'
  | 'BridgePlus'
  | 'FixNFlip'
  | 'Rental'
  | 'New Construction'
  | 'Multifamily'
  | 'Rental30'
  | 'Rental30 Premier';
export type LolaBffApiContractsModelsLoanFloodInsuranceAgency = {
  fullName?: string | null;
};
export type LolaBffApiContractsModelsLoanDealExceptionCase = {
  exceptionType?: string | null;
  policySection?: string | null;
  exceptionCommentary?: string | null;
  exceptionStatus?: string | null;
  exceptionApprovedBy?: string | null;
  exceptionRiskMitigants?: string | null;
};
export type LolaBffApiContractsModelsLoanExceptions = {
  maxArvAmount?: LolaBffApiContractsModelsLoanDealExceptionCase;
  maxLtvLoanAmount?: LolaBffApiContractsModelsLoanDealExceptionCase;
  blendedLtcLoanAmount?: LolaBffApiContractsModelsLoanDealExceptionCase;
  executionType?: LolaBffApiContractsModelsLoanDealExceptionCase;
  maxLtarvAmount?: LolaBffApiContractsModelsLoanDealExceptionCase;
  blendedLtcRatePercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
  ltcRatePercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
  maxLtarvRatePercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
  constructionLtcRatePercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
  defaultInterestRatePercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
  constructionLtcLoanAmount?: LolaBffApiContractsModelsLoanDealExceptionCase;
};
export type LolaBffApiContractsModelsLoanPropertyManagement = {
  contactPointEmailValue?: string | null;
  representativeFullName?: string | null;
  fullAddressName?: string | null;
  contactPointTelephoneValue?: string | null;
  fullName?: string | null;
  propertyManagerType?: string | null;
  propertyManagementComments?: string | null;
};
export type LolaBffApiContractsModelsLoanLender = {
  returnFullAddress?: string | null;
  stateName?: string | null;
  stateCode?: string | null;
  postalCode?: string | null;
  cityName?: string | null;
  countyName?: string | null;
  entityType?: string | null;
  contactPointEmailValue?: string | null;
  representativeFullName?: string | null;
  formationState?: string | null;
  contactPointTelephoneValue?: string | null;
  addressLineText?: string | null;
  addressUnitIdentifier?: string | null;
};
export type LolaBffApiContractsModelsLoanGeneralContractor = {
  contactPointEmailValue?: string | null;
  contactPointTelephoneValue?: string | null;
  representativeFirstName?: string | null;
  fullName?: string | null;
  representativeLastName?: string | null;
  capitalExpenditureAmount?: string | null;
};
export type LolaBffApiContractsModelsLoanTitleCompany = {
  stateName?: string | null;
  stateCode?: string | null;
  postalCode?: string | null;
  closingAgentContactPointTelephoneValue?: string | null;
  cityName?: string | null;
  closingAgentContactPointEmailValue?: string | null;
  countyName?: string | null;
  titleInsuranceUnderwriter?: string | null;
  closingAgentFirstName?: string | null;
  fullName?: string | null;
  closingAgentLastName?: string | null;
  addressUnitIdentifier?: string | null;
  addressLineText?: string | null;
  attorney?: LolaBffApiContractsModelsLoanFloodInsuranceAgency;
};
export type LolaBffApiContractsModelsLoanDealBroker = {
  contactPointEmailValue?: string | null;
  contactPointTelephoneValue?: string | null;
  nmlsIdentifier?: string | null;
  representativeFirstName?: string | null;
  fullName?: string | null;
  representativeLastName?: string | null;
  brokerWiringInstructionsIndicator?: string | null;
};
export type LolaBffApiContractsModelsLoanIntegrationMetadata = {
  customerPortalId?: string | null;
  salesforceDealId?: string | null;
  salesforceAccountId?: string | null;
};
export type LolaBffApiContractsModelsLoanTitleCommitment = {
  titleEffectiveDate?: string | null;
  titleInternationalExecutionIndicator?: string | null;
  titleCommitmentComments?: string | null;
  titleVestingType?: string | null;
  requiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator?: string | null;
  allPayoffsLiensIncludedInSection1RequirementsIndicator?: string | null;
  leaseholdEndorsementIndicator?: string | null;
  allSubjectPropertiesIncludedIndicator?: string | null;
  titlePolicyAmount?: string | null;
  propertyRightsOwnershipType?: string | null;
  titleExpirationDate?: string | null;
};
export type LolaBffApiContractsModelsBoxFolder = {
  folderId?: string | null;
};
export type LolaBffApiContractsModelsStorageMeta = {
  box?: LolaBffApiContractsModelsBoxFolder;
};
export type LolaBffApiContractsModelsLoanLoanMilestone =
  | ''
  | 'New Deal'
  | 'Initial Deal Review'
  | 'Processing'
  | 'Suspended'
  | 'Final Deal Review'
  | 'Final Deal Review Approved'
  | 'Final Quote Issued'
  | 'Final Quote Accepted'
  | 'Final Closing Updates Requested'
  | 'Clear to Close'
  | 'Loan Package Sent'
  | 'Loan Package Received'
  | 'Funded'
  | 'Close of Escrow'
  | 'File Complete'
  | 'Dead Deal'
  | 'Exit State'
  | 'Pre-Submission Deal';
export type LolaBffApiContractsModelsLoanDealMetadata = {
  teams?: any[] | null;
  organizations?: string[] | null;
  activeUsers?: any[] | null;
  branches?: any[] | null;
  milestone?: LolaBffApiContractsModelsLoanLoanMilestone;
};
export type LolaBffApiContractsModelsStatusInfo = {
  status?: string | null;
};
export type LolaBffApiContractsModelsLoanFieldMetaAggregations = {
  firstRateChangeDate?: LolaBffApiContractsModelsStatusInfo;
  armInitialFixedTermMonthCount?: LolaBffApiContractsModelsStatusInfo;
  armRepriceFrequencyType?: LolaBffApiContractsModelsStatusInfo;
  armFinalAdjustableRateTermMonthCount?: LolaBffApiContractsModelsStatusInfo;
};
export type LolaBffApiContractsModelsLoanFieldMeta = {
  loanProgramType?: LolaBffApiContractsModelsStatusInfo;
  portfolioLoanIndicator?: LolaBffApiContractsModelsStatusInfo;
  loanPurposeType?: LolaBffApiContractsModelsStatusInfo;
  aggregations?: LolaBffApiContractsModelsLoanFieldMetaAggregations;
};
export type LolaBffApiContractsModelsLoanLedger = {
  appraisalDepositAmount?: string | null;
  newYorkProcessingFeeAmount?: string | null;
  appraisalFeeAmount?: string | null;
  interestReserveAmount?: string | null;
  deferredOriginationFeeAmount?: string | null;
  creditReportFeeAmount?: string | null;
  processingFeeAmount?: string | null;
  feasibilityReviewFeeAmount?: string | null;
  totalBrokerFeeAmount?: string | null;
  condoCertificationFeeAmount?: string | null;
  lenderCounselFeeAmount?: string | null;
  buyUpFeeReductionAmount?: string | null;
  originationFeeAmount?: string | null;
  contractorReviewFeeAmount?: string | null;
  buyDownFeeAmount?: string | null;
  floodTaxInsuranceCertificationFeeAmount?: string | null;
  brokerProcessingFeeAmount?: string | null;
  brokerOriginationFeeAmount?: string | null;
};
export type LolaBffApiContractsModelsLoanAggregationInfo = {
  calculated?: string | null;
  focused?: string | null;
  override?: string | null;
  thirdParty?: string | null;
};
export type LolaBffApiContractsModelsLoanQuoteAggregations = {
  altTier?: LolaBffApiContractsModelsLoanAggregationInfo;
  usCitizenshipIndicator?: LolaBffApiContractsModelsLoanAggregationInfo;
  borrowingEntity?: LolaBffApiContractsModelsLoanAggregationInfo;
  borrowerTier?: LolaBffApiContractsModelsLoanAggregationInfo;
  debtStatus?: LolaBffApiContractsModelsLoanAggregationInfo;
  primaryGuarantor?: LolaBffApiContractsModelsLoanAggregationInfo;
  primaryBorrowerCreditScore?: LolaBffApiContractsModelsLoanAggregationInfo;
  sumTotalStatementQualifyingBalance?: LolaBffApiContractsModelsLoanAggregationInfo;
  quoteId?: LolaBffApiContractsModelsLoanAggregationInfo;
  propertyStateCode?: LolaBffApiContractsModelsLoanAggregationInfo;
};
export type LolaBffApiContractsModelsLoanQuote = {
  aggregations?: LolaBffApiContractsModelsLoanQuoteAggregations;
};
export type LolaBffApiContractsModelsLoanDealModelAggregations = {
  armFinalAdjustableRateTermMonthCount?: LolaBffApiContractsModelsLoanAggregationInfo;
  armInitialFixedTermMonthCount?: LolaBffApiContractsModelsLoanAggregationInfo;
  armRepriceFrequencyType?: LolaBffApiContractsModelsLoanAggregationInfo;
  scheduledFirstPaymentDate?: LolaBffApiContractsModelsLoanAggregationInfo;
  lastPaymentDueDate?: LolaBffApiContractsModelsLoanAggregationInfo;
  loanMaturityDate?: LolaBffApiContractsModelsLoanAggregationInfo;
  firstRateChangeDate?: LolaBffApiContractsModelsLoanAggregationInfo;
  prepaymentPenaltyExpirationDate?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalBrokerFeeAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalLtvRatePercent?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalLtarvRatePercent?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalHoaFeesAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalInitialInsuranceEscrowAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalAsIsAppraisedValueAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalBudgetAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  settlementStatementAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalFloodInsurancePremiumAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalInitialTaxEscrowAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalOutstandingLoanPayoffAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalAdjustedMonthlyRentAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalPropertyInsurancePremiumAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalPurchasePriceAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalSubjectToAppraisedValueAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalNumberOfProperties?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalTaxAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  borrowerRenovationCostsAfterPurchaseAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalCostAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalFloodInsurancePremiumOutstandingAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  requestedLoanAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalMonthlyMarketRent?: LolaBffApiContractsModelsLoanAggregationInfo;
  netWireAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalPropertyInsurancePremiumOutstandingAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  prepaidInterestAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  loanProductType?: LolaBffApiContractsModelsLoanAggregationInfo;
  estimatedClosingDate?: LolaBffApiContractsModelsLoanAggregationInfo;
  loanPurposeType?: LolaBffApiContractsModelsLoanAggregationInfo;
  loanProgramType?: LolaBffApiContractsModelsLoanAggregationInfo;
};
export type LolaBffApiContractsModelsLoanLenderFinance = {
  partnerFirstName?: string | null;
  partnerLastName?: string | null;
  partnerFinalTermSheetApprovalStatusDate?: string | null;
};
export type LolaBffApiContractsModelsLoanLoanModel = {
  id?: string | null;
  canBeArchived?: boolean;
  isLoanDetailsEmpty?: boolean;
  loanIdentifier?: string | null;
  loanProgramType?: LolaBffApiContractsModelsLoanLoanType;
  loanPurposeType?: string | null;
  totalLoanAmount?: number;
  totalRatePercent?: string | null;
  applicationReceivedDate?: string | null;
  estimatedClosingDate?: string | null;
  underwriterApprovalDate?: string | null;
  submittedUnderwriterDate?: string | null;
  clearToCloseDate?: string | null;
  loanFundingDate?: string | null;
  applicationDisclosureDate?: string | null;
  loanToValueRatePercent?: string | null;
  rateLockExpiration?: string | null;
  loanTermPeriodMonthCount?: string | null;
  totalAdjustedMonthlyRentAmount?: string | null;
  haircutAmount?: string | null;
  firstRateChangeDate?: string | null;
  deferredMaintenanceAmount?: string | null;
  programDirectorApprovalIndicator?: string | null;
  loanPackageCheckAmount?: string | null;
  totalLoanFeesAndClosingCostAmount?: string | null;
  uccFiledIndicator?: string | null;
  constructionLtcRatePercent?: string | null;
  loanPackageWireRequestComments?: string | null;
  totalLtarvRatePercent?: string | null;
  warehouseBankStatusType?: string | null;
  eoNamedInsuredMatchIndicator?: string | null;
  maxLtarvAmount?: string | null;
  promissoryNoteReceivedByLenderIndicator?: string | null;
  totalAppraisedPropertyValuationAmount?: string | null;
  amortizationType?: string | null;
  wireAbaRoutingNumber?: string | null;
  promissoryNoteTrackingNumber?: string | null;
  totalPropertyValuationAmount?: string | null;
  armRepriceFrequencyType?: string | null;
  loanPackageNoteReceivedByLenderIndicator?: string | null;
  propertyInsuranceAgency?: LolaBffApiContractsModelsLoanFloodInsuranceAgency;
  settlementStatementComments?: string | null;
  loanPackageCheckNumber?: string | null;
  promissoryNoteComments?: string | null;
  aggregateOccupancyPercent?: string | null;
  exceptions?: LolaBffApiContractsModelsLoanExceptions;
  totalFloodInsurancePremiumOutstandingAmount?: string | null;
  warehouseBankType?: string | null;
  allongeOrAssignmentSentToWarehouseIndicator?: string | null;
  monthlyHomeownersAssociationAmount?: string | null;
  personalGuarantorsCount?: string | null;
  totalRenovationCostAmount?: string | null;
  totalFloodInsurancePremiumAmount?: string | null;
  propertyManagement?: LolaBffApiContractsModelsLoanPropertyManagement;
  totalPropertyInsurancePremiumOutstandingAmount?: string | null;
  creditMemorandumIndicator?: string | null;
  armFinalAdjustableRateTermMonthCount?: string | null;
  feeLlpAs?: string | null;
  loanPackageWireRequest?: string | null;
  capitalStructureType?: string | null;
  loanPackageChecksReceivedIndicator?: string | null;
  propertyModelType?: string | null;
  settlementStatementAmount?: string | null;
  shippingCompanyType?: string | null;
  marginRatePercent?: string | null;
  creditReviewCommitteeComments?: string | null;
  locIssuanceDate?: string | null;
  lender?: LolaBffApiContractsModelsLoanLender;
  scheduledFirstPaymentDate?: string | null;
  totalLtvRatePercent?: string | null;
  gracePeriodDays?: string | null;
  cashOutAmount?: string | null;
  totalTaxAmount?: string | null;
  totalSubjectToAppraisedValueAmount?: string | null;
  collateralPackageTrackingNumber?: string | null;
  maxLtarvRatePercent?: string | null;
  lateFeePercent?: string | null;
  prepaidInterestAmount?: string | null;
  creditReviewCommitteeApprovalIndicator?: string | null;
  totalInitialInsuranceEscrowAmount?: string | null;
  generalContractor?: LolaBffApiContractsModelsLoanGeneralContractor;
  blendedLtcLoanAmount?: string | null;
  executionType?: string | null;
  loanPackageWireRequestStatus?: string | null;
  pricingEngineExceptionStatus?: string | null;
  totalBudgetAmount?: string | null;
  underwriterApprovalComments?: string | null;
  finalUnderwritingDecision?: string | null;
  loanPaymentType?: string | null;
  titleCompany?: LolaBffApiContractsModelsLoanTitleCompany;
  powerOfAttorneyFullName?: string | null;
  armRepriceLookbackType?: string | null;
  debtServiceCoverageRatioPercent?: string | null;
  lastPaymentDueDate?: string | null;
  dealBroker?: LolaBffApiContractsModelsLoanDealBroker;
  programDirectorComments?: string | null;
  totalPropertyInsurancePremiumAmount?: string | null;
  defaultInterestRatePercent?: string | null;
  notaryAnalyst?: string | null;
  lifetimeCapRatePercent?: string | null;
  noteToWarehouseTrackingNumber?: string | null;
  noteRatePercent?: string | null;
  totalHoaFeesAmount?: string | null;
  settlementStatementFileNumber?: string | null;
  realEstateProgramType?: string | null;
  valuationFeesCollectedBySalesIndicator?: string | null;
  armInitialFixedTermMonthCount?: string | null;
  policySectionNumber?: string | null;
  integrationMetadata?: LolaBffApiContractsModelsLoanIntegrationMetadata;
  propertyInsurancePremiumPaymentType?: string | null;
  recourseType?: string | null;
  fundingShieldStatusType?: string | null;
  lienPosition?: string | null;
  totalCostAmount?: string | null;
  totalMonthlyPitiPaymentAmount?: string | null;
  rateLlpAs?: string | null;
  loanAmortizationPeriodMonthCount?: string | null;
  loanPackageNoteTrackingNumber?: string | null;
  loanPackageWireSentDate?: string | null;
  initialMonthlyInterestOnlyPaymentAmount?: string | null;
  initialAdvancedFundAmount?: string | null;
  blendedLtcRatePercent?: string | null;
  downPaymentAmount?: string | null;
  index?: string | null;
  wireAccountNumber?: string | null;
  loanAmountVerifiedMatchIndicator?: string | null;
  wireApprovalIndicator?: string | null;
  initialTaskGenerationDate?: string | null;
  loanPackageTrackingNumber?: string | null;
  loanPackageInternationalExecutionIndicator?: string | null;
  eoExpirationDate?: string | null;
  armRepriceRoundingMethodType?: string | null;
  totalAsIsAppraisedValueAmount?: string | null;
  armRepriceRoundingRatePercentType?: string | null;
  postClosingNotes?: string | null;
  maxArvAmount?: string | null;
  wireBankName?: string | null;
  allongeAssignmentTrackingNumber?: string | null;
  loanAmortizationPeriodYearCount?: string | null;
  settlementStatementMatchLedgerIndicator?: string | null;
  floodInsurancePremiumPaymentType?: string | null;
  totalPurchasePriceAmount?: string | null;
  allongeAssignmentSentToWarehouseIndicator?: string | null;
  totalOutstandingLoanPayoffAmount?: string | null;
  releasePercent?: string | null;
  constructionLtcLoanAmount?: string | null;
  chargentCollectionAmount?: string | null;
  wireReleaseDate?: string | null;
  initialLtvRatePercent?: string | null;
  lenderIdentifier?: string | null;
  floodInsuranceAgency?: LolaBffApiContractsModelsLoanFloodInsuranceAgency;
  assetSummaryReportIndicator?: string | null;
  interestAccrualMethodType?: string | null;
  constructionBudgetVerifiedMatchIndicator?: string | null;
  locExpirationDate?: string | null;
  underwriterApprovalStatus?: string | null;
  titleCommitment?: LolaBffApiContractsModelsLoanTitleCommitment;
  netWireAmount?: string | null;
  floorRatePercent?: string | null;
  loanInterestOnlyPeriodMonthCount?: string | null;
  interestReserveDepositAmount?: string | null;
  notaryExpirationDate?: string | null;
  ltcRatePercent?: string | null;
  totalInitialTaxEscrowAmount?: string | null;
  wireAccountName?: string | null;
  totalConstructionCostAmount?: string | null;
  locAvailableAmount?: string | null;
  monthlyTaxInsurancePaymentAmount?: string | null;
  totalLiquidAssetAmount?: string | null;
  capitalExpenditureAmount?: string | null;
  collateralPackageStatusType?: string | null;
  prepaymentPenaltyExpirationDate?: string | null;
  channel?: string | null;
  titleFormsComments?: string | null;
  maxLtvRatePercent?: string | null;
  chargentTransactionIdentifier?: string | null;
  uccExpirationDate?: string | null;
  nextPaymentDueDate?: string | null;
  preliminaryUnderwritingDecisionDate?: string | null;
  grossSpreadPercent?: string | null;
  totalMonthlyItiPaymentAmount?: string | null;
  promissoryNoteShippedIndicator?: string | null;
  maxLtvLoanAmount?: string | null;
  closingAnalyst?: string | null;
  promissoryNoteRequestedIndicator?: string | null;
  fundingAndDisbursementApprovalIndicator?: string | null;
  pricingEngineOverrides?: string | null;
  borrowerRenovationCostsAfterPurchaseAmount?: string | null;
  loanMaturityDate?: string | null;
  totalPropertyCount?: string | null;
  employeeLoanIndicator?: string | null;
  loanName?: string | null;
  chargentGatewayIdentifier?: string | null;
  loanAfterInterestOnlyPeriodMonthCount?: string | null;
  monthlyPrincipalInterestPaymentAmount?: string | null;
  storageMeta?: LolaBffApiContractsModelsStorageMeta;
  createdAt?: string | null;
  portfolioLoanIndicator?: string | null;
  dealMetadata?: LolaBffApiContractsModelsLoanDealMetadata;
  paymentDueDay?: string | null;
  prepaymentPenaltyType?: string | null;
  indexSourceType?: string | null;
  fieldMeta?: LolaBffApiContractsModelsLoanFieldMeta;
  loanProductType?: string | null;
  ledger?: LolaBffApiContractsModelsLoanLedger;
  quote?: LolaBffApiContractsModelsLoanQuote;
  aggregations?: LolaBffApiContractsModelsLoanDealModelAggregations;
  modifiedAt?: string | null;
  exitStrategy?: string | null;
  propertyLoanPurpose?: string | null;
  targetCloseDate?: string | null;
  projectAnticipatedCompletionTimeline?: string | null;
  constructionBudgetRemainingAmount?: string | null;
  verifiableConstructionCompletedAmount?: string | null;
  lienDollarAmount?: string | null;
  propertyTypeChangingIndicator?: string | null;
  rateLockEndDate?: string | null;
  requestedLoanAmount?: string | null;
  lenderTitleProviderIndicator?: string | null;
  lotStatus?: string | null;
  lotUtilitiesIndicator?: string | null;
  lotZonedParcelIdIndicator?: string | null;
  occupancy?: string | null;
  occupationIndicator?: string | null;
  propertyLeasePurchaseOptionIndicator?: string | null;
  propertyRentReadyConditionIndicator?: string | null;
  propertyZoningChangesIndicator?: string | null;
  sellerRelationshipIndicator?: string | null;
  associatedPropertiesDescription?: string | null;
  subdividePropertyPartialReleasesIndicator?: string | null;
  loadBearingWallsRemoveIntendedIndicator?: string | null;
  squareFootageAddedIndicator?: string | null;
  buildingEnvelopeExpandedIndicator?: string | null;
  lenderPropertyInsuranceProviderIndicator?: string | null;
  hoaContactName?: string | null;
  hoaContactPhone?: string | null;
  hoaContactEmail?: string | null;
  developmentStrategy?: string | null;
  lenderFinance?: LolaBffApiContractsModelsLoanLenderFinance;
  lola?: any | null;
  loanStatus?: string | null;
  parties?: LolaBffApiContractsModelsLoanPartyInfo[] | null;
};
export type LolaBffApiContractsModelsRequestsCreateNewLoanRequest = {
  borrowerId?: string | null;
  loanProgramType?: string | null;
  brokerUserId?: string | null;
  processorUserId?: string | null;
};
export type LolaBffApiContractsModelsLoanProcessorDto = {
  id?: string | null;
  processorName?: string | null;
  email?: string | null;
};
export type LolaBffApiContractsModelsLoanLoanView = {
  id?: string | null;
  loanType?: string | null;
  processors?: LolaBffApiContractsModelsLoanProcessorDto[] | null;
  loanNumber?: string | null;
  borrowerName?: string | null;
  addressLine?: string | null;
  createdAt?: string;
  lastUpdateAt?: string | null;
  brokerName?: string | null;
  brokerId?: string | null;
  brokerEmail?: string | null;
  status?: string | null;
  step?: string | null;
};
export type LolaBffApiContractsModelsLoanFileModelsPdfApplicationType =
  | 'NotSet'
  | 'BorrowerGuarantorApp'
  | 'GuarantorApp'
  | 'FixNFlipBridgeApp'
  | 'NewConstructionApp'
  | 'RentalSingle30App'
  | 'PortfolioApp';
export type LolaBffApiContractsModelsIdResponse = {
  id?: string | null;
};
export type LolaBffApiContractsModelsLoanRequestUpdateLoanModel = {
  id?: string | null;
  canBeArchived?: boolean;
  isLoanDetailsEmpty?: boolean;
  loanIdentifier?: string | null;
  loanProgramType?: LolaBffApiContractsModelsLoanLoanType;
  loanPurposeType?: string | null;
  totalLoanAmount?: number;
  totalRatePercent?: string | null;
  applicationReceivedDate?: string | null;
  estimatedClosingDate?: string | null;
  underwriterApprovalDate?: string | null;
  submittedUnderwriterDate?: string | null;
  clearToCloseDate?: string | null;
  loanFundingDate?: string | null;
  applicationDisclosureDate?: string | null;
  loanToValueRatePercent?: string | null;
  rateLockExpiration?: string | null;
  loanTermPeriodMonthCount?: string | null;
  totalAdjustedMonthlyRentAmount?: string | null;
  haircutAmount?: string | null;
  firstRateChangeDate?: string | null;
  deferredMaintenanceAmount?: string | null;
  programDirectorApprovalIndicator?: string | null;
  loanPackageCheckAmount?: string | null;
  totalLoanFeesAndClosingCostAmount?: string | null;
  uccFiledIndicator?: string | null;
  constructionLtcRatePercent?: string | null;
  loanPackageWireRequestComments?: string | null;
  totalLtarvRatePercent?: string | null;
  warehouseBankStatusType?: string | null;
  eoNamedInsuredMatchIndicator?: string | null;
  maxLtarvAmount?: string | null;
  promissoryNoteReceivedByLenderIndicator?: string | null;
  totalAppraisedPropertyValuationAmount?: string | null;
  amortizationType?: string | null;
  wireAbaRoutingNumber?: string | null;
  promissoryNoteTrackingNumber?: string | null;
  totalPropertyValuationAmount?: string | null;
  armRepriceFrequencyType?: string | null;
  loanPackageNoteReceivedByLenderIndicator?: string | null;
  propertyInsuranceAgency?: LolaBffApiContractsModelsLoanFloodInsuranceAgency;
  settlementStatementComments?: string | null;
  loanPackageCheckNumber?: string | null;
  promissoryNoteComments?: string | null;
  aggregateOccupancyPercent?: string | null;
  exceptions?: LolaBffApiContractsModelsLoanExceptions;
  totalFloodInsurancePremiumOutstandingAmount?: string | null;
  warehouseBankType?: string | null;
  allongeOrAssignmentSentToWarehouseIndicator?: string | null;
  monthlyHomeownersAssociationAmount?: string | null;
  personalGuarantorsCount?: string | null;
  totalRenovationCostAmount?: string | null;
  totalFloodInsurancePremiumAmount?: string | null;
  propertyManagement?: LolaBffApiContractsModelsLoanPropertyManagement;
  totalPropertyInsurancePremiumOutstandingAmount?: string | null;
  creditMemorandumIndicator?: string | null;
  armFinalAdjustableRateTermMonthCount?: string | null;
  feeLlpAs?: string | null;
  loanPackageWireRequest?: string | null;
  capitalStructureType?: string | null;
  loanPackageChecksReceivedIndicator?: string | null;
  propertyModelType?: string | null;
  settlementStatementAmount?: string | null;
  shippingCompanyType?: string | null;
  marginRatePercent?: string | null;
  creditReviewCommitteeComments?: string | null;
  locIssuanceDate?: string | null;
  lender?: LolaBffApiContractsModelsLoanLender;
  scheduledFirstPaymentDate?: string | null;
  totalLtvRatePercent?: string | null;
  gracePeriodDays?: string | null;
  cashOutAmount?: string | null;
  totalTaxAmount?: string | null;
  totalSubjectToAppraisedValueAmount?: string | null;
  collateralPackageTrackingNumber?: string | null;
  maxLtarvRatePercent?: string | null;
  lateFeePercent?: string | null;
  prepaidInterestAmount?: string | null;
  creditReviewCommitteeApprovalIndicator?: string | null;
  totalInitialInsuranceEscrowAmount?: string | null;
  generalContractor?: LolaBffApiContractsModelsLoanGeneralContractor;
  blendedLtcLoanAmount?: string | null;
  executionType?: string | null;
  loanPackageWireRequestStatus?: string | null;
  pricingEngineExceptionStatus?: string | null;
  totalBudgetAmount?: string | null;
  underwriterApprovalComments?: string | null;
  finalUnderwritingDecision?: string | null;
  loanPaymentType?: string | null;
  titleCompany?: LolaBffApiContractsModelsLoanTitleCompany;
  powerOfAttorneyFullName?: string | null;
  armRepriceLookbackType?: string | null;
  debtServiceCoverageRatioPercent?: string | null;
  lastPaymentDueDate?: string | null;
  dealBroker?: LolaBffApiContractsModelsLoanDealBroker;
  programDirectorComments?: string | null;
  totalPropertyInsurancePremiumAmount?: string | null;
  defaultInterestRatePercent?: string | null;
  notaryAnalyst?: string | null;
  lifetimeCapRatePercent?: string | null;
  noteToWarehouseTrackingNumber?: string | null;
  noteRatePercent?: string | null;
  totalHoaFeesAmount?: string | null;
  settlementStatementFileNumber?: string | null;
  realEstateProgramType?: string | null;
  valuationFeesCollectedBySalesIndicator?: string | null;
  armInitialFixedTermMonthCount?: string | null;
  policySectionNumber?: string | null;
  integrationMetadata?: LolaBffApiContractsModelsLoanIntegrationMetadata;
  propertyInsurancePremiumPaymentType?: string | null;
  recourseType?: string | null;
  fundingShieldStatusType?: string | null;
  lienPosition?: string | null;
  totalCostAmount?: string | null;
  totalMonthlyPitiPaymentAmount?: string | null;
  rateLlpAs?: string | null;
  loanAmortizationPeriodMonthCount?: string | null;
  loanPackageNoteTrackingNumber?: string | null;
  loanPackageWireSentDate?: string | null;
  initialMonthlyInterestOnlyPaymentAmount?: string | null;
  initialAdvancedFundAmount?: string | null;
  blendedLtcRatePercent?: string | null;
  downPaymentAmount?: string | null;
  index?: string | null;
  wireAccountNumber?: string | null;
  loanAmountVerifiedMatchIndicator?: string | null;
  wireApprovalIndicator?: string | null;
  initialTaskGenerationDate?: string | null;
  loanPackageTrackingNumber?: string | null;
  loanPackageInternationalExecutionIndicator?: string | null;
  eoExpirationDate?: string | null;
  armRepriceRoundingMethodType?: string | null;
  totalAsIsAppraisedValueAmount?: string | null;
  armRepriceRoundingRatePercentType?: string | null;
  postClosingNotes?: string | null;
  maxArvAmount?: string | null;
  wireBankName?: string | null;
  allongeAssignmentTrackingNumber?: string | null;
  loanAmortizationPeriodYearCount?: string | null;
  settlementStatementMatchLedgerIndicator?: string | null;
  floodInsurancePremiumPaymentType?: string | null;
  totalPurchasePriceAmount?: string | null;
  allongeAssignmentSentToWarehouseIndicator?: string | null;
  totalOutstandingLoanPayoffAmount?: string | null;
  releasePercent?: string | null;
  constructionLtcLoanAmount?: string | null;
  chargentCollectionAmount?: string | null;
  wireReleaseDate?: string | null;
  initialLtvRatePercent?: string | null;
  lenderIdentifier?: string | null;
  floodInsuranceAgency?: LolaBffApiContractsModelsLoanFloodInsuranceAgency;
  assetSummaryReportIndicator?: string | null;
  interestAccrualMethodType?: string | null;
  constructionBudgetVerifiedMatchIndicator?: string | null;
  locExpirationDate?: string | null;
  underwriterApprovalStatus?: string | null;
  titleCommitment?: LolaBffApiContractsModelsLoanTitleCommitment;
  netWireAmount?: string | null;
  floorRatePercent?: string | null;
  loanInterestOnlyPeriodMonthCount?: string | null;
  interestReserveDepositAmount?: string | null;
  notaryExpirationDate?: string | null;
  ltcRatePercent?: string | null;
  totalInitialTaxEscrowAmount?: string | null;
  wireAccountName?: string | null;
  totalConstructionCostAmount?: string | null;
  locAvailableAmount?: string | null;
  monthlyTaxInsurancePaymentAmount?: string | null;
  totalLiquidAssetAmount?: string | null;
  capitalExpenditureAmount?: string | null;
  collateralPackageStatusType?: string | null;
  prepaymentPenaltyExpirationDate?: string | null;
  channel?: string | null;
  titleFormsComments?: string | null;
  maxLtvRatePercent?: string | null;
  chargentTransactionIdentifier?: string | null;
  uccExpirationDate?: string | null;
  nextPaymentDueDate?: string | null;
  preliminaryUnderwritingDecisionDate?: string | null;
  grossSpreadPercent?: string | null;
  totalMonthlyItiPaymentAmount?: string | null;
  promissoryNoteShippedIndicator?: string | null;
  maxLtvLoanAmount?: string | null;
  closingAnalyst?: string | null;
  promissoryNoteRequestedIndicator?: string | null;
  fundingAndDisbursementApprovalIndicator?: string | null;
  pricingEngineOverrides?: string | null;
  borrowerRenovationCostsAfterPurchaseAmount?: string | null;
  loanMaturityDate?: string | null;
  totalPropertyCount?: string | null;
  employeeLoanIndicator?: string | null;
  loanName?: string | null;
  chargentGatewayIdentifier?: string | null;
  loanAfterInterestOnlyPeriodMonthCount?: string | null;
  monthlyPrincipalInterestPaymentAmount?: string | null;
  storageMeta?: LolaBffApiContractsModelsStorageMeta;
  createdAt?: string | null;
  portfolioLoanIndicator?: string | null;
  dealMetadata?: LolaBffApiContractsModelsLoanDealMetadata;
  paymentDueDay?: string | null;
  prepaymentPenaltyType?: string | null;
  indexSourceType?: string | null;
  fieldMeta?: LolaBffApiContractsModelsLoanFieldMeta;
  loanProductType?: string | null;
  ledger?: LolaBffApiContractsModelsLoanLedger;
  quote?: LolaBffApiContractsModelsLoanQuote;
  aggregations?: LolaBffApiContractsModelsLoanDealModelAggregations;
  modifiedAt?: string | null;
  exitStrategy?: string | null;
  propertyLoanPurpose?: string | null;
  targetCloseDate?: string | null;
  projectAnticipatedCompletionTimeline?: string | null;
  constructionBudgetRemainingAmount?: string | null;
  verifiableConstructionCompletedAmount?: string | null;
  lienDollarAmount?: string | null;
  propertyTypeChangingIndicator?: string | null;
  rateLockEndDate?: string | null;
  requestedLoanAmount?: string | null;
  lenderTitleProviderIndicator?: string | null;
  lotStatus?: string | null;
  lotUtilitiesIndicator?: string | null;
  lotZonedParcelIdIndicator?: string | null;
  occupancy?: string | null;
  occupationIndicator?: string | null;
  propertyLeasePurchaseOptionIndicator?: string | null;
  propertyRentReadyConditionIndicator?: string | null;
  propertyZoningChangesIndicator?: string | null;
  sellerRelationshipIndicator?: string | null;
  associatedPropertiesDescription?: string | null;
  subdividePropertyPartialReleasesIndicator?: string | null;
  loadBearingWallsRemoveIntendedIndicator?: string | null;
  squareFootageAddedIndicator?: string | null;
  buildingEnvelopeExpandedIndicator?: string | null;
  lenderPropertyInsuranceProviderIndicator?: string | null;
  hoaContactName?: string | null;
  hoaContactPhone?: string | null;
  hoaContactEmail?: string | null;
  developmentStrategy?: string | null;
  lenderFinance?: LolaBffApiContractsModelsLoanLenderFinance;
  lola?: any | null;
  loanStatus?: string | null;
  parties?: LolaBffApiContractsModelsLoanPartyInfo[] | null;
};
export type LolaBffApiContractsModelsPropertyInsuranceAgency = {
  addressLineText?: string | null;
  addressUnitIdentifier?: string | null;
  cityName?: string | null;
  countyName?: string | null;
  stateName?: string | null;
  stateCode?: string | null;
  postalCode?: string | null;
  fullName?: string | null;
  representativeFullName?: string | null;
  contactPointTelephoneValue?: string | null;
  contactPointEmailValue?: string | null;
  insuranceAgentFullName?: string | null;
};
export type LolaBffApiContractsModelsLoanPropertyAcceess = {
  contactName?: string | null;
  phone?: string | null;
  email?: string | null;
};
export type LolaBffApiContractsModelsPropertyResultAppraisal = {
  appraisalValueDerivationType?: string | null;
  appraisalOrderedDate?: string | null;
  appraisalCompletedDate?: string | null;
  appraisalEffectiveDate?: string | null;
  appraisalExpirationDate?: string | null;
  appraisalType?: string | null;
  qualityRatingType?: string | null;
  conditionRatingType?: string | null;
  totalBedroomCount?: string | null;
  totalBathroomCount?: string | null;
  propertyStructureBuiltYear?: string | null;
  appraisalDate?: string | null;
  propertyAreaValue?: string | null;
  propertyBuiltYear?: string | null;
  specificZoningClassification?: string | null;
  propertyZoningDescription?: string | null;
  propertyZoningComplianceRatingType?: string | null;
  propertyValuationAmount?: string | null;
  subjectToPropertyValuationAmount?: string | null;
  appraisalCoordinator?: string | null;
  internalValuationEligibilityIndicator?: string | null;
  propertyAccessInformation?: string | null;
  appraisalForm1007RequiredIndicator?: string | null;
};
export type LolaBffApiContractsModelsPropertyAppraisalManagementCompany = {
  representativeFullName?: string | null;
  contactPointTelephoneValue?: string | null;
  contactPointEmailValue?: string | null;
  appraisalManagementCompanyName?: string | null;
};
export type LolaBffApiContractsModelsPropertyUnit = {
  unitName?: string | null;
  monthlyLeaseRentAmount?: string | null;
  monthlyMarketRentAmount?: string | null;
  adjustedRentAmount?: string | null;
  leaseStatusType?: string | null;
  leaseExpirationDate?: string | null;
  rentReportConfidenceScore?: string | null;
  paymentVerificationIndicator?: string | null;
  corporateLeaseIndicator?: string | null;
};
export type LolaBffApiContractsModelsPropertyLeasedFinancedUnits = {
  unit0?: LolaBffApiContractsModelsPropertyUnit;
  unit1?: LolaBffApiContractsModelsPropertyUnit;
  unit2?: LolaBffApiContractsModelsPropertyUnit;
  unit3?: LolaBffApiContractsModelsPropertyUnit;
  unit4?: LolaBffApiContractsModelsPropertyUnit;
  unit5?: LolaBffApiContractsModelsPropertyUnit;
  unit6?: LolaBffApiContractsModelsPropertyUnit;
  unit7?: LolaBffApiContractsModelsPropertyUnit;
  unit8?: LolaBffApiContractsModelsPropertyUnit;
};
export type LolaBffApiContractsModelsPropertyPropertyInsurance = {
  propertyInsurancePolicyIdentifier?: string | null;
  deductibleAmount?: string | null;
  dwellingCoverageAmount?: string | null;
  rentalLossCoverageAmount?: string | null;
  liabilityCoverageAmount?: string | null;
  insurancePremiumAmount?: string | null;
  insurancePremiumOutstandingAmount?: string | null;
  policyExpirationDate?: string | null;
  nextPaymentDueDate?: string | null;
  annualPropertyInsuranceAmount?: string | null;
  replacementCoverageIndicator?: string | null;
  insurancePremiumPaymentType?: string | null;
  builderRiskInsurance?: string | null;
};
export type LolaBffApiContractsModelsPropertyFloodInsurance = {
  insurancePremiumAmount?: string | null;
  dwellingCoverageAmount?: string | null;
  insurancePremiumPaymentType?: string | null;
  insurancePremiumOutstandingAmount?: string | null;
  deductibleAmount?: string | null;
  policyNumber?: string | null;
  policyExpirationDate?: string | null;
  nextPaymentDueDate?: string | null;
};
export type LolaBffApiContractsModelsLoanLoanProperty = {
  id?: string | null;
  propertyType?: string | null;
  propertyLoanPurpose?: string | null;
  mortgageDollarAmount?: string | null;
  propertyInsuranceAgency?: LolaBffApiContractsModelsPropertyInsuranceAgency;
  propertyAcceess?: LolaBffApiContractsModelsLoanPropertyAcceess;
  currentMarketValue?: string | null;
  contractedMonthlyRent?: string | null;
  estimatedMarketRent?: string | null;
  units?: string | null;
  purchasePrice?: string | null;
  originalPurchasePrice?: string | null;
  originalPurchaseDate?: string | null;
  estimatedAfterRepairValue?: string | null;
  annualTaxes?: string | null;
  annualPropertyInsurance?: string | null;
  annualFloodInsurance?: string | null;
  parcelNumber?: string | null;
  address?: LolaBffApiContractsModelsLoanAddress;
  financedUnitCount?: string | null;
  originalPurchasePriceAmount?: string | null;
  outstandingLoanPayoffAmount?: string | null;
  appraisal?: LolaBffApiContractsModelsPropertyResultAppraisal;
  subjectToPropertyValuationAmount?: string | null;
  appraisalManagementCompany?: LolaBffApiContractsModelsPropertyAppraisalManagementCompany;
  leasedFinancedUnits?: LolaBffApiContractsModelsPropertyLeasedFinancedUnits;
  cityAnnualTaxAmount?: string | null;
  propertyInsurance?: LolaBffApiContractsModelsPropertyPropertyInsurance;
  floodInsurance?: LolaBffApiContractsModelsPropertyFloodInsurance;
  annualHomeownersAssociationFeeAmount?: string | null;
  propertyValuationAmount?: string | null;
  purchasePriceAmount?: string | null;
  monthlyMarketRentAmount?: string | null;
  monthlyLeaseRentAmount?: string | null;
  annualTaxesAmount?: string | null;
  annualPropertyInsuranceAmount?: string | null;
  createdAt?: string;
  modifiedAt?: string | null;
  lola?: any | null;
};
export type LolaBffApiContractsModelsLoanTaskTemplateInfo = {
  templateId?: string | null;
  description?: string | null;
  condition?: any | null;
};
export type LolaBffApiContractsModelsLoanTaskInfo = {
  taskId?: string | null;
  taskName?: string | null;
  taskConfigurationId?: string | null;
  taskStatus?: string | null;
  comments?: string | null;
  documents?: LolaBffApiContractsModelsLoanDocumentTaskFileInfo[] | null;
  templates?: LolaBffApiContractsModelsLoanTaskTemplateInfo[] | null;
  createdAt?: string;
  modifiedAt?: string | null;
};
export type LolaBffApiContractsModelsLoanDocumentTask = {
  entityType?: string | null;
  entityId?: string | null;
  task?: LolaBffApiContractsModelsLoanTaskInfo;
};
export type LolaBffApiContractsModelsLoanDocumentTaskSection = {
  section?: string | null;
  documentTasks?: LolaBffApiContractsModelsLoanDocumentTask[] | null;
};
export type LolaBffApiContractsModelsLoanChangeBrokerRequest = {
  userId?: string | null;
};
export type LolaBffApiContractsModelsLoanOperation = {
  name?: string | null;
  userId?: string | null;
};
export type LolaBffApiContractsModelsLoanManageLoanUsersRequest = {
  operations?: LolaBffApiContractsModelsLoanOperation[] | null;
};
export type LolaBffApiContractsModelsPartyPartyRoleRequest = {
  role: string;
};
export type LolaBffCoreDomainModelLoanResponseAddGuarantorResponse = {
  guarantorPartyId?: string | null;
  dealPartyRelationId?: string | null;
};
export type LolaBffApiContractsModelsRequestsCreateLoanFromQuoteRequest = {
  quoteId?: number;
  borrowerId?: string | null;
  brokerUserId?: string | null;
  processorUserId?: string | null;
};
export type LolaBffApiContractsModelsLoanBorrower = {
  id?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  phoneNumber?: string | null;
  zip?: string | null;
  state?: string | null;
  city?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  company?: string | null;
};
export type LolaBffApiContractsModelsUserMetadata = {
  phoneNumber?: string | null;
  receiveCall?: boolean;
  receiveEmail?: boolean;
};
export type LolaBffApiContractsModelsUserRole =
  | 'NotSet'
  | 'KeyBrokerManager'
  | 'BrokerManager'
  | 'Broker'
  | 'Processor';
export type LolaBffApiContractsModelsUserStatus =
  | 'NotSet'
  | 'Active'
  | 'Invited'
  | 'Archived';
export type LolaBffApiContractsModelsUser = {
  userId?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userMetadata?: LolaBffApiContractsModelsUserMetadata;
  fullName?: string | null;
  picture?: string | null;
  createdAt?: string | null;
  lastLogin?: string | null;
  role?: LolaBffApiContractsModelsUserRole;
  status?: LolaBffApiContractsModelsUserStatus;
  teamId?: number | null;
  managesTeams?: string[] | null;
  assignedLoans?: string[] | null;
};
export type LolaBffApiContractsModelsLoanLoanStatus =
  | 'Not Set'
  | 'Unsubmitted'
  | 'Submitted'
  | 'Clear to close'
  | 'Closed'
  | 'Archived'
  | 'Change request'
  | 'In Development'
  | 'In Review'
  | 'Delivered'
  | 'Closing'
  | 'N/A'
  | 'Suspended';
export type LolaBffApiContractsModelsLoanStagesLoanStageType =
  | 'NotSet'
  | 'BuildMyLoan'
  | 'FeasibilityReview'
  | 'ClearMyLoan'
  | 'CloseMyLoan';
export type LolaBffApiContractsModelsLoanStagesLoanStageItem = {
  id?: string | null;
  stageOrder?: number;
  stageType?: LolaBffApiContractsModelsLoanStagesLoanStageType;
  name?: string | null;
  status?: LolaBffApiContractsModelsLoanStagesStageStatus;
  createdAt?: string;
  createdBy?: LolaBffApiContractsModelsUser;
  dueDate?: string | null;
  isStarted?: boolean;
  stepsCount?: number;
  currentItemStepNumber?: number;
};
export type LolaBffApiContractsModelsLoanLoan = {
  id?: string | null;
  loanIdentifier?: string | null;
  name?: string | null;
  productType?: LolaBffApiContractsModelsLoanLoanType;
  borrower?: LolaBffApiContractsModelsLoanBorrower;
  createdAt?: string;
  lastUpdateAt?: string | null;
  createdBy?: LolaBffApiContractsModelsUser;
  broker?: LolaBffApiContractsModelsUser;
  processors?: LolaBffApiContractsModelsUser[] | null;
  status?: LolaBffApiContractsModelsLoanLoanStatus;
  currentMilestone?: LolaBffApiContractsModelsLoanLoanMilestone;
  stages?: LolaBffApiContractsModelsLoanStagesLoanStageItem[] | null;
};
export type LolaBffApiContractsModelsLoanActiveLoan = {
  createdAt?: string | null;
  lastUpdateAt?: string | null;
  notifications?: number;
  id?: string | null;
  loanIdentifier?: string | null;
  name?: string | null;
  productType?: LolaBffApiContractsModelsLoanLoanType;
  borrower?: LolaBffApiContractsModelsLoanBorrower;
  createdBy?: LolaBffApiContractsModelsUser;
  broker?: LolaBffApiContractsModelsUser;
  processors?: LolaBffApiContractsModelsUser[] | null;
  status?: LolaBffApiContractsModelsLoanLoanStatus;
  currentMilestone?: LolaBffApiContractsModelsLoanLoanMilestone;
  stages?: LolaBffApiContractsModelsLoanStagesLoanStageItem[] | null;
};
export type LolaBffApiContractsModelsLoanResponseActiveLoansResponse = {
  buildMyLoan?: LolaBffApiContractsModelsLoanActiveLoan[] | null;
  approveMyLoan?: LolaBffApiContractsModelsLoanActiveLoan[] | null;
  closeMyLoan?: LolaBffApiContractsModelsLoanActiveLoan[] | null;
};
export type LolaBffApiContractsModelsLoanLoanActivityStatus =
  | 'NotSet'
  | 'Closed'
  | 'Archived';
export type LolaBffApiContractsModelsLoanInactiveLoan = {
  createdBy?: string | null;
  stages?: string | null;
  notifications?: number;
  id?: string | null;
  loanIdentifier?: string | null;
  name?: string | null;
  productType?: LolaBffApiContractsModelsLoanLoanType;
  borrower?: LolaBffApiContractsModelsLoanBorrower;
  broker?: LolaBffApiContractsModelsUser;
  processors?: LolaBffApiContractsModelsUser[] | null;
  currentMilestone?: LolaBffApiContractsModelsLoanLoanMilestone;
  createdAt?: string | null;
  lastUpdateAt?: string | null;
  status?: LolaBffApiContractsModelsLoanLoanActivityStatus;
  archivedLoan?: boolean;
};
export type LolaBffApiContractsModelsLoanResponseInactiveLoansResponse = {
  inactiveLoans?: LolaBffApiContractsModelsLoanInactiveLoan[] | null;
  totalInactiveLoans?: number;
  from?: number;
  to?: number;
};
export type LolaBffApiContractsModelsLoanRequestInactiveLoanFilters = {
  nameFilter?: string | null;
  statusFilter?: string | null;
  beginDateFilter?: string;
  endDateFilter?: string;
};
export type LolaBffApiContractsModelsLoanRequestInactiveLoanFiltersRequest = {
  inactiveLoanFilters?: LolaBffApiContractsModelsLoanRequestInactiveLoanFilters;
  userName?: string | null;
  page?: number;
};
export type LolaBffApiContractsModelsLoanResponseLoanControlPanelResponse = {
  stepName?: string | null;
  isDisabled?: boolean;
  steps?: LolaBffApiContractsModelsStepperOverviewProgressResponse[] | null;
};
export type LolaBffApiContractsModelsLoanAssignLoansToProcessorRequest = {
  loanIds?: string[] | null;
  processorUserId?: string | null;
};
export type MicrosoftAspNetCoreMvcIActionResult = object;
export type LolaBffApiContractsModelsLoanResponseInactiveLoanResponse = {
  id?: string | null;
  loanName?: string | null;
  loanIdentifier?: string | null;
  milestone?: LolaBffApiContractsModelsLoanLoanMilestone;
};
export type LolaBffApiContractsModelsLoanRequestLoanChangeRequest = {
  reason?: string | null;
  description?: string | null;
};
export type LolaBffApiContractsModelsLoanStagesTodoActionType =
  | 'NotSet'
  | 'Upload'
  | 'Review'
  | 'Custom';
export type LolaBffApiContractsModelsLoanStagesTodoActionParamType =
  | 'NotSet'
  | 'DriverLicense'
  | 'BankStatement'
  | 'PropertyReport'
  | 'ConstructionBudget'
  | 'Other';
export type LolaBffApiContractsModelsLoanLoanTodo = {
  id?: number;
  title?: string | null;
  borrowerName?: string | null;
  borrowerId?: number;
  loanId?: string | null;
  loanName?: string | null;
  stageId?: number;
  stageName?: string | null;
  stageCurrentStepNumber?: number;
  stageCurrentStepName?: string | null;
  totalStageSteps?: number;
  stageStatus?: LolaBffApiContractsModelsLoanStagesStageStatus;
  createdAt?: string;
  dueDate?: string | null;
  actionType?: LolaBffApiContractsModelsLoanStagesTodoActionType;
  actionParamType?: LolaBffApiContractsModelsLoanStagesTodoActionParamType;
};
export type LolaBffApiContractsModelsLolaTaskStatusLolaTaskStatusDto = {
  entity1Type?: string | null;
  entity1Id?: string | null;
  entity2Type?: string | null;
  entity2Id?: string | null;
  tenantId?: string | null;
  taskType?: string | null;
  completionStatus?: string | null;
  displayStatus?: string | null;
  metadata?: string | null;
  createdAt?: string;
  updatedAt?: string | null;
};
export type LolaBffApiContractsModelsMeridianResponseCraResponse = {
  name?: string | null;
  code?: string | null;
  url?: string | null;
  mlcid?: string | null;
};
export type LolaBffApiContractsModelsMeridianResponseMeridianReissueCreditResponse =
  {
    score?: string | null;
    error?: string | null;
    tryMergeFound?: boolean;
  };
export type LolaBffMeridianApiClientModelsRequestMeridianCraRequest = {
  name: string;
  code: string;
  url: string;
  mlcid: string;
};
export type LolaBffMeridianApiClientModelsRequestReissueCreditRequest = {
  loanId: string;
  partyId: string;
  firstName: string;
  lastName: string;
  ssn: string;
  vendorOrderIdentifier: string;
  meridianCra: LolaBffMeridianApiClientModelsRequestMeridianCraRequest;
};
export type LolaBffApiContractsModelsPartyUpdateIndividualPartyRequest = {
  partyMilestone?: LolaBffApiContractsModelsPartyPartyMilestone;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  citizenshipCountry?: string | null;
  usCitizenshipIndicator?: string | null;
  contactPointRoleType?: string | null;
  contactPointTelephoneValue?: string | null;
  contactPointEmailValue?: string | null;
  paymentTokenId?: string | null;
  equifaxScore?: string | null;
  transUnionScore?: string | null;
  experianScore?: string | null;
  creditReportNotes?: string | null;
  estimatedCreditScore?: string | null;
  creditReportDate?: string | null;
  creditReportPullType?: string | null;
  fixNFlipNBridgePlusTier?: string | null;
  locStatusType?: string | null;
  totalApprovedLoc?: string | null;
  unpaidPrincipalBalance?: string | null;
  loanAmountInProcess?: string | null;
  remainingLocAvailable?: string | null;
  locExpirationDate?: string | null;
  numberOfTransactions?: string | null;
  transactionsCumulativeSalePrice?: string | null;
  transactionsAverageProjectDuration?: string | null;
  transactionsAverageSalePrice?: string | null;
  transactionsInLast12Months?: string | null;
  numberOfFlips?: string | null;
  flipsCumulativeSalePrice?: string | null;
  flipsAverageProjectDuration?: string | null;
  flipsAverageSalePrice?: string | null;
  flipsInLast12Months?: string | null;
  numberOfNewBuilds?: string | null;
  newBuildsCumulativeSalePrice?: string | null;
  newBuildsAverageProjectDuration?: string | null;
  newBuildsAverageSalePrice?: string | null;
  newBuildsInLast12Months?: string | null;
  experienceNotes?: string | null;
  backgroundReportDate?: string | null;
  governmentIssuedIdExpirationDate?: string | null;
  individualBackgroundReportExpirationDate?: string | null;
  convictionIndicator?: string | null;
  outstandingLawsuitsIndicator?: string | null;
  outstandingJudgmentsIndicator?: string | null;
  bankruptcyIndicator?: string | null;
  foreclosureIndicator?: string | null;
  mortgageDelinquentIndicator?: string | null;
  taxpayerIdentifierType?: string | null;
  taxpayerIdentifierValue?: string | null;
  liquidAssetTotalAmount?: string | null;
  address?: LolaBffApiContractsModelsLoanAddress;
  crmId?: string | null;
  newConstructionEligibilityIndicator?: string | null;
  operationsDepartment?: string | null;
  lola?: any | null;
};
export type LolaBffApiContractsModelsPartyUpdateEntityPartyRequest = {
  entityType?: string | null;
  fullName?: string | null;
  ownershipPercent?: string | null;
  businessIncorporationStateName?: string | null;
  businessIncorporationStateCode?: string | null;
  businessRegistrationStates?: string[] | null;
  fixAndFlipBridgePlusTier?: string | null;
  remainingLineOfCreditAmount?: string | null;
  notes?: string | null;
  realEstateOperationType?: string[] | null;
  totalFixAndFlipNewConstructionSoldPastThreeYearsCount?: string | null;
  partyMilestone?: string | null;
  representativeCreditScore?: string | null;
  totalOutstandingLongTermLoanCount?: string | null;
  totalOutstandingLongTermPrincipalAmount?: string | null;
  taxpayerIdentifierType?: string | null;
  taxpayerIdentifierValue?: string | null;
  liquidAssetTotalAmount?: string | null;
  address?: LolaBffApiContractsModelsLoanAddress;
  crmId?: string | null;
  newConstructionEligibilityIndicator?: string | null;
  operationsDepartment?: string | null;
  lola?: any | null;
};
export type LolaBffApiContractsModelsPaymentPaymentIntentResponse = {
  clientSecret?: string | null;
  publicKey?: string | null;
};
export type LolaBffApiContractsModelsPaymentCreatePaymentIntentRequest = {
  productCode?: string | null;
  partyIds?: string[] | null;
  dealId?: string | null;
};
export type LolaBffApiContractsModelsPaymentCreatePaymentLinkRequest = {
  productCode?: string | null;
  partyIds?: string[] | null;
  dealId?: string | null;
};
export type LolaBffApiContractsModelsUserInfo = {
  userId?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  role?: LolaBffApiContractsModelsUserRole;
};
export type LolaBffApiContractsModelsPricingEngineResponseQuoteSummaryResponse =
  {
    portalUser?: LolaBffApiContractsModelsUserInfo;
    name?: string | null;
    isActive?: boolean;
    quoteType?: string | null;
    createdOn?: string | null;
    modifiedOn?: string | null;
    id?: string | null;
    productType?: string | null;
    username?: string | null;
    underwriterStatus?: string | null;
    isSubmittedInLoan?: boolean;
    isAttachedToLoan?: boolean;
    isQuoteStaged?: boolean;
    loanId?: string | null;
    loanMilestone?: string | null;
    customerProductType?: string | null;
  };
export type LolaBffApiContractsModelsPricingEngineResponseQuoteInfo = {
  id?: number;
  program?: string | null;
  product?: string | null;
  status?: number;
  isSubmittedInLoan?: boolean;
  isQuoteStaged?: boolean;
  loanMilestone?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestPremier = {
  propertyManagementFee?: number;
  collateralCondos?: string | null;
  collateral?: string | null;
  openPrepayOption?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestBase = {
  condo?: string | null;
  fixToRent?: string | null;
  propertyVal?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestScenario = {
  scenarioId?: number;
  ltvPercentage?: number;
  included?: string | null;
  preferred?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestUnderwriterFields = {
  legalFee?: string | null;
  taxImpound?: string | null;
  insuranceImpound?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestLenderFinance = {
  interestRateAddOn?: string | null;
  originationPointsAddOn?: string | null;
  applicationFee?: string | null;
  documentFee?: string | null;
  processingFee?: string | null;
  lenderFinanceAppraisalFee?: string | null;
  rpolt6?: string | null;
  rpogt6?: string | null;
  purchase?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestExceptionPricingOverride =
  {
    commissionReduction?: string | null;
    autoRate?: number | null;
    autoFee?: number | null;
  };
export type LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest = {
  productType?: string | null;
  submissionType?: string | null;
  borrowingEntity?: string | null;
  borrowerNetWorth?: string | null;
  creditScore?: number | null;
  sreoPropertyNumber?: number | null;
  numOfProperties?: number | null;
  totalCosts1?: number | null;
  appraisedVal1?: number | null;
  appraisedVal3?: string | null;
  purchasePrice?: string | null;
  mortgagePayoffs?: string | null;
  monthlyRent?: string | null;
  annualTaxes?: string | null;
  annualInsurance?: number | null;
  annualFloodInsurance?: number | null;
  annualHOA?: number | null;
  propertiesNY?: string | null;
  borrowerIsReferral?: string | null;
  yieldSpread?: number | null;
  refiL1cFixNFlip?: string | null;
  buyUpDownOption?: string | null;
  buyUpDownPercentage?: number | null;
  isTier5Borrower?: string | null;
  prepaymentPenalty?: string | null;
  brokerFee?: number | null;
  brokerProcessingFee?: string | null;
  appraisalFee?: number | null;
  usCitizen?: string | null;
  isZeroOrigFee?: string | null;
  seasonalRental?: string | null;
  fiveNineUnit?: string | null;
  zip?: string | null;
  propertyAddress?: string | null;
  city?: string | null;
  state?: string | null;
  premier?: LolaBffApiContractsModelsPricingEngineRequestPremier;
  base?: LolaBffApiContractsModelsPricingEngineRequestBase;
  scenario?: LolaBffApiContractsModelsPricingEngineRequestScenario[] | null;
  quoteType?: string | null;
  quoteName?: string | null;
  sfAccountId?: string | null;
  sfDealId?: string | null;
  overrideMaster?: boolean;
  hasSubmittedQuote?: boolean | null;
  milestone?: string | null;
  underwriterFields?: LolaBffApiContractsModelsPricingEngineRequestUnderwriterFields;
  lenderFinance?: LolaBffApiContractsModelsPricingEngineRequestLenderFinance;
  exceptionPricingOverride?: LolaBffApiContractsModelsPricingEngineRequestExceptionPricingOverride;
};
export type LolaBffApiContractsModelsPricingEngineResponsePremierResponse = {
  index?: string | null;
  indexRate?: number | null;
  dateRate?: string;
  openPrepay?: number | null;
  interestRateName?: string | null;
  interestRateValue?: number;
  spread?: number | null;
};
export type LolaBffApiContractsModelsPricingEngineResponseScenarioResponse = {
  eocTitle?: string | null;
  ltvEligibility?: string | null;
  margin?: number;
  borrower?: string | null;
  estimatedValOfProperty?: number;
  exitFee?: string | null;
  feeLLPAs?: string | null;
  numOfProperties?: number | null;
  openPrepayOption?: string | null;
  estimatedLoanToVal?: number;
  borrowerCreditScore?: number;
  loanAmt?: number;
  payoffMortgageProperties?: number;
  purchasePriceProperties?: number;
  estimatedClosingCost?: number;
  originationFee?: number;
  originationFeePercent?: string | null;
  buyUpDownNotOpted?: string | null;
  processingFee?: number;
  legalFee?: string | null;
  lenderFinanceApplicationFee?: number;
  lenderFinanceAppraisalFee?: number;
  lenderFinanceDocumentFee?: number;
  lenderFinanceFrontEndYieldSpreadPremium?: number;
  lenderFinanceOriginationPoints?: number;
  lenderFinanceProcessingFee?: number;
  totalLenderFinanceFees?: number;
  limaOriginationFeeToBorrower?: number;
  limaRateToBorrower?: number;
  brokerFee?: number;
  brokerProcessingFeeCalc?: number;
  insuranceCertifications?: number;
  taxImpound?: number;
  insuranceImpound?: number;
  interestReserveEscrow?: number | null;
  closingCashToBorrower?: number;
  qualifyingInterestRate?: number;
  rateBuyUpDown?: number;
  rateLLPAs?: string | null;
  prepaymentPenalty?: number;
  initFixedInterestRate?: number | null;
  amortizationPeriod?: string | null;
  monthlyPrincipalInterest?: number;
  monthlyTaxesInsurance?: number;
  monthlyHOADues?: number;
  monthlyTotalPayment?: number;
  monthlyEstimatedRentalIncome?: number;
  rentDebtRatio?: number;
  capStructure?: string | null;
  lifetimeCap?: number | null;
  floor?: number | null;
  lookbackPeriod?: string | null;
  prepaymentPenaltyText?: string | null;
  guarantyType?: string | null;
  liquidReserves?: number;
  premier?: LolaBffApiContractsModelsPricingEngineResponsePremierResponse;
  buyUpDownFee?: number;
  pricingUpdateTimestamp?: string | null;
  warnings?: string | null;
  updatedAt?: string | null;
  overridePricing?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineResponseRentalQuoteResponse =
  {
    feeLLPAs?: string | null;
    rateLLPAs?: string | null;
    originationFeePercent?: number;
    overridePricing?: string | null;
    scenarios?:
      | LolaBffApiContractsModelsPricingEngineResponseScenarioResponse[]
      | null;
    id?: string | null;
    scenarioId?: string | null;
    workbookId?: string | null;
    validQuote?: boolean;
    variantId?: string | null;
    lastUpdated?: string | null;
    validationErrors?: string | null;
    termSheetTitle?: string | null;
  };
export type LolaBffApiContractsModelsPricingEngineResponseRentalQuoteByIdResponse =
  {
    quoteInfo?: LolaBffApiContractsModelsPricingEngineResponseQuoteInfo;
    in?: LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest;
    out?: LolaBffApiContractsModelsPricingEngineResponseRentalQuoteResponse;
  };
export type LolaBffApiContractsModelsPricingEngineResponseCalculateAndUpdateRentalQuoteResponse =
  {
    in?: LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest;
    out?: LolaBffApiContractsModelsPricingEngineResponseRentalQuoteResponse;
  };
export type LolaBffApiContractsModelsPricingEngineRequestUpdateRentalQuoteRequest =
  {
    productType?: string | null;
    submissionType?: string | null;
    borrowingEntity?: string | null;
    borrowerNetWorth?: string | null;
    creditScore?: number | null;
    sreoPropertyNumber?: number | null;
    numOfProperties?: number | null;
    totalCosts1?: number | null;
    appraisedVal1?: number | null;
    appraisedVal3?: string | null;
    purchasePrice?: string | null;
    mortgagePayoffs?: string | null;
    monthlyRent?: string | null;
    annualTaxes?: string | null;
    annualInsurance?: number | null;
    annualFloodInsurance?: number | null;
    annualHOA?: number | null;
    propertiesNY?: string | null;
    borrowerIsReferral?: string | null;
    yieldSpread?: number | null;
    refiL1cFixNFlip?: string | null;
    buyUpDownOption?: string | null;
    buyUpDownPercentage?: number | null;
    isTier5Borrower?: string | null;
    prepaymentPenalty?: string | null;
    brokerFee?: number | null;
    brokerProcessingFee?: string | null;
    appraisalFee?: number | null;
    usCitizen?: string | null;
    isZeroOrigFee?: string | null;
    seasonalRental?: string | null;
    fiveNineUnit?: string | null;
    zip?: string | null;
    propertyAddress?: string | null;
    city?: string | null;
    state?: string | null;
    premier?: LolaBffApiContractsModelsPricingEngineRequestPremier;
    base?: LolaBffApiContractsModelsPricingEngineRequestBase;
    scenario?: LolaBffApiContractsModelsPricingEngineRequestScenario[] | null;
    quoteType?: string | null;
    quoteName?: string | null;
    sfAccountId?: string | null;
    sfDealId?: string | null;
    overrideMaster?: boolean;
    hasSubmittedQuote?: boolean | null;
    milestone?: string | null;
    underwriterFields?: LolaBffApiContractsModelsPricingEngineRequestUnderwriterFields;
    lenderFinance?: LolaBffApiContractsModelsPricingEngineRequestLenderFinance;
    exceptionPricingOverride?: LolaBffApiContractsModelsPricingEngineRequestExceptionPricingOverride;
  };
export type LolaBffApiContractsModelsPricingEngineRequestLoanSummary = {
  loanPurpose?: string | null;
  loanProduct?: string | null;
  propertyAddress?: string | null;
  city?: string | null;
  formattedAddress?: string | null;
  state?: string | null;
  zip?: string | null;
  propertyType?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestLoanTermRequest = {
  channel?: string | null;
  referralFee?: string | null;
  brokerFee?: number | null;
  loanTerm?: string | null;
  guaranty?: string | null;
  appraisalFee?: number | null;
  brokerProcessingFee?: number | null;
  projectInspectionFee?: number | null;
  internalRefinance?: string | null;
  exitFeeCheck?: string | null;
  deferredOrigPercent?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestLeverageRequest = {
  adjustedBlendedLTC?: number | null;
  adjustedPurchaseLTC?: number | null;
  adjustedARV?: number | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestDealTerms = {
  targetCloseDate?: string | null;
  timeCloseToPayoff?: number | null;
  lesserOfLotOrPurchase?: string | null;
  numOfProperties?: number | null;
  rehabConBudget?: string | null;
  afterCompletedVal?: string | null;
  refinance?: string | null;
  originalPurchaseDate?: string | null;
  existingDebtStatus?: string | null;
  payoff?: number | null;
  cashOut?: string | null;
  heavyRehab?: string | null;
  buyUpPercentage?: number | null;
  buyDownPercentage?: number | null;
  isZeroOrigFee?: string | null;
  totalPurchasePrice?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestBorrowerInfo = {
  primaryGuarantor?: string | null;
  secondaryGuarantor?: string | null;
  borrowingEntity?: string | null;
  fnfCompletedInThreeYears?: number | null;
  buildsCompletedInThreeYears?: number | null;
  completedTransactions?: number | null;
  qualifyingFICO?: number | null;
  combinedLiquidity?: string | null;
  borrowerTier?: string | null;
  usCitizen?: string | null;
  altTier?: string | null;
  borrowerIsReferral?: string | null;
  minimumFlipsAndLOC?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest =
  {
    loanSummary?: LolaBffApiContractsModelsPricingEngineRequestLoanSummary;
    loanTerm?: LolaBffApiContractsModelsPricingEngineRequestLoanTermRequest;
    leverage?: LolaBffApiContractsModelsPricingEngineRequestLeverageRequest;
    dealTerms?: LolaBffApiContractsModelsPricingEngineRequestDealTerms;
    borrowerInfo?: LolaBffApiContractsModelsPricingEngineRequestBorrowerInfo;
    quoteType?: string | null;
    quoteName?: string | null;
    sfAccountId?: string | null;
    sfDealId?: string | null;
    overrideMaster?: boolean;
    hasSubmittedQuote?: boolean | null;
    milestone?: string | null;
    underwriterFields?: LolaBffApiContractsModelsPricingEngineRequestUnderwriterFields;
    lenderFinance?: LolaBffApiContractsModelsPricingEngineRequestLenderFinance;
    exceptionPricingOverride?: LolaBffApiContractsModelsPricingEngineRequestExceptionPricingOverride;
  };
export type LolaBffApiContractsModelsPricingEngineResponseBorrowerInfo = {
  borrowerTier?: string | null;
};
export type LolaBffApiContractsModelsPricingEngineResponseBlendedLtcCalc = {
  maxBlendedLTCRatio?: number;
  totalCost?: number;
  blendedLTCMaxLoan?: number;
};
export type LolaBffApiContractsModelsPricingEngineResponseLtcCalcOnPurchaseRehab =
  {
    maxLTCOnPurchase?: number;
    initFundedLoanAmt?: number;
    ltcConBudget?: number;
    loanAmt?: number;
    effectiveBlendedLTC?: number;
    ltcCalcRehabMaxLoan?: number;
  };
export type LolaBffApiContractsModelsPricingEngineResponseLtvarvCalc = {
  afterCompletedVal?: number;
  maxLTVRatio?: number;
  ltvMaxLoan?: number;
  totalLoanAmt?: number;
};
export type LolaBffApiContractsModelsPricingEngineResponseCashToClose = {
  purchase?: number;
  existingPayoff?: number;
  conBudget?: number;
  totalLoanFeesCost?: number;
  estimatedLoanAmt?: number;
  estimatedCashAtClose?: number;
};
export type LolaBffApiContractsModelsPricingEngineResponseLoanCosts = {
  brokerFee?: number;
  appraisalFee?: number;
  brokerProcessingFee?: number;
  interestReserve?: number;
  originationFee?: number;
  processingFee?: number;
  projectInspectionFee?: number;
  servicingSetupFee?: number;
  totalLoanCostFees?: number;
  brokerProcessingFeeCalc?: number;
};
export type LolaBffApiContractsModelsPricingEngineResponseRateFeeLlpa = {
  baseRate?: number;
  totalRateLLPA?: number;
  finalRate?: number;
  baseOrigFee?: number;
  totalOrigFeeLLPA?: number;
  referralFee?: number;
  finalOrigFee?: number;
  deferredOriginationFee?: number;
};
export type LolaBffApiContractsModelsPricingEngineResponsePayments = {
  initMonthlyPayment?: number;
  paymentFullyDrawn?: number;
};
export type LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteResponse =
  {
    borrowerInfo?: LolaBffApiContractsModelsPricingEngineResponseBorrowerInfo;
    blendedLTCCalc?: LolaBffApiContractsModelsPricingEngineResponseBlendedLtcCalc;
    ltcCalcOnPurchaseRehab?: LolaBffApiContractsModelsPricingEngineResponseLtcCalcOnPurchaseRehab;
    ltvarvCalc?: LolaBffApiContractsModelsPricingEngineResponseLtvarvCalc;
    cashToClose?: LolaBffApiContractsModelsPricingEngineResponseCashToClose;
    loanCosts?: LolaBffApiContractsModelsPricingEngineResponseLoanCosts;
    rateFeeLLPA?: LolaBffApiContractsModelsPricingEngineResponseRateFeeLlpa;
    payments?: LolaBffApiContractsModelsPricingEngineResponsePayments;
    internalValuation?: string | null;
    legalFee?: number;
    lenderFinanceFrontEndYieldSpreadPremium?: number;
    lenderFinanceOriginationPoints?: number;
    lenderFinanceApplicationFee?: number;
    lenderFinanceDocumentFee?: number;
    lenderFinanceProcessingFee?: number;
    lenderFinanceAppraisalFee?: number;
    totalLenderFinanceFees?: number;
    limaRateToBorrower?: number;
    limaOriginationFeeToBorrower?: number;
    exitFee?: string | null;
    blendedLoanToCost?: number;
    actualCashOut?: string | null;
    pricingUpdateTimestamp?: string | null;
    propertyValueBasisLTC?: number;
    rehabBudgetLTC?: number;
    currentProjectedLTVLTARV?: number;
    feeLLPAsFactored?: string | null;
    rateLLPAsFactored?: string | null;
    warnings?: string | null;
    overridePricing?: string | null;
    servicingSetupFee?: number | null;
    id?: string | null;
    scenarioId?: string | null;
    workbookId?: string | null;
    validQuote?: boolean;
    variantId?: string | null;
    lastUpdated?: string | null;
    validationErrors?: string | null;
    termSheetTitle?: string | null;
  };
export type LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteByIdResponse =
  {
    quoteInfo?: LolaBffApiContractsModelsPricingEngineResponseQuoteInfo;
    in?: LolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest;
    out?: LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteResponse;
  };
export type LolaBffApiContractsModelsPricingEngineResponseCalculateAndUpdateShortTermFinanceQuoteResponse =
  {
    in?: LolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest;
    out?: LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteResponse;
  };
export type LolaBffApiContractsModelsPricingEngineRequestUpdateShortTermFinanceQuoteRequest =
  {
    loanSummary?: LolaBffApiContractsModelsPricingEngineRequestLoanSummary;
    loanTerm?: LolaBffApiContractsModelsPricingEngineRequestLoanTermRequest;
    leverage?: LolaBffApiContractsModelsPricingEngineRequestLeverageRequest;
    dealTerms?: LolaBffApiContractsModelsPricingEngineRequestDealTerms;
    borrowerInfo?: LolaBffApiContractsModelsPricingEngineRequestBorrowerInfo;
    quoteType?: string | null;
    quoteName?: string | null;
    sfAccountId?: string | null;
    sfDealId?: string | null;
    overrideMaster?: boolean;
    hasSubmittedQuote?: boolean | null;
    milestone?: string | null;
    underwriterFields?: LolaBffApiContractsModelsPricingEngineRequestUnderwriterFields;
    lenderFinance?: LolaBffApiContractsModelsPricingEngineRequestLenderFinance;
    exceptionPricingOverride?: LolaBffApiContractsModelsPricingEngineRequestExceptionPricingOverride;
  };
export type LolaBffApiContractsModelsPricingEngineResponseCalculateShortTermFinanceQuoteResponse =
  {
    out?: LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteResponse;
  };
export type LolaBffApiContractsModelsPricingEngineResponseCalculateRentalQuoteResponse =
  {
    out?: LolaBffApiContractsModelsPricingEngineResponseRentalQuoteResponse;
  };
export type LolaBffApiContractsModelsPricingEngineRequestSaveRentalQuoteRequest =
  {
    in?: LolaBffApiContractsModelsPricingEngineRequestRentalQuoteRequest;
    out?: LolaBffApiContractsModelsPricingEngineResponseRentalQuoteResponse;
  };
export type LolaBffApiContractsModelsPricingEngineRequestSaveShortTermFinanceQuoteRequest =
  {
    in?: LolaBffApiContractsModelsPricingEngineRequestShortTermFinanceQuoteRequest;
    out?: LolaBffApiContractsModelsPricingEngineResponseShortTermFinanceQuoteResponse;
  };
export type LolaBffApiContractsModelsPricingEngineRequestSendQuoteRequest = {
  loanId?: string | null;
};
export type LolaBffApiContractsModelsPropertyPropertyAggregations = {
  cityInitialTaxEscrowMonthCount?: LolaBffApiContractsModelsLoanAggregationInfo;
  cityInitialTaxEscrowAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  countyInitialTaxEscrowMonthCount?: LolaBffApiContractsModelsLoanAggregationInfo;
  countyInitialTaxEscrowAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  initialPropertyInsuranceEscrowMonthCount?: LolaBffApiContractsModelsLoanAggregationInfo;
  initialPropertyInsuranceEscrowAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  initialFloodInsuranceEscrowAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  initialFloodInsuranceEscrowMonthCount?: LolaBffApiContractsModelsLoanAggregationInfo;
  specialAssessmentInitialTaxEscrowAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  specialAssessmentInitialTaxEscrowMonthCount?: LolaBffApiContractsModelsLoanAggregationInfo;
  monthlyLeaseRentAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalInsuranceEscrowAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  totalTaxEscrowAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  monthlyMarketRentAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  adjustedRentAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  annualTaxAmount?: LolaBffApiContractsModelsLoanAggregationInfo;
  ltvRatePercent?: LolaBffApiContractsModelsLoanAggregationInfo;
};
export type LolaBffApiContractsModelsPropertyFloodCertification = {
  floodCertificationActionType?: string | null;
  floodInsuranceRequiredStatusType?: string | null;
  communityNumber?: string | null;
  floodCertificationIdentifier?: string | null;
  mapPanelIdentifier?: string | null;
  mapDate?: string | null;
  originalDeterminationDate?: string | null;
  floodZoneDesignationType?: string | null;
  specialFloodHazardAreaIndicator?: string | null;
  lifeOfLoanIndicator?: string | null;
  floodCheckIdentifier?: string | null;
  floodProductCertifyDate?: string | null;
  floodCheckDescription?: string | null;
  floodCheckInformation?: string | null;
};
export type LolaBffApiContractsModelsPropertySheetMetadata = {
  type?: string | null;
};
export type LolaBffApiContractsModelsPropertyPropertyExceptions = {
  appraisal?: LolaBffApiContractsModelsLoanDealExceptionCase;
  ltvLoanAmount?: LolaBffApiContractsModelsLoanDealExceptionCase;
  adjustedRentAmount?: LolaBffApiContractsModelsLoanDealExceptionCase;
  capitalExpenditureAmount?: LolaBffApiContractsModelsLoanDealExceptionCase;
  ltarvRatePercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
  blendedLtcRatePercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
  blendedLtcLoanAmount?: LolaBffApiContractsModelsLoanDealExceptionCase;
  ltvRatePercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
  constructionLtcRatePercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
  maxConstructionBudgetLtcPercent?: LolaBffApiContractsModelsLoanDealExceptionCase;
};
export type LolaBffApiContractsModelsPropertyPropertyInspection = {
  inspectionInitialRequestDate?: string | null;
  inspectionRequestOrderedDate?: string | null;
  inspectionReportDueDate?: string | null;
  inspectionOrderType?: string | null;
  inspectorFullName?: string | null;
  constructionAnalystFullName?: string | null;
  inspectionOrderReceivedDate?: string | null;
  inspectionOrderProcessedDate?: string | null;
  inspectionDocumentsReceivedDate?: string | null;
  inspectionInitialDelayReasonStatusType?: string[] | null;
};
export type LolaBffApiContractsModelsPropertyUpdatePropertyRequest = {
  aggregations?: LolaBffApiContractsModelsPropertyPropertyAggregations;
  address?: LolaBffApiContractsModelsLoanAddress;
  appraisal?: LolaBffApiContractsModelsPropertyResultAppraisal;
  floodCertification?: LolaBffApiContractsModelsPropertyFloodCertification;
  floodInsuranceAgency?: LolaBffApiContractsModelsPropertyInsuranceAgency;
  floodInsurance?: LolaBffApiContractsModelsPropertyFloodInsurance;
  propertyInsuranceAgency?: LolaBffApiContractsModelsPropertyInsuranceAgency;
  propertyInsurance?: LolaBffApiContractsModelsPropertyPropertyInsurance;
  propertyType?: string | null;
  condoWarrantableIndicator?: string | null;
  financedUnitCount?: string | null;
  propertyLoanPurpose?: string | null;
  purchasePriceAmount?: string | null;
  contractClosingDate?: string | null;
  contractExecutionDate?: string | null;
  originalPurchaseDate?: string | null;
  originalPurchasePriceAmount?: string | null;
  outstandingLoanPayoffAmount?: string | null;
  allocatedLoanAmount?: string | null;
  ltcRatePercent?: string | null;
  leaseStatusType?: string | null;
  annualTaxesAmount?: string | null;
  annualHomeownersAssociationFeeAmount?: string | null;
  deferredMaintenanceAmount?: string | null;
  parcelNumber?: string | null;
  renovationCostAmount?: string | null;
  constructionCostAmount?: string | null;
  constructionLtcRatePercent?: string | null;
  constructionLtcLoanAmount?: string | null;
  blendedLtcRatePercent?: string | null;
  blendedLtcLoanAmount?: string | null;
  ltarvRatePercent?: string | null;
  ltarvAmount?: string | null;
  ltvLoanAmount?: string | null;
  totalCostAmount?: string | null;
  netFundAmount?: string | null;
  netFundRatePercent?: string | null;
  netWireAmount?: string | null;
  settlementStatementAmount?: string | null;
  allocatedRdsrRatePercent?: string | null;
  leasedFinancedUnits?: LolaBffApiContractsModelsPropertyLeasedFinancedUnits;
  sheetMetadata?: LolaBffApiContractsModelsPropertySheetMetadata;
  exceptions?: LolaBffApiContractsModelsPropertyPropertyExceptions;
  appraisalManagementCompany?: LolaBffApiContractsModelsPropertyAppraisalManagementCompany;
  condoCertificateFeesCollectedIndicator?: string | null;
  condoCertificateComments?: string | null;
  constructionCostComments?: string | null;
  renovationCostComments?: string | null;
  propertyDetailComments?: string | null;
  psaSignedIndicator?: string | null;
  psaExtensionRequestedIndicator?: string | null;
  psaExtensionFormIndicator?: string | null;
  psaAssignmentContractIndicator?: string | null;
  psaAddendumIndicator?: string | null;
  psaSellerOwnerRecordMismatchIndicator?: string | null;
  psaBuyerBorrowerMismatchIndicator?: string | null;
  psaExpirationDate?: string | null;
  payoffExpirationDate?: string | null;
  mortgageeFullName?: string | null;
  mortgageeContactFullName?: string | null;
  mortgageeContactPointTelephoneValue?: string | null;
  mortgageeContactPointEmailValue?: string | null;
  cityAnnualTaxAmount?: string | null;
  cityTaxDueDate?: string | null;
  cityTaxBillingFrequencyType?: string | null;
  cityTaxingAuthority?: string | null;
  countyAnnualTaxAmount?: string | null;
  countyTaxDueDate?: string | null;
  countyTaxBillingFrequencyType?: string | null;
  countyTaxingAuthority?: string | null;
  specialAssessmentAnnualTaxAmount?: string | null;
  specialAssessmentTaxDueDate?: string | null;
  specialAssessmentTaxBillingFrequencyType?: string | null;
  specialAssessmentTaxingAuthority?: string | null;
  propertyInspection?: LolaBffApiContractsModelsPropertyPropertyInspection;
  cemaRefinanceIndicator?: string | null;
  propertyRightsOwnershipType?: string | null;
  rentReportConfidenceScorePercent?: string | null;
  totalRentalIncomeAmount?: string | null;
  capitalExpenditureAmount?: string | null;
  maxConstructionBudgetLtcPercent?: string | null;
  borrowerRenovationCostsAfterPurchaseAmount?: string | null;
  subjectToPropertyValuationAmount?: string | null;
  lola?: any | null;
};
export type LolaBffApiContractsModelsRoleRole = {
  name?: string | null;
  description?: string | null;
};
export type LolaBffApiContractsModelsSettingsLoanProductDto = {
  id?: string | null;
  name?: string | null;
};
export type LolaBffApiContractsModelsSettingsLoanProgramDto = {
  id?: string | null;
  name?: string | null;
  products?: LolaBffApiContractsModelsSettingsLoanProductDto[] | null;
};
export type LolaBffApiContractsModelsSettingsLoanProgramsResponse = {
  programs?: LolaBffApiContractsModelsSettingsLoanProgramDto[] | null;
};
export type LolaBffApiContractsModelsSettingsTaskMappingInfo = {
  taskConfigurationId?: string | null;
  taskName?: string | null;
  entityType?: string | null;
};
export type LolaBffApiContractsModelsSettingsTaskConfiguration = {
  taskMapping?: {
    [key: string]: LolaBffApiContractsModelsSettingsTaskMappingInfo;
  } | null;
};
export type LolaBffApiContractsModelsSimpleUserInfo = {
  userId?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
};
export type LolaBffApiContractsModelsTeam = {
  id?: number;
  manager?: LolaBffApiContractsModelsSimpleUserInfo;
  createdAt?: string;
};
export type LolaBffApiContractsModelsRequestsTeamAction =
  | 'Create'
  | 'Change'
  | 'Add'
  | 'Remove';
export type LolaBffApiContractsModelsRequestsManageTeamRequest = {
  sourceTeamId?: number | null;
  destinationTeamId?: number | null;
  userId?: string | null;
  previousUserId?: string | null;
  action: LolaBffApiContractsModelsRequestsTeamAction;
};
export type LolaBffApiContractsModelsTaskDocumentTemplateTemplateInfo = {
  templateId?: string | null;
  file?: string | null;
  description?: string | null;
  condition?: any | null;
};
export type LolaBffApiContractsModelsTaskDocumentTemplateTaskInfo = {
  task?: string | null;
  taskConfigurationId?: string | null;
  entityType?: string | null;
  description?: string | null;
  templates?:
    | LolaBffApiContractsModelsTaskDocumentTemplateTemplateInfo[]
    | null;
};
export type LolaBffApiContractsModelsTaskDocumentTemplateTaskDocumentTemplatesConfiguration =
  {
    tasks?: LolaBffApiContractsModelsTaskDocumentTemplateTaskInfo[] | null;
  };
export type LolaBffApiContractsModelsLoanTemplateTemplateFileInfo = {
  fileName?: string | null;
  fileType?: string | null;
  fileSize?: string | null;
  description?: string | null;
  contentUrl?: string | null;
};
export type LolaBffApiContractsModelsTenantTenant = {
  id?: string | null;
  name?: string | null;
  lolaSubDomain?: string | null;
  companyShortName?: string | null;
  companyLongName?: string | null;
  themeData?: any | null;
  enabled?: boolean;
};
export type LolaBffApiContractsModelsUsersCreateAuth0UserRequest = {
  firstName: string;
  lastName: string;
  email: string;
  role?: LolaBffApiContractsModelsUserRole;
  teamId?: number | null;
  managerUserId?: string | null;
  isNewTeam?: boolean;
};
export type LolaBffApiContractsModelsUsersUpdateAuth0UserRequest = {
  firstName: string;
  lastName: string;
  userMetadata?: LolaBffApiContractsModelsUserMetadata;
};
export type MicrosoftAspNetCoreMvcFormattersIOutputFormatter = object;
export type MicrosoftAspNetCoreMvcNotFoundObjectResult = {
  value?: any | null;
  formatters?: MicrosoftAspNetCoreMvcFormattersIOutputFormatter[] | null;
  contentTypes?: string[] | null;
  declaredType?: string | null;
  statusCode?: number | null;
};
export const {
  useGetApiV1ActivityLogQuery,
  useLazyGetApiV1ActivityLogQuery,
  useGetApiV1ApplicationStageByLoanIdBuildMyLoanQuery,
  useLazyGetApiV1ApplicationStageByLoanIdBuildMyLoanQuery,
  useGetApiV1ApprovemyloanGetOverviewProgressQuery,
  useLazyGetApiV1ApprovemyloanGetOverviewProgressQuery,
  useGetApiV1ApprovemyloanGetReviewStatusQuery,
  useLazyGetApiV1ApprovemyloanGetReviewStatusQuery,
  useGetApiV1ApprovemyloanGetShowConditionsQuery,
  useLazyGetApiV1ApprovemyloanGetShowConditionsQuery,
  useGetApiV1ApprovemyloanGetActionsQuery,
  useLazyGetApiV1ApprovemyloanGetActionsQuery,
  useGetApiV1ApprovemyloanGetFinalUnderwritingQuery,
  useLazyGetApiV1ApprovemyloanGetFinalUnderwritingQuery,
  usePostApiV1ApprovemyloanBeginFinalUnderwritingMutation,
  useGetApiV1ApprovemyloanIsPricingAndTermsAcceptedQuery,
  useLazyGetApiV1ApprovemyloanIsPricingAndTermsAcceptedQuery,
  usePostApiV1ApprovemyloanAcceptFinalPricingAndTermsSheetMutation,
  usePostApiV1ApprovemyloanGetMyClearToCloseMutation,
  usePostApiV1AuthTokenMutation,
  useGetApiV1AuthLogoutQuery,
  useLazyGetApiV1AuthLogoutQuery,
  usePostApiV1AuthTokenRefreshMutation,
  usePostApiV1AuthChangePasswordMutation,
  usePostApiV1AuthResetPasswordSendMutation,
  usePostApiV1AuthResetPasswordConfirmMutation,
  useGetApiV1BorrowersSearchQuery,
  useLazyGetApiV1BorrowersSearchQuery,
  useGetApiV1BorrowersByPartyIdQuery,
  useLazyGetApiV1BorrowersByPartyIdQuery,
  useGetApiV1BorrowersGetByLoanidQuery,
  useLazyGetApiV1BorrowersGetByLoanidQuery,
  useGetApiV1ClosemyloanGetOverviewProgressQuery,
  useLazyGetApiV1ClosemyloanGetOverviewProgressQuery,
  useGetApiV1ClosemyloanGetSchedulingQuery,
  useLazyGetApiV1ClosemyloanGetSchedulingQuery,
  useGetApiV1ClosemyloanGetInClosingQuery,
  useLazyGetApiV1ClosemyloanGetInClosingQuery,
  useGetApiV1ClosemyloanFundingInformationQuery,
  useLazyGetApiV1ClosemyloanFundingInformationQuery,
  useGetApiV1ConditionGetConditionDetailsQuery,
  useLazyGetApiV1ConditionGetConditionDetailsQuery,
  usePostApiV1ConditionConditionUploadMutation,
  usePostApiV1ConditionByLoanIdExceptionRequestMutation,
  usePostApiV1ConstructionInspectionOrderMutation,
  useGetApiV1DashboardActionsForTodayQuery,
  useLazyGetApiV1DashboardActionsForTodayQuery,
  useGetApiV1DashboardTodoCountQuery,
  useLazyGetApiV1DashboardTodoCountQuery,
  useGetApiV1DashboardStatsKpiWidgetsQuery,
  useLazyGetApiV1DashboardStatsKpiWidgetsQuery,
  useGetApiV1DashboardStatsAllTimeKpiQuery,
  useLazyGetApiV1DashboardStatsAllTimeKpiQuery,
  useGetApiV1DashboardStatsKeyStatisticsQuery,
  useLazyGetApiV1DashboardStatsKeyStatisticsQuery,
  useGetApiV1DashboardStatsManagerPipelineQuery,
  useLazyGetApiV1DashboardStatsManagerPipelineQuery,
  useGetApiV1DashboardStatsKeyManagerPipelineQuery,
  useLazyGetApiV1DashboardStatsKeyManagerPipelineQuery,
  useGetApiV1DashboardStatsBrokerPipelineQuery,
  useLazyGetApiV1DashboardStatsBrokerPipelineQuery,
  useGetApiV1DocumentLockerQuery,
  useLazyGetApiV1DocumentLockerQuery,
  useGetApiV1DocumentLockerLoansByLoanIdQuery,
  useLazyGetApiV1DocumentLockerLoansByLoanIdQuery,
  useGetApiV1DocumentLockerTasksByTaskIdQuery,
  useLazyGetApiV1DocumentLockerTasksByTaskIdQuery,
  useGetApiV1DocumentsDownloadUrlQuery,
  useLazyGetApiV1DocumentsDownloadUrlQuery,
  useGetApiV1DocumentsDownloadQuery,
  useLazyGetApiV1DocumentsDownloadQuery,
  useDeleteApiV1DocumentsDeleteMutation,
  usePostApiV1DocumentsUploadByTaskIdMutation,
  useGetApiV1DocumentsGenerateFilesArchiveQuery,
  useLazyGetApiV1DocumentsGenerateFilesArchiveQuery,
  usePostApiV1FilesParsePdfMutation,
  useGetApiV1HealthTaskConfigurationQuery,
  useLazyGetApiV1HealthTaskConfigurationQuery,
  useGetApiV1LearningCenterQuery,
  useLazyGetApiV1LearningCenterQuery,
  useGetApiV1LearningCenterDownloadQuery,
  useLazyGetApiV1LearningCenterDownloadQuery,
  useGetApiV1LicensesQuery,
  useLazyGetApiV1LicensesQuery,
  usePostApiV1LicensesMutation,
  useGetApiV1LicensesByLicenseIdQuery,
  useLazyGetApiV1LicensesByLicenseIdQuery,
  usePutApiV1LicensesByLicenseIdMutation,
  useDeleteApiV1LicensesByLicenseIdMutation,
  usePostApiV1LoansByLoanIdAddPropertiesMutation,
  usePostApiV1LoansMutation,
  useGetApiV1LoansQuery,
  useLazyGetApiV1LoansQuery,
  usePostApiV1LoansByLoanIdFromPdfAppMutation,
  useGetApiV1LoansByLoanIdQuery,
  useLazyGetApiV1LoansByLoanIdQuery,
  usePutApiV1LoansByLoanIdMutation,
  usePostApiV1LoansByLoanIdSubmitMutation,
  useGetApiV1LoansByLoanIdPartiesQuery,
  useLazyGetApiV1LoansByLoanIdPartiesQuery,
  useGetApiV1LoansByLoanIdPropertiesQuery,
  useLazyGetApiV1LoansByLoanIdPropertiesQuery,
  useGetApiV1LoansByLoanIdDocumentTasksQuery,
  useLazyGetApiV1LoansByLoanIdDocumentTasksQuery,
  usePostApiV1LoansByLoanIdChangeBrokerMutation,
  usePostApiV1LoansByLoanIdManageUsersMutation,
  usePostApiV1LoansByLoanIdPartiesAndPartyIdMutation,
  useDeleteApiV1LoansByLoanIdPartiesAndPartyIdMutation,
  usePostApiV1LoansByLoanIdCreateGuarantorMutation,
  usePostApiV1LoansByLoanIdUploadBorrowerAuthorizationMutation,
  usePostApiV1LoansByLoanIdUploadCreditReportMutation,
  usePostApiV1LoansByLoanIdUploadPortfolioRequestMutation,
  useGetApiV1LoansByLoanIdPaymentsQuery,
  useLazyGetApiV1LoansByLoanIdPaymentsQuery,
  usePostApiV1LoansCreateFromQuoteMutation,
  usePostApiV1LoansByLoanIdGenerateTasksMutation,
  useGetApiV1LoansExportQuery,
  useLazyGetApiV1LoansExportQuery,
  useGetApiV1LoansActiveLoansQuery,
  useLazyGetApiV1LoansActiveLoansQuery,
  usePostApiV1LoansInactiveLoansMutation,
  useGetApiV1LoansControlPanelQuery,
  useLazyGetApiV1LoansControlPanelQuery,
  usePostApiV1LoansAssignProcessorMutation,
  usePutApiV1LoansPartyRelationsMutation,
  usePostApiV1LoansArchiveLoanMutation,
  useGetApiV1LoansDisabledQuery,
  useLazyGetApiV1LoansDisabledQuery,
  usePostApiV1LoansByLoanIdSendChangeRequestMutation,
  useGetApiV1LoanTodosQuery,
  useLazyGetApiV1LoanTodosQuery,
  useGetApiV1LolaTaskStatusQuery,
  useLazyGetApiV1LolaTaskStatusQuery,
  useGetApiV1MeridianCraQuery,
  useLazyGetApiV1MeridianCraQuery,
  usePostApiV1MeridianReissueCreditReportMutation,
  usePutApiV1PartiesByPartyIdIndividualMutation,
  usePutApiV1PartiesByPartyIdEntityMutation,
  usePutApiV1PartiesPartyRelationsMutation,
  useDeleteApiV1PartiesByPartyIdMutation,
  usePostApiV1PaymentsStripeCreatePaymentIntentMutation,
  usePostApiV1PaymentsStripeCreatePaymentLinkMutation,
  usePostApiV1PaymentsStripeSendPaymentLinksMutation,
  useGetApiV1PricingEngineQuotesQuery,
  useLazyGetApiV1PricingEngineQuotesQuery,
  useGetApiV1PricingEngineQuoteByQuoteIdRentalQuery,
  useLazyGetApiV1PricingEngineQuoteByQuoteIdRentalQuery,
  usePutApiV1PricingEngineQuoteByQuoteIdRentalMutation,
  useGetApiV1PricingEngineQuoteByQuoteIdStfQuery,
  useLazyGetApiV1PricingEngineQuoteByQuoteIdStfQuery,
  usePutApiV1PricingEngineQuoteByQuoteIdStfMutation,
  usePostApiV1PricingEngineQuoteStfCalculationsMutation,
  usePostApiV1PricingEngineQuoteRentalCalculationsMutation,
  usePostApiV1PricingEngineQuoteRentalMutation,
  useGetApiV1PricingEngineQuoteByQuoteIdRentalPdfQuery,
  useLazyGetApiV1PricingEngineQuoteByQuoteIdRentalPdfQuery,
  usePostApiV1PricingEngineQuoteStfMutation,
  useGetApiV1PricingEngineQuoteByQuoteIdStfPdfQuery,
  useLazyGetApiV1PricingEngineQuoteByQuoteIdStfPdfQuery,
  useDeleteApiV1PricingEngineQuoteByQuoteIdMutation,
  usePostApiV1PricingEngineQuoteByQuoteIdStfPdfSendMutation,
  usePostApiV1PricingEngineQuoteByQuoteIdRentalPdfSendMutation,
  usePostApiV1PricingEngineQuoteByQuoteIdCopyQuoteMutation,
  usePutApiV1PricingEngineQuoteByQuoteIdChangeStatusMutation,
  usePutApiV1PricingEngineUsersOpxUsernameMutation,
  usePutApiV1PricingEngineUsersOpxUsernameCalculationsMutation,
  usePostApiV1PropertiesMutation,
  usePutApiV1PropertiesByPropertyIdMutation,
  useDeleteApiV1PropertiesByPropertyIdMutation,
  usePutApiV1PropertiesByPropertyIdUpdateFromJobjectMutation,
  useGetApiV1RolesQuery,
  useLazyGetApiV1RolesQuery,
  useGetApiV1SettingsProgramsAndProductsQuery,
  useLazyGetApiV1SettingsProgramsAndProductsQuery,
  useGetApiV1SettingsTaskConfigurationQuery,
  useLazyGetApiV1SettingsTaskConfigurationQuery,
  usePostApiV1SigningSendCreditAuthorizationMutation,
  useGetApiV1TeamsQuery,
  useLazyGetApiV1TeamsQuery,
  usePostApiV1TeamsMutation,
  useGetApiV1TeamsNewTeamIdQuery,
  useLazyGetApiV1TeamsNewTeamIdQuery,
  useGetApiV1DocumentTemplatesQuery,
  useLazyGetApiV1DocumentTemplatesQuery,
  useGetApiV1DocumentTemplatesGetDownloadUrlQuery,
  useLazyGetApiV1DocumentTemplatesGetDownloadUrlQuery,
  useGetApiV1TenantConfigQuery,
  useLazyGetApiV1TenantConfigQuery,
  useGetApiV1TenantByTenantIdPublicAndImageQuery,
  useLazyGetApiV1TenantByTenantIdPublicAndImageQuery,
  useGetApiV1UsersMeQuery,
  useLazyGetApiV1UsersMeQuery,
  usePostApiV1UsersMutation,
  useGetApiV1UsersQuery,
  useLazyGetApiV1UsersQuery,
  usePutApiV1UsersMutation,
  useGetApiV1UsersAllQuery,
  useLazyGetApiV1UsersAllQuery,
  useGetApiV1UsersByUserIdQuery,
  useLazyGetApiV1UsersByUserIdQuery,
  useGetApiV1UsersUsernameByUserNameQuery,
  useLazyGetApiV1UsersUsernameByUserNameQuery,
  usePostApiV1UsersMePhotoMutation,
  useDeleteApiV1UsersMePhotoMutation,
  useGetApiV1UsersByUserIdPhotoQuery,
  useLazyGetApiV1UsersByUserIdPhotoQuery,
  usePostApiV1UsersByUserIdReSendInvitationMutation,
  usePostApiV1WebhooksDocusignMutation,
  usePostApiV1WebhooksStripeMutation,
  usePostApiV1WebhooksPruvanMutation,
  usePostApiV1WebhooksElphiMutation,
} = injectedRtkApi;
