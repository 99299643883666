import React, { useContext, useEffect } from 'react';
import { Divider, Grid, LinearProgress, Paper } from '@mui/material';
import {
  LolaBffApiContractsModelsDashboardKpiQuotesKpi,
  useLazyGetApiV1DashboardStatsKpiWidgetsQuery,
} from '@api/output/api';
import { formatNumber, formatThousands } from '@utils/numberFormatting.utils';
import {
  KPI_TOOLTIP_MAP,
  LEFT_LABEL_POSTFIX_MAP,
} from '@pages/dashboard/components/StatsProgressWidget/statsProgressWidget.constants';
import { ErrorNoticeFetchRequest } from '@components/ErrorNoticeFetchRequest/ErrorNoticeFetchRequest.component';
import { useTeamStats } from '../../hooks/useTeamStats';
import { PeriodContext } from '../../contexts/Period.context';
import {
  WidgetContent,
  WidgetContentBody,
  WidgetContentFooter,
} from '../WidgetContent';

import styles from './statsProgressWidget.module.scss';

export const StatsProgressWidget = () => {
  const [load, { data, isFetching, isError }] =
    useLazyGetApiV1DashboardStatsKpiWidgetsQuery();

  const { start, end } = useContext(PeriodContext);
  const isTeamStats = useTeamStats();

  useEffect(() => {
    load({
      dateFrom: start,
      dateTo: end,
      teamStats: isTeamStats,
    });
  }, [start, end, isTeamStats]);

  if (isError) {
    return (
      <Grid container spacing={4}>
        {['quotes', 'pipeline', 'funded'].map((name) => (
          <Grid item xs={12} md={6} lg={4} key={name}>
            <Paper elevation={1}>
              <WidgetContent title={name}>
                <ErrorNoticeFetchRequest />
              </WidgetContent>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  }

  if (isFetching) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={4}>
      {data &&
        Object.keys(data).map((key) => {
          const typedKey = key as unknown as keyof typeof data;
          const item = data[
            typedKey
          ] as LolaBffApiContractsModelsDashboardKpiQuotesKpi;
          const name = typedKey?.toUpperCase() || '';

          if (!item) return null;

          return (
            <Grid item xs={12} md={6} lg={4} key={name}>
              <Paper elevation={1}>
                <WidgetContent title={name} tooltip={KPI_TOOLTIP_MAP[key]}>
                  <WidgetContentBody
                    label={`$${formatThousands(item.totalAmount)}`}
                    labelClass={styles.label}
                  />
                  <Divider className={styles.divider} />
                  <WidgetContentFooter
                    leftLabelPrefix={item.numberOfLoans || ''}
                    leftLabelPostfix={
                      item.numberOfLoans
                        ? ` ${LEFT_LABEL_POSTFIX_MAP[key]}`
                        : 'Not enough data'
                    }
                    rightLabelPrefix={
                      item.averageTotalAmount
                        ? formatNumber(item.averageTotalAmount)
                        : ''
                    }
                    rightLabelPostfix={
                      item.averageTotalAmount ? ' average quote amount' : ''
                    }
                  />
                </WidgetContent>
              </Paper>
            </Grid>
          );
        })}
    </Grid>
  );
};
