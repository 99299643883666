import React, { useEffect, useMemo } from 'react';
import { Grid, LinearProgress, SelectProps, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useGetApiV1TeamsNewTeamIdQuery } from '@api/output/api';
import { ROLES } from '@typings/common';
import { SelectManager } from '../SelectManager/SelectManager.component';

export interface CreateTeamFormProps {
  role?: ROLES;
  textAppendix?: JSX.Element;
  showManagerSelect?: boolean;
  selectVariant?: SelectProps['variant'];
}

export const CreateTeamForm = ({
  role,
  textAppendix,
  showManagerSelect,
  selectVariant,
}: CreateTeamFormProps) => {
  const { setValue } = useFormContext();
  const { data, isLoading } = useGetApiV1TeamsNewTeamIdQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const teamName = `Team ${data}`;

  useEffect(() => {
    setValue('teamId', data);
    return () => {
      setValue('teamId', '');
    };
  }, [data]);

  const parsedText = useMemo(() => {
    const textIntro = (
      <Typography variant="p">
        A new <Typography variant="pBold">{teamName}</Typography> will be
        created.
      </Typography>
    );

    if (textAppendix) {
      return (
        <>
          {textIntro}
          <br />
          {textAppendix}
        </>
      );
    }

    if (role === ROLES.BROKER_MANAGER) {
      return (
        <>
          {textIntro}
          <Typography variant="p">
            You will be assigned as a manager of this team.
          </Typography>
        </>
      );
    }

    return textIntro;
  }, [textAppendix, role, teamName]);

  const parsedShowManager = useMemo(() => {
    return showManagerSelect === undefined
      ? role === ROLES.KEY_BROKER_MANAGER
      : showManagerSelect;
  }, [showManagerSelect, role]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {parsedText}
      </Grid>
      {parsedShowManager && (
        <Grid item xs={12}>
          <SelectManager
            label={`Select a manager who will be assigned to lead ${teamName}.`}
            selectVariant={selectVariant}
          />
        </Grid>
      )}
    </Grid>
  );
};
