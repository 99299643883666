import React, { useMemo, FocusEvent, useCallback, useEffect } from 'react';
import {
  Controller,
  useFormContext,
  get,
  ValidationRule,
} from 'react-hook-form';
import { MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import { ChangeHandler, CommonHandler, Option } from '@typings/common';
import { SelectWithPlaceholder } from '@components/SelectWithPlaceholder/SelectWithPlaceholder.component';
import { useEventHandlerWrapper } from '@components/DynamicForm/hooks/useEventHandlerWrapper';
import { normalizeCamelCase } from '@utils/text.utils';
import { MultiSelectSearch } from '@components/MultiSelectSearch/MultiSelectSearch.component';
import { CustomRegisterOptions } from '@components/DynamicForm/utils/yupToControllerRules';

export interface SelectControlProps {
  name: string;
  label?: string;
  options: Option[];
  required?: string | ValidationRule<boolean>;
  placeholder?: string;
  variant?: SelectProps['variant'];
  disabled?: boolean;
  multiple?: boolean;
  customHandler?: ChangeHandler;
  optionalHelperText?: string;
  defaultValue?: string | string[];
  rules?: CustomRegisterOptions;
  error?: boolean;
  helperText?: string;
  onBlur?: (e: FocusEvent<HTMLElement>) => void;
  locked?: boolean;
}

export const SelectControl = ({
  name,
  label,
  options,
  required,
  rules,
  error,
  multiple,
  placeholder = 'Select',
  variant = 'standard',
  disabled,
  customHandler,
  optionalHelperText,
  helperText,
  defaultValue,
  onBlur,
  locked,
}: SelectControlProps) => {
  const {
    control,
    setValue,
    formState: { errors, defaultValues },
  } = useFormContext();

  const localDefaultValue = useMemo(() => {
    const formDefaultValue = get(defaultValues, name);
    const fallbackValue = multiple ? [] : '';
    return formDefaultValue ?? defaultValue ?? fallbackValue;
  }, [multiple, defaultValue, defaultValues, name]);

  useEffect(() => {
    setValue(name, localDefaultValue);
  }, [name, localDefaultValue]);

  const customHandlerCreator = useEventHandlerWrapper<
    SelectChangeEvent<string | boolean | undefined>
  >(name, customHandler);

  const onChangeLocalCreator = useCallback(
    (changeHandler: CommonHandler<SelectChangeEvent>) => {
      const customChangeHandler = customHandlerCreator();

      return (e: SelectChangeEvent) => {
        changeHandler?.(e);
        customChangeHandler?.(e);
      };
    },
    [customHandlerCreator, customHandler]
  );

  const errorMessage = get(errors, `${name}.message`)?.replace(
    name,
    normalizeCamelCase(name.split('.').pop())
  );

  const hasError = get(errors, name) || error;
  const isRequired =
    required && (typeof required === 'object' ? required.value : !!required);

  return (
    <Controller
      name={name}
      defaultValue={localDefaultValue}
      control={control}
      rules={{
        ...rules,
        required,
      }}
      render={({
        field: { value, onChange, onBlur: controllerOnBlur, ...props },
      }) => {
        return multiple ? (
          <MultiSelectSearch
            label={label}
            placeholder={placeholder}
            helperText={hasError ? errorMessage : helperText}
            options={options}
            selectedItems={value}
            onChange={(newValue) => {
              onChange(newValue);
              if (customHandler) {
                customHandler(name, newValue);
              }
            }}
            disabled={disabled}
            required={!!isRequired}
            optionalHelperText={optionalHelperText}
            error={!!hasError}
            onBlur={onBlur ? onBlur : controllerOnBlur}
          />
        ) : (
          <SelectWithPlaceholder
            label={label}
            placeholder={placeholder}
            value={value ?? localDefaultValue}
            variant={variant}
            error={!!hasError}
            helperText={hasError ? errorMessage : helperText}
            selectOptions={options}
            disabled={disabled}
            required={!!isRequired}
            optionalHelperText={optionalHelperText}
            onChange={onChangeLocalCreator(onChange)}
            locked={locked}
            {...props}
            onBlur={onBlur ? onBlur : controllerOnBlur}
          >
            <MenuItem value="" disabled style={{ display: 'none' }}>
              Select an option
            </MenuItem>
            {options?.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectWithPlaceholder>
        );
      }}
    />
  );
};
