import React from 'react';
import { LinearProgress, Typography } from '@mui/material';
import styles from './stagesProgress.module.scss';

export interface StagesProgressProps {
  /** Classname for wrapper, can be used for overriding some internal styles or adding margins */
  className?: string;
  /** Count of equal sections for filling */
  sectionsCount?: number;
  /** Config for the size of section, e.g [2, 4, 4] mean that 20% - 40% - 40%, `sectionCount` will be ignored */
  sectionsSizes?: number[];
  /** Minimum and maximum values for progress to be displayed as divisions */
  divisions?: {
    min?: number;
    max?: number;
  };
  /** Count of completed (filled) sections */
  completedCount?: number;
}

export enum STAGES_PROGRESS_ELEMENT_TYPE {
  FILLED = 'filled',
  PARTIAL = 'partial',
  EMPTY = 'empty',
}

export const StagesProgress = ({
  sectionsCount = 1,
  completedCount = 0,
  sectionsSizes = [],
  divisions,
  className = '',
}: StagesProgressProps) => {
  const preparedSectionCount = sectionsSizes.length || sectionsCount;
  const fullyFilled = Math.floor(completedCount);
  const partSize = completedCount - fullyFilled;

  const sections = [];
  for (let i = 0; i < preparedSectionCount; i++) {
    let type = STAGES_PROGRESS_ELEMENT_TYPE.EMPTY;
    if (i < fullyFilled) {
      type = STAGES_PROGRESS_ELEMENT_TYPE.FILLED;
    } else if (i === fullyFilled && partSize) {
      type = STAGES_PROGRESS_ELEMENT_TYPE.PARTIAL;
    }
    sections.push({
      id: `${type}-${i}`,
      type,
    });
  }

  return (
    <div>
      <div className={`${styles.wrapper} ${className}`}>
        {sections.map(({ type, id }, index) =>
          type === STAGES_PROGRESS_ELEMENT_TYPE.PARTIAL ? (
            <LinearProgress
              key={id}
              value={partSize * 100}
              variant="determinate"
              className={styles[type]}
              style={{ flex: sectionsSizes[index] || 1 }}
            />
          ) : (
            <div
              key={id}
              className={styles[type]}
              style={{ flex: sectionsSizes[index] || 1 }}
            />
          )
        )}
      </div>
      {divisions && (
        <div className={styles.divisions}>
          <Typography variant="pDescription">{divisions.min}</Typography>
          <Typography variant="pDescription">{divisions.max}</Typography>
        </div>
      )}
    </div>
  );
};
