import { UPLOAD_STATUS } from '@constants/uploader.constants';
import {
  AllowedExtensionsRule,
  MaxSizeRule,
  RulesConfig,
} from './Uploader.types';

export const checkFileExtension = (
  fileName: string,
  extensionRule?: AllowedExtensionsRule
) => {
  if (!extensionRule) return '';
  const extension = fileName.slice(fileName.lastIndexOf('.') + 1);
  return extensionRule.rule.includes(extension) ? '' : extensionRule.message;
};

export const checkFileSize = (size: number, maxSizeRule?: MaxSizeRule) => {
  if (!maxSizeRule) return '';
  return size > maxSizeRule.rule ? maxSizeRule.message : '';
};

export const checkFile = (file?: File, rules?: RulesConfig) => {
  if (!rules || !file) return '';

  let error = checkFileExtension(file.name, rules.allowedExtensions);
  if (error) return error;

  error = checkFileSize(file.size, rules.maxSize);
  if (error) return error;
};

export const showDropZone = (status: UPLOAD_STATUS) => {
  const STATUS_WITH_DROPZONE = [
    UPLOAD_STATUS.NOT_UPLOADED,
    UPLOAD_STATUS.UPLOADED,
  ];
  return STATUS_WITH_DROPZONE.includes(status);
};

export const formAcceptedFiles = (rule: string[] | undefined) => {
  if (!rule?.length) return '';
  return rule.map((extension) => `.${extension}`).join(',');
};
