import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, LinearProgress, Link, Typography } from '@mui/material';

import { ArrowBackIcon } from '@lola/ui-react-components';
import { FormData } from '@form-configs/types';
import styles from './contentFormSection.module.scss';

export interface ContentFormSectionProps {
  isLoading?: boolean;
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  primaryButtonDisabled?: boolean;
  primaryButtonText: string;
  onSubmit?: (props: FormData) => void;
  backLinkProps?: {
    to: string;
    label: string;
  };
}

export const ContentFormSection = ({
  isLoading,
  title,
  subtitle,
  primaryButtonDisabled,
  primaryButtonText,
  children,
  onSubmit,
  backLinkProps,
}: PropsWithChildren<ContentFormSectionProps>) => {
  return (
    <form onSubmit={onSubmit} className={styles.section}>
      <Typography variant="h1" className={styles.heading}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="p" paragraph className={styles.subtitle}>
          {subtitle}
        </Typography>
      )}
      <div className={styles.controls}>{children}</div>
      <Button
        type="submit"
        variant="contained"
        size="large"
        disabled={primaryButtonDisabled || isLoading}
        className={styles.submitButton}
      >
        {primaryButtonText}
      </Button>
      {isLoading && <LinearProgress />}
      {backLinkProps && (
        <Link component={NavLink} to={backLinkProps.to} underline="none">
          <Button startIcon={<ArrowBackIcon />} variant="text">
            {backLinkProps.label}
          </Button>
        </Link>
      )}
    </form>
  );
};
