import React, { useMemo } from 'react';
import { LinearProgress, SelectProps } from '@mui/material';
import { LolaBffApiContractsModelsUserRole } from '@api/output/api';
import { convertToOptions } from '@utils/options.utils';
import { SelectControl } from '@components/SelectControl/SelectControl.component';
import { useLoadUsers } from '@hooks/useLoadUsers';

interface SelectRequiredUserProps {
  name: string;
  roles: LolaBffApiContractsModelsUserRole[];
  label?: string;
  skipFetch?: boolean;
  variant?: SelectProps['variant'];
  placeholder?: string;
}

export const SelectRequiredUser = ({
  name,
  roles,
  label,
  skipFetch = false,
  variant = 'standard',
  placeholder = 'Choose member',
}: SelectRequiredUserProps) => {
  const { users, isLoading } = useLoadUsers({
    roles,
    skipFetch,
  });

  const options = useMemo(() => {
    return users
      ? convertToOptions({
          input: users,
          idKey: 'userId',
          valueKeys: ['fullName', 'email'],
        })
      : [];
  }, [users, roles]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <SelectControl
      name={name}
      label={label}
      options={options}
      variant={variant}
      placeholder={placeholder}
    />
  );
};
