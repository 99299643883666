import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import {
  ProgressStepItemStatus,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
import { LOAN_PROGRAM_KEY } from '@config/forms';
import {
  convertFormConfigToYupSchema,
  getValidationErrors,
} from '@components/DynamicForm/utils/validation';
import { AvailableForms, forms } from '@form-configs/output';
import {
  APPLICATION_TYPE,
  FORM_NAME_MAP,
} from '@pages/personalLoans/pages/buildMyLoan/pages/application/Application.constants';
import { FormData } from '@form-configs/types';
import { useContactForm } from '@pages/personalLoans/pages/buildMyLoan/pages/application/hooks/useContactForm';
import { LolaBffApiContractsModelsLoanLoanProperty } from '@api/output/api';
import { useStepStatus } from '../useStepStatus';

const APPLICATION: ProgressStepperItem = {
  id: '/personal-loans/:id/build-my-loan/application',
  title: 'Application',
  to: 'application',
};
type Schema = yup.ObjectSchema<FormData>;

export const isFormValid = (
  data: FormData,
  mainSchema: yup.AnySchema,
  contactSchema: yup.AnySchema
) => {
  const mainErr = getValidationErrors(data, mainSchema) ?? [];
  const contactErr = getValidationErrors(data, contactSchema) ?? [];

  const errors = [...mainErr, ...contactErr];
  console.warn('Application Page errors', errors);

  return errors.length === 0;
};

const checkApplicationType = (
  properties: LolaBffApiContractsModelsLoanLoanProperty[]
) => {
  if (!properties?.length) return null;

  return properties.length === 1
    ? APPLICATION_TYPE.SINGLE
    : APPLICATION_TYPE.PORTFOLIO;
};

export const useStepApplication = (requiredSteps: ProgressStepItemStatus[]) => {
  const [isValid, setIsValid] = useState(false);
  const { watch, getValues } = useFormContext();
  const { pathname } = useLocation();
  const [loanProgram, properties] = watch([LOAN_PROGRAM_KEY, 'properties']);

  //Temp fix for demo
  //In useMemo for applicationType there was a stale value for properties, always = [],
  //So we got null for applicationType and mainFormSchema, which break page validation and calculation
  const applicationType = checkApplicationType(properties);

  const mainFormSchema = useMemo(() => {
    if (!applicationType) return null;

    const form = FORM_NAME_MAP[applicationType]?.[
      loanProgram
    ] as AvailableForms;

    return convertFormConfigToYupSchema(forms[form]) as Schema;
  }, [applicationType, loanProgram]);

  const contactForm = useContactForm(applicationType, loanProgram);
  const contactFormSchema = useMemo(
    () => convertFormConfigToYupSchema(contactForm),
    [contactForm]
  );

  useEffect(() => {
    if (!mainFormSchema) {
      setIsValid(false);
      return;
    }

    const isValid = isFormValid(getValues(), mainFormSchema, contactFormSchema);
    setIsValid(isValid);

    const isProductPageActive = pathname.includes('/build-my-loan/application');
    let subscription: ReturnType<typeof watch> | undefined;

    if (isProductPageActive) {
      subscription = watch((data) => {
        const isValid = isFormValid(data, mainFormSchema, contactFormSchema);
        setIsValid(isValid);
      });
    }

    return () => {
      subscription?.unsubscribe();
    };
  }, [pathname, watch, mainFormSchema, contactFormSchema, getValues]);

  const status = useStepStatus({
    requiredStepsStatus: requiredSteps,
    isCompleted: isValid,
  });

  return useMemo(
    () => ({
      ...APPLICATION,
      status,
    }),
    [status]
  );
};
