import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { useGetApiV1UsersMeQuery } from '@api/output/api';
import { clearTokens } from '@store/slices/auth.slice';
import { isPossibleAuthenticated } from '@store/selectors/auth.selectors';
import { RootLayout } from '../../layouts/Root/Root.layout';

export const RequireAuth = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isPossibleAuthenticated);
  const { isError, isFetching, isLoading } = useGetApiV1UsersMeQuery();

  useEffect(() => {
    if (isError) {
      dispatch(clearTokens());
    }
  }, [isError]);

  if (isLoading || isFetching) {
    return <LinearProgress title="loading..." />;
  }

  if (isError || !isAuthenticated) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return <RootLayout />;
};
