import React, { memo, useEffect } from 'react';
//MUI
import { Grid, LinearProgress, Paper } from '@mui/material';
//Router
import { NavLink, Outlet, useParams, useLocation } from 'react-router-dom';
//React-hooks-form
import { FormProvider, useForm } from 'react-hook-form';
//Components
import { KanbanIcon } from '@lola/ui-react-components';
import { ProgressStepper } from '@components/ProgressStepper/ProgressStepper.component';
import { LoanFooter } from '@pages/personalLoans/components/LoanFooter/LoanFooter.component';
//API
import { useGetApiV1ClosemyloanGetOverviewProgressQuery } from '@api/output/api';
//Types
import {
  ProgressIndicatorType,
  ProgressStepperItem,
} from '@components/ProgressStepper/types';
//Icons
//Hooks
import { useStepsNavigation } from '@pages/personalLoans/hooks/useStepsNavigation';
import { useActiveStepRoute } from '../../hooks/useActiveStepRoute';
//Styles
import styles from './closeMyLoan.module.scss';

export const CloseMyLoanLayout = memo(() => {
  const location = useLocation();
  const { loanId = '' } = useParams();
  const { isLoading, data, refetch } =
    useGetApiV1ClosemyloanGetOverviewProgressQuery({
      loanId: loanId,
    });

  const newElemts: ProgressStepperItem[] = data as ProgressStepperItem[];
  const { currentStep, goToNextStep, goToPreviousStep } =
    useStepsNavigation(newElemts);
  const activeStep = useActiveStepRoute(newElemts ? newElemts : []);

  const methods = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    refetch();
  }, [location]);

  if (isLoading || !data) {
    return <LinearProgress />;
  }

  return (
    <FormProvider {...methods}>
      <section className={styles.section}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Paper elevation={0} className={styles.stepperWidget}>
              <ProgressStepper
                progressIndicatorType={ProgressIndicatorType.LINEAR}
                items={newElemts}
                headerTitle="Close my Loan"
                activeItem={activeStep}
                headerIcon={
                  <NavLink to="../">
                    <KanbanIcon />
                  </NavLink>
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Outlet />
          </Grid>
        </Grid>
      </section>
      <LoanFooter
        currentStep={currentStep}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
      />
    </FormProvider>
  );
});
