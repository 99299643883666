import React from 'react';
import { Button, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { AddIcon } from '@lola/ui-react-components';
import { SubpageHeaderComponent } from '@components/SubpageHeader/SubpageHeader.component';
import { QuotesList } from './components/QuotesList/QuotesList.component';

export const QuotesPage = () => {
  return (
    <>
      <SubpageHeaderComponent title="Scenario builder">
        <Link component={NavLink} to="quote" underline="none">
          <Button variant="contained" startIcon={<AddIcon />} size="large">
            Create new quote
          </Button>
        </Link>
      </SubpageHeaderComponent>
      <QuotesList />
    </>
  );
};
